import { TweenLite } from "gsap";
import CustomEase from "../CustomEase";

export const pageAnimation = (node, appear, top, completion, duration, delay, easeCurve) => {
    let outsidePosition = `${top ? "-" : ""}${window.innerHeight}`;
    TweenLite.fromTo(
        node,
        !!duration ? duration : 1.006,
        {
            top: appear ? outsidePosition : "0px",
        },
        {
            top: appear ? "0px" : outsidePosition,
            delay: !!delay ? delay : 0,
            onComplete: () => {if (completion) completion();},
            ease: CustomEase.create("custom", !!easeCurve ? easeCurve : "0.60, 0.00, 0.39, 1.00"),

        }
    );
};

export const opacityRouteAnimation = (node, appear, duration, delay,easeCurve) => {
    TweenLite.fromTo(
        node,
        !!duration ? duration : 0.22,
        {
            opacity: appear ? 0 : 1,
        },
        {
            opacity: appear ? 1: 0,
            delay: !!delay ? delay : 0,
            ease: CustomEase.create("custom", !!easeCurve ? easeCurve : "0, 0, 1, 1"),
        }
    );
};