import React, { Component, Fragment } from "react";
import styled from 'styled-components'
import { Link } from "react-router-dom";

const LogoText = styled.p`
margin: 0;
position: relative;
font-family: Aktiv Grotesk W01 Medium;
font-style: normal;
font-weight: normal;
font-size: 20px;

color: #001F6B;
`

const SubLogo = styled.p`
margin: 0;
margin-top: 5px;
position: relative;
font-family: Aktiv Grotesk W01 Bold1370651;
font-style: normal;
font-weight: bold;
font-size: 12px;
/* or 13px */

text-transform: uppercase;

color: #EEC50C;
`

const FullPageArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props =>
    props.windowHeight ? `${props.windowHeight}px` : "100vh"};
  width: ${props => (props.windowWidth ? `${props.windowWidth}px` : "100vw")};
`;

const DonationLink = styled.a`
  position: relative;
  margin: 0;
  margin-top: 5px;
  font-family: Aktiv Grotesk W01 Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;


  color: #1F4EC3;
  text-decoration: none;

  &:hover {
    color: #2D69FF;
    text-decoration: underline;
  }

`

const DescriptionText = styled.p`
  position: relative;
  font-family: Aktiv Grotesk W01 Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;


  color: #323150;
`

const BorderedButton = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  width: 130px;
  height: 60px;

  text-decoration: none;

  &:hover {
    background: #FBFBFB;
  }
`

const CryptoText = styled.p`
  position: relative;
  font-family: Aktiv Grotesk W01 Bold1370651;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #1F4EC3;
`

const LogoContaienr = styled.div`
  position: absolute;
  top: 64px;
  left: 70px;
  width: 120px;

  @media (max-width: 1300px) {
    left: 30px;
  }
`

const CloseContainer = styled(Link)`
  position: absolute;
  top: 64px;
  right: 70px;
  width: 40px;
  height: 40px;
  background-image: url('${require('../assets/ukraine/close.png')}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;
  text-decoration: none;

  @media (max-width: 1300px) {
    right: 30px;
    width: 30px;
    height: 30px;
  }
`

const TextContent = styled.div`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 70px;
  width: calc(640px - 2 * 70px);

  @media (max-width: 1300px) {
    top: 150px;
    margin-left: 30px;
    transform: translate(0, 0);
    width: calc(100% - 2 * 30px);
    max-width: calc(640px - 2 * 70px);
    padding-bottom: 120px;
  }
`

const MilkinsideTeam = styled.p`
position: relative;
margin: 0;
font-family: Aktiv Grotesk W01 Regular;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;

color: #FFFFFF;
`

const Trademark = styled.p`
position: relative;
margin: 0;
margin-top: 5px;
font-family: Aktiv Grotesk W01 Regular;
font-size: 16px;
line-height: 155%;
/* identical to box height, or 25px */


color: rgba(255, 255, 255, 0.6);
`

const SocialLink = styled.a`
    position: relative;
    height: 18px;
    width: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 100px;
`

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
  object-fit: cover;
`;

const DesktopVideoSection = styled.div`
position: absolute;
top: 0;
left: 640px;
background: black;
height: 100%;
width: 100%;

@media (max-width: 1300px) {
  display: none;
}

`

const MobileVideo = styled.video`
@media (min-width: 1300px) {
  display: none;
}

position: relative;
top: 0;
left: 0;
width: 100%;
height: 100%;
max-height: 100%;
min-height: 100%;
overflow: hidden;
object-fit: cover;
`


const MobileMilkinsideTeam = styled.p`
@media (min-width: 1300px) {
  display: none;
}

position: relative;
font-family: Aktiv Grotesk W01 Medium;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;

color: #000000;
`

const BannerBG = styled.div`
position: absolute;
height: 65px;
background: rgba(0, 0, 0, 0.3);
backdrop-filter: blur(54px);
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;

width: 1300px;

`

const BannerText = styled.p`
  position: relative;

  font-family: Aktiv Grotesk W01 Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  
  
  color: #FFFFFF;
`

const BannerButton = styled(Link)`
  height: 65px;
  width: 175px;
  background: white;
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

const JoinUsText = styled.p`
  position: relative;
  pointer-events: none;

  font-family: Aktiv Grotesk W01 Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  color: #000000;
`

export class UkraineBanner extends Component {
  render() {
    return (
      <BannerBG
        style={{
          top: this.props.top,
          bottom: this.props.bottom,
          left: this.props.left,
          width: this.props.width
        }}
      >
        <div
          style={{
            position: "relative",
            height: '37px',
            width: '37px',
            backgroundImage: `url('${require('../assets/ukraine/heart.png')}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            marginLeft: '10px'
          }}
        />
        <BannerText style={{marginLeft: '10px'}}>
        {this.props.headerMode !== "mobile" ? 'We stand with Ukraine, and we invite you to join us.' : 'We stand with Ukraine'}
        </BannerText>

        <BannerButton to="/stand-with-ukraine">
          <div
            style={{
              position: "relative",
              height: '18px',
              width: '18px',
              backgroundImage: `url('${require('../assets/ukraine/circleFlag.png')}')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
          <JoinUsText  style={{ marginLeft: '6px'}}>
            Join us
          </JoinUsText>
        </BannerButton>
        
      </BannerBG>
    )
  }
}


const UkraineContainer = styled(FullPageArea)`
  z-index: 100;
  @media (max-width: 1300px) {
    overflow-y: scroll;
  }
`


export default class Ukraine extends Component {
  render() {
    return (
        <UkraineContainer
          style={{
            background: '#EDEAEF',
          }}
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
        >
          <LogoContaienr>
            <LogoText>MILKINSIDE.</LogoText>
            <SubLogo>STANDS WITH UKRAINE.</SubLogo>
          </LogoContaienr>

          {/* Exit */}
          <CloseContainer
            to="/"
            // onClick={() => this.props.onClose ? this.props.onClose() : undefined}
          />

          {/* <Banner /> */}

          <TextContent>
            <MobileVideo
              crossorigin="anonymous"
              autoPlay
              playsInline
              muted
              loop
              src={require('../assets/ukraine/ukraine_loop.mp4')}
            />
            <DescriptionText>Like many international teams, we have colleagues, family, and friends in Ukraine.  Right now our energy and personal funds are directed toward helping them defend themselves against the violence of Putin's invasion.</DescriptionText>
            <DescriptionText style={{ marginTop: '20px' }}>We stand with Ukraine, and we invite you to join us.  We have made a list of organizations where we are donating for the defense of Ukraine and the care of its people.</DescriptionText>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <DonationLink href="https://www.globalgiving.org/projects/ukraine-crisis-relief-fund/" target="_blank">GlobalGiving: Ukraine Crisis Relief Fund</DonationLink>
              <DonationLink href="https://www.globalempowermentmission.org/" target="_blank">Global Empowerment Mission Diaster Relief</DonationLink>
              <DonationLink href="https://wck.org/" target="_blank">World Central Kitchen</DonationLink>
              <DonationLink href="https://www.doctorswithoutborders.org/" target="_blank">Doctors Without Borders </DonationLink>
              <DonationLink href="https://novaukraine.org" target="_blank">Nova Ukraine</DonationLink>
            </div>

            <DescriptionText style={{ marginTop: '20px' }}>For those who have asked, we also provide the following wallet
              links set up by our Ukrainian volunteers to pay for food and gas and
              medicine as they shepherd the displaced across the border to safety.</DescriptionText>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
              <BorderedButton href="https://www.blockchain.com/btc/address/39t9q1PwMuVTCCPP3juaopEjnHytPqTYgD" target="_blank">
                <CryptoText>Bitcoin</CryptoText>
              </BorderedButton>
              <BorderedButton href="https://etherscan.io/address/0x3e26480626B016D84ccC3286014c4FECfEfc9F07" target="_blank">
                <CryptoText>Ethereum</CryptoText>
              </BorderedButton>
              <BorderedButton href="https://etherscan.io/address/0xdbfd8137004327518E0fBFB949d69B7C60f04952" target="_blank">
                <CryptoText>USDT</CryptoText>
              </BorderedButton>
            </div>

            <DescriptionText style={{ marginTop: '25px' }}>Thank you for support</DescriptionText>
            <MobileMilkinsideTeam style={{ marginTop: '10px'}}>The Milkinside Team</MobileMilkinsideTeam>
          </TextContent>

          <DesktopVideoSection>
            <VideoBackground
              crossorigin="anonymous"
              autoPlay
              playsInline
              muted
              loop
              src={require('../assets/ukraine/ukraine_loop.mp4')}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'calc(100% - 640px)',
                height: '100%'
              }}
            >
              <div style={{
                position: 'absolute',
                left: '50px',
                bottom: '50px',
              }}>
                <MilkinsideTeam>The Milkinside Team</MilkinsideTeam>
                <Trademark>© 2022 by Milkinside</Trademark>
              </div>

              <div style={{
                position: 'absolute',
                right: '50px',
                bottom: '50px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
                <SocialLink
                  target={'_blank'}
                  href="https://dribbble.com/milkinside"
                  style={{
                    backgroundImage: `url('${require('../assets/ukraine/dribbble.png')}')`
                  }}
                />
                <SocialLink
                  target={'_blank'}
                  href="https://www.behance.net/gleb"
                  style={{
                    backgroundImage: `url('${require('../assets/ukraine/behance.png')}')`
                  }}
                />
                <SocialLink
                  target={'_blank'}
                  href="https://www.linkedin.com/company/milkinside"
                  style={{
                    backgroundImage: `url('${require('../assets/ukraine/linkedin.png')}')`
                  }}
                />
                <SocialLink
                  target={'_blank'}
                  href="https://vimeo.com/glebkuznetsov"
                  style={{
                    backgroundImage: `url('${require('../assets/ukraine/vimeo.png')}')`
                  }}
                />
              </div>
            </div>
          </DesktopVideoSection>
        </UkraineContainer>
    );
  }
}
