import React, { Component } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";

import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import { responsiveFontSize } from "../Layouts";

const TextWrap = styled.div`
  overflow: hidden;
`;

export const TextAnimationType = Object.freeze({
  normal: "normal",
  router: "router",
  transition: "transition"
});

export const LineAnimationStyle = Object.freeze({
  left: "left",
  center: "center"
});

export class AnimatedLine extends Component {
  constructor(props) {
    super(props);
    this.firstMount = true;
  }

  render() {
    const { left, top, bottom, width, height, style } = this.props;
    return (
      <div
        ref={this.props.lineRef}
        style={{
          position: "absolute",
          left: left,
          top: top,
          bottom: bottom,
          height: height,
          width: width,
          overflow: "hidden",
          ...this.props.wrapStyle
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%"
          }}
        >
          <Transition
            in={this.props.in}
            appear={true}
            mountOnEnter={true}
            unmountOnExit={true}
            addEndListener={(node, done) => {
              if (!this.props.in && this.props.disableExitAnimation) {
                return;
              }
              if (style !== LineAnimationStyle.center) {
                done();
                return;
              }
              if (this.props.in) {
                let delay;
                let duration;
                // Route
                duration = 0.56;
                if (this.props.isRoute) {
                  delay = 0.95;
                }
                // Appear
                else if (this.firstMount && !this.props.isTransition) {
                  delay = 0;
                  this.firstMount = false;
                }
                // Transition
                else {
                  delay = 0.45;
                  duration = 0.46;
                }

                TweenMax.fromTo(
                  node,
                  duration,
                  {
                    width: "0%"
                  },
                  {
                    width: "100%",
                    delay: this.props.delay ? this.props.delay : delay,
                    onComplete: () => {
                      done();
                    },
                    ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
                  }
                );
              } else {
                // Route Exit
                TweenMax.fromTo(
                  node,
                  0.3,
                  {
                    width: "100%"
                  },
                  {
                    width: "0%",
                    delay: 0,
                    onComplete: () => {
                      done();
                    },
                    ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00")
                  }
                );
              }
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "50%",
                height: "100%",
                transform: "translateX(-50%)",
                width: "100%",
                overflow: style === LineAnimationStyle.center ? "hidden" : null
              }}
            >
              <Transition
                in={this.props.in}
                appear={true}
                mountOnEnter={true}
                unmountOnExit={true}
                addEndListener={(node, done) => {
                  if (!this.props.in && this.props.disableExitAnimation) {
                    return;
                  }
                  if (style !== LineAnimationStyle.left) {
                    done();
                    return;
                  }
                  if (this.props.in) {
                    let delay;
                    let duration;
                    // Route
                    duration = 0.56;
                    if (this.props.isRoute) {
                      delay = 0.95;
                    }
                    // Appear
                    else if (this.firstMount && !this.props.isTransition) {
                      delay = 0;
                      this.firstMount = false;
                    }
                    // Transition
                    else {
                      delay = 0.45;
                      duration = 0.46;
                    }

                    TweenMax.fromTo(
                      node,
                      duration,
                      {
                        transform: "translate(-100%, 0)"
                      },
                      {
                        transform: "translate(0, 0)",
                        delay: delay,
                        onComplete: () => {
                          TweenMax.set(node, { clearProps: "transform" });
                          done();
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.33, 0.00, 0.00, 1.00"
                        )
                      }
                    );
                  }
                  // Exit
                  else {
                    let delay;
                    let duration;

                    delay = 0;
                    duration = 0.4;

                    TweenMax.fromTo(
                      node,
                      duration,
                      {
                        transform: "translate(0, 0)"
                      },
                      {
                        transform: "translate(-100%, 0)",
                        delay: delay,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          done();
                        },
                        ease: CustomEase.create(
                          "custom",
                          "1.00, 0.00, 0.67, 1.00"
                        )
                      }
                    );
                  }
                }}
              >
                {this.props.children}
              </Transition>
            </div>
          </Transition>
        </div>
      </div>
    );
  }
}

export class AboutTextDecoration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      in: true,
      delay: null
    };

    this.container = React.createRef();
  }
  animateIn() {
    this.setState({
      in: true,
      delay: 0.1,
    });

    setTimeout(() => {
      this.setState({
        delay: null
      }) 
    }, 1000);
  }

  animateOut() {
    this.setState({
      in: false,
      delay: 0
    });
    setTimeout(() => {
      this.setState({
        delay: null
      }) 
    }, 1000);
  }

  render() {
    let leftRotateTransform = `translateX(${
      this.props.centered
        ? this.props.nColumnDividors < 5
          ? "-40%"
          : "-50%"
        : "-90%"
    }) rotate(-90deg)`;
    let rightRotateTransform = `rotate(90deg) translateY(${
      this.props.centered
        ? this.props.nColumnDividors < 5
          ? "-35%"
          : "-50%"
        : "20%"
    })`;
    const { left, top } = this.props;
    return (
      <div
        ref={this.container}
        style={{
          position: "absolute",
          top: top,
          left: left,
          transform: this.props.leftRotate
            ? leftRotateTransform
            : rightRotateTransform,
          transformOrigin: this.props.leftRotate ? "100% 0%" : "0% 0%",
          width: "fit-content",
          ...this.props.style
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: this.props.leftRotate ? "flex-end" : null,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          {this.props.leftRotate && (
            <AnimatedLine
              in={this.props.in && this.state.in}
              disableExitAnimation={this.props.disableExitAnimation}
              delay={this.state.delay}
              isRoute={this.props.isRoute}
              isTransition={this.props.isTransition}
              style={LineAnimationStyle.left}
              height={`${(1 / 297) * this.props.columnWidth}vw`}
              width={`${this.props.columnWidth *
                (this.props.nColumnDividors < 5 ? 0.5797 : 0.44 * 0.671)}vw`}
              wrapStyle={{
                position: "relative",
                transform: "rotate(180deg) translateX(10%)"
              }}
            >
              <div
                style={{ height: "100%", width: "100%", background: "white" }}
              />
            </AnimatedLine>
          )}
          <SlidingText
            in={this.props.in && this.state.in}
            disableExitAnimation={this.props.disableExitAnimation}
            delay={this.state.delay}
            isRoute={this.props.isRoute}
            isTransition={this.props.isTransition}
            wrapStyle={{
              position: "relative",
              marginRight: this.props.leftRotate
                ? null
                : `${this.props.columnWidth * 0.44 * 0.083}vw`
            }}
            style={{
              fontFamily: "Aktiv Grotesk W01 Medium",
              fontStyle: "normal",
              fontWeight: "lighter",
              lineHeight: `${responsiveFontSize(
                18,
                this.props.nColumnDividors > 4 ? 11 : 13
              )}vw`,
              fontSize: `${responsiveFontSize(
                15,
                this.props.nColumnDividors > 4 ? 8 : 10
              )}vw`,
              letterSpacing: "0.1em",
              whiteSpace: "nowrap",
              color: "white"
            }}
          >
            {this.props.topLine}
          </SlidingText>

          {!this.props.leftRotate && (
            <AnimatedLine
              in={this.props.in && this.state.in}
              disableExitAnimation={this.props.disableExitAnimation}
              isRoute={this.props.isRoute}
              isTransition={this.props.isTransition}
              style={LineAnimationStyle.left}
              height="1px"
              width={`${this.props.columnWidth *
                (this.props.nColumnDividors < 5 ? 0.5797 : 0.44 * 0.671)}vw`}
              wrapStyle={{
                position: "relative",
                transform: "translateY(-100%)"
              }}
            >
              <div
                style={{ height: "100%", width: "100%", background: "white" }}
              />
            </AnimatedLine>
          )}
        </div>
        <SlidingText
          in={this.props.in && this.state.in}
          disableExitAnimation={this.props.disableExitAnimation}
          isRoute={this.props.isRoute}
          isTransition={this.props.isTransition}
          wrapStyle={{
            marginTop: `${this.props.columnWidth * 0.44 * 0.04}vw`,
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: this.props.leftRotate ? "flex-end" : "flex-start"
          }}
          style={{
            fontFamily: "Aktiv Grotesk W01 Medium",
            fontStyle: "normal",
            fontWeight: "lighter",
            lineHeight: `${responsiveFontSize(
              18,
              this.props.nColumnDividors > 4 ? 11 : 13
            )}vw`,
            fontSize: `${responsiveFontSize(
              15,
              this.props.nColumnDividors > 4 ? 8 : 10
            )}vw`,
            letterSpacing: "0.1em",
            whiteSpace: "nowrap",
            color: "white"
          }}
        >
          {this.props.bottomLine}
        </SlidingText>
      </div>
    );
  }
}
export class DescriptionText extends Component {
  constructor(props) {
    super(props);
    this.firstMount = true;
  }

  animation(node, appear, duration, delay, ease, done) {
    TweenMax.fromTo(
      node,
      duration,
      {
        transform: appear
          ? `translate(0, ${this.props.downAnimation ? "-" : ""}${0.1 * this.props.columnWidth}vw)`
          : "translate(0, 0)",
        opacity: appear ? 0 : 1
      },
      {
        transform: appear
          ? "translate(0, 0)"
          : `translate(0, ${this.props.isRoute ? "" : ""}${0.0 *
              this.props.columnWidth}vw)`,
        opacity: appear ? 1 : 0,
        delay: delay,
        onComplete: () => {
          if (done) {
            done();
          }
        },
        ease: CustomEase.create("custom", ease)
      }
    );
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={1800}
        appear={true}
        onEntered={(_, isAppear) => {
          this.firstMount = false;
        }}
        mountOnEnter={
          this.props.mountOnEnter !== undefined ? this.props.mountOnEnter : true
        }
        unmountOnExit={
          this.props.unmountOnExit !== undefined
            ? this.props.unmountOnExit
            : true
        }
        addEndListener={(node, done) => {
          if (!this.props.in && this.props.disableExitAnimation) {
            return;
          }
          let duration, delay, ease;
          if (!this.props.isDisabled) {
            if (this.firstMount) {
              duration =
                this.props.appearDuration !== undefined
                  ? this.props.appearDuration
                  : 0.86;
              delay =
                this.props.appearDelay !== undefined
                  ? this.props.appearDuration
                  : 0.2;
              ease = "0.33, 0.00, 0.00, 1.00";
            }

            if (this.props.in && this.props.isRoute) {
              duration =
                this.props.routeDuration !== undefined
                  ? this.props.routeDuration
                  : 0.86;
              delay = !!this.props.routeDelay ? this.props.routeDuration : 1.1;
              ease = "0.33, 0.00, 0.00, 1.00";
            } else if (this.props.in) {
              duration =
                this.props.transitionDuration !== undefined
                  ? this.props.transitionDuration
                  : 0.36;
              delay =
                this.props.transitionDelay !== undefined
                  ? this.props.transitionDelay
                  : 0.8;
              ease = "0.00, 0.00, 1.00, 1.00";
            } else {
              duration =
                this.props.exitDuration !== undefined
                  ? this.props.exitDuration
                  : this.props.isRoute
                  ? 0.3
                  : 0.3;
              delay =
                this.props.exitDelay !== undefined ? this.props.exitDelay : 0;
              ease = "0.33, 0.00, 0.00, 1.00"; // this.props.isRoute
              // ? "1.00, 0.00, 0.67, 1.00"
              // : "0.33, 0.00, 0.00, 1.00";
            }
            this.animation(
              node,
              this.props.in,
              duration,
              delay,
              this.props.ease !== undefined ? this.props.ease : ease,
              done
            );
          }
        }}
      >
        <p ref={this.props.textRef} style={{ ...this.props.style }}>
          {this.props.children}
        </p>
      </Transition>
    );
  }
}

export const SlidingTransitionType = {
  OPACITY: "opacity",
  SLIDE: "slide"
};
export class SlidingText extends Component {
  constructor(props) {
    super(props);
    this.firstMount = true;
  }
  // 1 0 0.67 1
  animationSlide(node, appear, duration, delay, ease, done) {
    TweenMax.fromTo(
      node,
      duration,
      {
        transform: !appear ? "translate(0, 0)" : `translate(0, 100%)`
      },
      {
        transform: !appear ? `translate(0, 100%)` : "translate(0, 0)",
        delay: delay,
        onComplete: () => {
          done();
        },
        ease: CustomEase.create("custom", ease)
      }
    );
  }

  animationOpacity(node, appear, duration, delay, ease, done) {
    TweenMax.fromTo(
      node,
      duration,
      {
        opacity: appear ? 0 : 1
      },
      {
        opacity: appear ? 1 : 0,
        delay: delay,
        onComplete: () => {
          done();
        },
        ease: CustomEase.create("custom", ease)
      }
    );
  }

  render() {
    return (
      <TextWrap ref={this.props.Sref} style={this.props.wrapStyle}>
        <Transition
          in={this.props.in}
          timeout={1350}
          appear={true}
          onEntered={() => (this.firstMount = false)}
          mountOnEnter={
            this.props.mountOnEnter !== undefined
              ? this.props.mountOnEnter
              : true
          }
          unmountOnExit={
            this.props.unmountOnExit !== undefined
              ? this.props.unmountOnExit
              : true
          }
          addEndListener={(node, done) => {
            if (!this.props.in && this.props.disableExitAnimation) {
              return;
            }
            let duration, delay;
            if (!this.props.isDisabled) {
              if (this.firstMount) {
                duration =
                  this.props.appearDuration !== undefined
                    ? this.props.appearDuration
                    : 0.86;
                delay =
                  this.props.appearDelay !== undefined
                    ? this.props.appearDuration
                    : 0.2;
              }

              if (this.props.in && this.props.isRoute) {
                duration =
                  this.props.routeDuration !== undefined
                    ? this.props.routeDuration
                    : 0.86;
                delay = !!this.props.routeDelay
                  ? this.props.routeDuration
                  : 0.95;
              } else if (this.props.in) {
                duration =
                  this.props.transitionDuration !== undefined
                    ? this.props.transitionDuration
                    : 0.76;
                delay =
                  this.props.transitionDelay !== undefined
                    ? this.props.transitionDelay
                    : 0.693;
              } else {
                duration =
                  this.props.exitDuration !== undefined
                    ? this.props.exitDuration
                    : this.props.isRoute
                    ? 0.4
                    : 0.86;
                delay =
                  this.props.exitDelay !== undefined ? this.props.exitDelay : 0;
              }

              if (this.props.transitionType === SlidingTransitionType.OPACITY) {
                this.animationOpacity(
                  node,
                  this.props.in,
                  0.233,
                  delay,
                  "0.17, 0.17, 0.83, 0.83",
                  done
                );
              } else {
                this.animationSlide(
                  node,
                  this.props.in,
                  duration,
                  this.props.delay ? this.props.delay : delay,
                  this.props.in
                    ? this.firstMount
                      ? "0.33, 0.00, 0.00, 1.00"
                      : "1.0, 0.0. 0.67, 1.0"
                    : "0.33, 0.00, 0.00, 1.00",
                  done
                );
              }
            }
          }}
        >
          <div style={{ ...this.props.style }}>
            <span>{this.props.children}</span>
          </div>
        </Transition>
      </TextWrap>
    );
  }
}
