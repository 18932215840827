import React from "react";

export const ColumnStepSizeContainer = ({
  className,
  blockWidth,
  blockHeight,
  positioningStyle = {},
  columnWidth,
  children
}) => {
  // console.log("BLOCK WIDTH: " + blockWidth)
  return (
    <div
      className={className}
      style={{
        position: "absolute",
        width:
          (blockWidth * columnWidth).toString() + "vw",
        height: "auto",
        ...positioningStyle
      }}
    >
      <div
        style={{
          paddingTop: ((blockHeight / blockWidth) * 100).toString() + "%",
          position: "relative"
        }}
      >
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%"
          }}
        >
          {children && children}
        </div>
      </div>
    </div>
  );
};
