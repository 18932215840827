import React, { Component } from "react";
import { fragShader, vertShader } from "./FOVTileScaleTransition";
import { Curtains } from "curtainsjs";
import { TweenMax } from "gsap";
import { CustomEase } from "../CustomEase";
import { MediaLoader } from "../MediaLoader";
import { isMobile } from "react-sizes/lib/presets";

const uuidv4 = require("uuid/v4");

export const mod = (i, N) => {
  return ((i % N) + N) % N;
};

export const FOVTransitionType = {
  SLIDESHOW: "slideshow",
  HOMEPAGE: "homepage"
};

export class FOVShaderImage extends Component {
  constructor(props) {
    super(props);
    this.preloaded = null;
    this.curtain = null;
    this.plane1 = React.createRef();
    this.plane2 = React.createRef();
    this.curtainPlanes = [];
    this.containerName = "canvas" + uuidv4();
    this.isAnimating = false;

    this.setupRendering = this.setupRendering.bind(this);
    this._setupRendering = this._setupRendering.bind(this);

    this.isPlane1Top = true;

    if (!!props.assets[0].type) {
      if (!!props.assets[0].blobURL) {
        this.preloaded = true;
        this.loader = { loadedAssets: {} };
        props.assets.forEach((e, i) => {
          let asset;
          if (e.type === "video") {
            asset = document.createElement("video");
            asset.preload = "none"; //true;
            asset.muted = true;
            asset.loop = true;
            asset.setAttribute("playsinline", "");
          } else if (e.type === "img") {
            asset = document.createElement("img");
            asset.crossOrigin = this.crossOrigin || "anonymous";
          }
          asset.src = e.blobURL;
          if (e.type === "video" && e.readyState === 0) {
            asset.load();
          }
          this.loader.loadedAssets[i.toString()] = asset;
        });
      } else {
        // Preload assets
        this.preloaded = false;
        this.loader = new MediaLoader(
          props.assets.map((e, i) => {
            return { name: i.toString(), ...e };
          })
        );
        this.loader.onAllSourcesLoaded(this.onAllSourcesLoaded.bind(this));
        this.loader.loadAll();
      }
    } else {
      this.preloaded = true;
      this.loader = { loadedAssets: {} };
      props.assets.forEach((e, i) => {
        if (e.tagName === "VIDEO" && e.readyState === 0) {
          // e.load();
        }
        this.loader.loadedAssets[i.toString()] = e;
      });
    }

    this.state = {
      focusIndex: this.props.startAssetIdx || 0
    };
  }

  previous() {
    let nextImageIdx = mod(this.state.focusIndex - 1, this.props.assets.length);
    this.setImageIndex(nextImageIdx);
  }

  next() {
    let nextImageIdx = mod(this.state.focusIndex + 1, this.props.assets.length);
    this.setImageIndex(nextImageIdx);
  }

  setAnchorPosition(plane, appear) {
    if (this.props.type === FOVTransitionType.HOMEPAGE) {
      plane.uniforms.anchorMode.value = 3.0;
      plane.uniforms.fovCenter.value = appear ? [0.5, 0.5] : [0.35, 0.35];
    } else {
      plane.uniforms.anchorMode.value = appear ? 1.0 : 0.0;
      plane.uniforms.fovCenter.value = appear ? [0.15, 0.15] : [0.25, 0.25];
    }
  }

  setImageIndex(idx, animate = true, completionBlock) {
    if (this.isAnimating) return;

    let currentBackPlane;
    let currentFrontPlane;
    let currentBackTexture;
    let currentFrontTexture;

    if (!this.isPlane1Top) {
      currentBackPlane = this.glPlane1;
      currentFrontPlane = this.glPlane2;
      currentBackTexture = this.plane1Texture;
      currentFrontTexture = this.plane2Texture;
    } else {
      currentBackPlane = this.glPlane2;
      currentFrontPlane = this.glPlane1;
      currentBackTexture = this.plane2Texture;
      currentFrontTexture = this.plane1Texture;
    }
    currentBackPlane.moveToFront();

    let nextImageIdx = mod(idx, this.props.assets.length);

    if (!animate) {
      currentBackPlane.uniforms.opacity.value = 0.0;
      currentBackPlane.uniforms.fov.value = 0.0;
      currentBackPlane.uniforms.scale.value = 1.0;
      currentBackTexture.setSource(
        this.loader.loadedAssets[nextImageIdx.toString()]
      );
      currentBackPlane.uniforms.opacity.value = 1.0;
      currentBackPlane.playVideos();
      currentBackPlane.planeResize();

      currentFrontPlane.uniforms.opacity.value = 0.0;
      if (currentFrontTexture.source.tagName === "VIDEO") {
        this.loader.loadedAssets[this.state.focusIndex].pause();
      }
    } else {
      this.isAnimating = true;
      currentBackPlane.uniforms.opacity.value = 0.0;
      currentBackTexture.setSource(
        this.loader.loadedAssets[nextImageIdx.toString()]
      );
      // currentFrontTexture.resize();
      // currentBackTexture.resize();
      // currentFrontTexture.resize();
      // this.curtain.resize();

      // window.requestAnimationFrame(() => {
      //     currentFrontTexture.resize();
      // });

      
      if (this.loader.loadedAssets[this.state.focusIndex].pause) {
        this.loader.loadedAssets[this.state.focusIndex].pause();
        // console.log("CURRENT FRONT TEXT PAUSE");
      }

      currentBackPlane.playVideos();
      // currentBackPlane.planeResize();
      

      this.setAnchorPosition(currentBackPlane, true);
      this.setAnchorPosition(currentFrontPlane, false);

      if (this.props.type === FOVTransitionType.SLIDESHOW) {
        this.transitionSlideshow(currentBackPlane, true, currentBackTexture);
        this.transitionSlideshow(currentFrontPlane, false, currentFrontTexture);
      } else if (this.props.type === FOVTransitionType.HOMEPAGE) {
        if (isMobile) {
          // TweenMax.ticker.fps(30);
          this.curtain.onRender(() => {
          //   this.curtain.glContext.flush();
            // currentFrontTexture.resize();
            // currentBackTexture.resize();
            // currentFrontTexture.resize();

          });
          // setTimeout(() => {
          //   this.curtain.onRender(() => {});
          //   TweenMax.ticker.fps(60);
          // }, 1000);
        }
        this.transitionHomepage(currentBackPlane, true, currentBackTexture);
        this.transitionHomepage(currentFrontPlane, false, currentFrontTexture);
      }
      setTimeout(() => {
        this.isAnimating = false;
        if (completionBlock) {
          completionBlock();
        }
      }, 800);
    }
    this.setState({
      focusIndex: nextImageIdx
    });
    this.isPlane1Top = !this.isPlane1Top;

  }

  onAllSourcesLoaded() {
    if (this.props.type === FOVTransitionType.SLIDESHOW) {
      // console.log("on all sourcess loaded");
    }

    if (this.props.onAllSourcesLoaded) {
      this.props.onAllSourcesLoaded(
        this.loader.assetObjectURLs
          ? this.loader.assetObjectURLs
          : this.loader.loadedAssets
      );
    }
    if (this.curtain) {
      let asset = this.loader.loadedAssets[this.state.focusIndex|| 0];
      this.setupRendering(asset, null);
    }
  }

  updateAssets(index, video) {
    this.loader.loadedAssets[index.toString()] = video;

    if (index === this.state.focusIndex) {
      let currentFrontPlane;
      let currentFrontTexture;

      if (this.isPlane1Top) {
        currentFrontPlane = this.glPlane1;
        currentFrontTexture = this.plane1Texture;
      }
      else {
        currentFrontPlane = this.glPlane2;
        currentFrontTexture = this.plane2Texture;
      }

  
      currentFrontTexture.setSource(video);
      currentFrontPlane.planeResize();
      currentFrontPlane.playVideos();
      
    }
  }

  componentDidMount() {
    this.curtain = new Curtains({
      container: this.containerName,
      autoRender: false,
      production: true
    });
    this.curtain.disableDrawing();

    let params = {
      autoloadSources: false,
      shareProgram: true,
      vertexShader: vertShader,
      fragmentShader: fragShader,
      uniforms: {
        opacity: {
          name: "uOpacity",
          type: "1f",
          value: 1.0
        },
        scale: {
          name: "uScale",
          type: "1f",
          value: 1.0
        },
        fov: {
          name: "uFOV",
          type: "1f",
          value: 0.0
          // value: 0.8,
        },
        fovCenter: {
          name: "uFOVCenter",
          type: "2fv",
          // value: [0.5, 0.5],
          value: [0.0, 0.0]
        },
        // Anchor mode: 0 (top left), 1 (bottom right), 2 (top right), 3 (bottom left)
        anchorMode: {
          name: "uAnchorMode",
          type: "1f",
          value: 1.0
        }
      }
    };

    this.glPlane1 = this.curtain.addPlane(this.plane1.current, params);
    this.glPlane2 = this.curtain.addPlane(this.plane2.current, params);
    this.glPlane1.enableDepthTest(false);
    this.glPlane2.enableDepthTest(false);
    this.glPlane1.moveToFront();

    this.plane1Texture = this.glPlane1.createTexture("assetTexture");
    this.plane2Texture = this.glPlane2.createTexture("assetTexture");

    if (this.preloaded) {
      this.setupRendering(
        this.loader.loadedAssets[`${this.state.focusIndex || 0}`],
        null
      );
    }
    // Ref after setting up
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    // console.log("DISPOSING CURTAIN");
    this.curtain.dispose();
  }

  _setupRendering(texturePlane, isPlane2) {
    if (this.props.type === FOVTransitionType.SLIDESHOW) {
      // console.log("_setupRendering");
    }
    if (isPlane2) {
      this.plane2Texture.setSource(texturePlane);
    } else {
      this.plane1Texture.setSource(texturePlane);
    }
    this.glPlane1.playVideos();
    this.curtain.enableDrawing();
    this.glPlane1._shouldDraw = true;
    this.glPlane2._shouldDraw = true;
  }

  setupRendering(plane1Texture, plane2Texture) {
    if (this.props.type === FOVTransitionType.SLIDESHOW) {
      // console.log("setupRendering");
      // console.log(plane1Texture);
      // console.log(plane1Texture.tagName);
    }

    if (plane1Texture.tagName === "VIDEO") {
      if (plane1Texture.readyState !== 0) {
        // console.log("FOV plane1text has video data");
        this._setupRendering(plane1Texture);
        this.curtain._animate();
      } else {
        // console.log("FOV plane1text setting up event listeners");
        // console.log(plane1Texture.src);
        // console.log(plane1Texture.load());
        plane1Texture.addEventListener("loadeddata", () => {
          // console.log("FOV plane1text has video loadeddata data");
          // console.log(plane1Texture.readyState);
          this._setupRendering(plane1Texture);
          this.curtain._animate();
        });
      }
    } else if (plane1Texture.tagName === "IMG") {
      if (plane1Texture.complete) {
        if (this.props.type === FOVTransitionType.SLIDESHOW) {
          // console.log("FOV plane1text has img data");
          // console.log(plane1Texture);
        }
        this._setupRendering(plane1Texture);
        this.curtain._animate();
      } else {
        plane1Texture.onload = () => {
          // console.log("FOV plane1text onload has img data");
          // console.log(plane1Texture);
          if (plane1Texture.complete) {
            this._setupRendering(plane1Texture);
            this.curtain._animate();
          }
        };
      }
    }
  }

  transitionSlideshow(plane, isAppear, texture) {
    // texture.shouldUpdate = true;
    // plane._shouldDraw = true;
    if (this.props.onAnimating) {
      this.props.onAnimating();
    }

    if (!isAppear) {
      //0.33, 0.00, 0.83, 0.83
      TweenMax.fromTo(
        plane.uniforms.scale,
        0.68,
        {
          value: 1.0
        },
        {
          value: 0.27,
          delay: 0.0,
          onComplete: () => {
            texture.shouldUpdate = false;
            if (texture.tagName === "VIDEO") {
              texture.pause();
            }
          },
          ease: CustomEase.create("custom1", "0.84, 0.00, 0.31, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.fov,
        0.68,
        {
          value: 0.0
        },
        {
          value: 9.0,
          delay: 0.0,
          ease: CustomEase.create("custom2", "0.33, 0.00, 0.83, 0.83")
        }
      );
    } else {
      TweenMax.fromTo(
        plane.uniforms.fov,
        0.68,
        {
          value: 9.0
        },
        {
          value: 0.0,
          delay: 0.08,
          ease: CustomEase.create("custom1", "0.17, 0.17, 0.67, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.scale,
        0.44,
        {
          value: 0.27
        },
        {
          value: 1.0,
          delay: 0.32,
          onComplete: () => {
            if (this.props.onEndAnimation) {
              this.props.onEndAnimation();
            }
          },
          ease: CustomEase.create("custom2", "0.69, 0.00, 0.16, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.opacity,
        0.12,
        {
          value: 0.0
        },
        {
          value: 1.0,
          delay: 0.48,
          ease: CustomEase.create("custom3", "0.17, 0.17, 0.83, 0.83")
        }
      );
    }
  }

  transitionHomepage(plane, isAppear, texture) {
    // texture.shouldUpdate = true;
    // plane._shouldDraw = true;
    if (this.props.onAnimating) {
      this.props.onAnimating();
    }
    if (!isAppear) {
      //0.33, 0.00, 0.83, 0.83
      TweenMax.fromTo(
        plane.uniforms.scale,
        0.7133,
        {
          value: 1.0
        },
        {
          value: 0.0,
          delay: 0.0,
          onComplete: () => {
            texture.shouldUpdate = false;

            if (texture.tagName === "VIDEO") {
              texture.pause();
            }
          },
          ease: CustomEase.create("custom1", "0.52, 0.00, 0.56, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.fov,
        0.45,
        {
          value: 0.0
        },
        {
          value: 8.0,
          delay: 0.0,
          ease: CustomEase.create("custom2", "0.33, 0.00, 0.83, 0.83")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.opacity,
        0.2,
        {
          value: 1.0
        },
        {
          value: 0.05,
          delay: 0.39,
          ease: CustomEase.create("custom2", "0.33, 0.00, 0.83, 0.83")
        }
      );

      // TweenMax.fromTo(
      //   plane.uniforms.fovCenter,
      //   0.45,
      //   {
      //     value: [0.5, 0.5],
      //   },
      //   {
      //     value: [0.2, 0.2],
      //     delay: 0.0,
      //     onComplete: () => {},
      //     ease: CustomEase.create("custom2", "0.33, 0.00, 0.83, 0.83")
      //   }
      // );
    } else {
      TweenMax.fromTo(
        plane.uniforms.fov,
        0.333,
        {
          value: 12.0
        },
        {
          value: 0.0,
          delay: 0.45,
          onComplete: () => {
            if (this.props.onEndAnimation) {
              this.props.onEndAnimation();
            }
          },
          ease: CustomEase.create("custom1", "0.17, 0.17, 0.67, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.scale,
        0.78,
        {
          value: 10.0
        },
        {
          value: 1.0,
          delay: 0.0,
          ease: CustomEase.create("custom2", "0.34, 0.00, 0.22, 1.00")
        }
      );

      TweenMax.fromTo(
        plane.uniforms.opacity,
        0.0333,
        {
          value: 0.0
        },
        {
          value: 1.0,
          delay: 0.483,
          ease: CustomEase.create("custom3", "0.17, 0.17, 0.83, 0.83")
        }
      );
    }
  }

  render() {
    return (
      <div
        style={{
          ...this.props.style
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative"
          }}
        >
          <div
            ref={this.props.fovContainer}
            id={this.containerName}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute"
            }}
          />
          <div
            ref={this.plane1}
            style={
              this.props.planeStyle
                ? this.props.planeStyle
                : {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "auto",
                    height: "auto",
                    minWidth: "100%",
                    minHeight: "100%",
                    transform: "translate(-50%, -50%)"
                  }
            }
          />
          <div
            ref={this.plane2}
            style={
              this.props.planeStyle
                ? this.props.planeStyle
                : {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "auto",
                    height: "auto",
                    minWidth: "100%",
                    minHeight: "100%",
                    transform: "translate(-50%, -50%)"
                  }
            }
          />
        </div>
      </div>
    );
  }
}
