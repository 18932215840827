import React, { Component, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import { ColumnGrid } from "components/ColumnGrid";
import { RoutedGLBackground } from "pages/GLBackground";

import { Header } from "components/Header";
import { Cursor, CursorClickTransitions } from "components/Cursor";
import { ScrollWrapper, ScrollDirection } from "components/ScrollWrapper";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import { createGlobalStyle } from "styled-components";
import { Loader } from "pages/Loader";
import { MediaLoader } from "./MediaLoader";
import { LayoutMode } from "./Layouts";

import { withSizes } from "react-sizes";
import { isMobileOnly, isMobile, isChrome } from "react-device-detect";

import DocumentTitle from "react-document-title";
import { layoutSizesMapToProps } from "./Layouts";
import Home from "pages/Home";
import Projects from "pages/Projects";
import About from "pages/About";
import NewWork from "pages/NewWork";
import Contact from "pages/Contact";
import { setBlogData } from "./pages/home/BlogSection";
import { setDribbbleData, dribbbleIDs } from "./pages/SampleWork";
import moment from "moment";
import { BackupAboutData } from "./pages/About";
import Ukraine from "./pages/Ukraine";

const FadeTransitions = `
.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

.fade-exit-done {
  opacity: 0;
}
`

const GlobalStyle = createGlobalStyle`
${CursorClickTransitions}
${FadeTransitions}
`;

const GLBackgroundAssets = [
   {
        name: "homepage0",
        placeholder: !isMobileOnly ? "https://d1i4luv9ibe252.cloudfront.net/homepage0.new.jpg" : "https://d1i4luv9ibe252.cloudfront.net/homepage0.mobile.new.jpg",
        video: !isMobileOnly
          ? "https://d1i4luv9ibe252.cloudfront.net/homepage0.new.mp4"
          : "https://d1i4luv9ibe252.cloudfront.net/homepage0.mobile.new.mp4",
      },
      {
        name: "homepage1",
        placeholder: !isMobileOnly ? "https://d1i4luv9ibe252.cloudfront.net/homepage.1.2022.jpg" : "https://d1i4luv9ibe252.cloudfront.net/homepage.1.2022.jpg",
        video: !isMobileOnly
           ? "https://d1i4luv9ibe252.cloudfront.net/homepage.1.2022.mp4"
          : "https://d1i4luv9ibe252.cloudfront.net/homepage.1.2022.mp4",
      
      },
      {
        name: "homepage2",
        placeholder: !isMobileOnly ? "https://d1i4luv9ibe252.cloudfront.net/homepage.2.2022.jpg" : "https://d1i4luv9ibe252.cloudfront.net/homepage.2.2022.jpg",
        video: 
           !isMobileOnly  ? "https://d1i4luv9ibe252.cloudfront.net/hompage.2.2022.mp4"
          : "https://d1i4luv9ibe252.cloudfront.net/hompage.2.2022.mp4",

      },
      {
        name: "work0",
        placeholder:  "https://d1i4luv9ibe252.cloudfront.net/work/product_design.jpg",
        video: "https://d1i4luv9ibe252.cloudfront.net/work/product_short.mp4",
      },
      {
        name: "work1",
        placeholder:  "https://d1i4luv9ibe252.cloudfront.net/work/motion_graphics.jpg",
        video: "https://d1i4luv9ibe252.cloudfront.net/work/motion_short_2.mp4",
      },
      {
        name: "work2",
        placeholder: "https://d1i4luv9ibe252.cloudfront.net/work/cgi_poem.jpg",
        video: "https://d1i4luv9ibe252.cloudfront.net/work/cgi_poem.mp4",
      },
      {
        name: "work3",
        placeholder: "https://d1i4luv9ibe252.cloudfront.net/work/airbus.jpg", 
        video: "https://d1i4luv9ibe252.cloudfront.net/work/Airbus_short_video.mp4",
      },
      {
        name: "work4",
        placeholder: "https://d1i4luv9ibe252.cloudfront.net/work/automotive.jpg",
        video: "https://d1i4luv9ibe252.cloudfront.net/work/automotive_short.mp4",
    
      },
      {
        name: "work5",
        placeholder: "https://d1i4luv9ibe252.cloudfront.net/work/gold.jpg",
        video: "https://d1i4luv9ibe252.cloudfront.net/work/gold_cgi.mp4",
      
      },
      { name: "about", 
        placeholder: require("assets/about.jpg"), 
      },
      { name: "contact", 
        placeholder: require("assets/contact.jpg"), 
      }
];


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      loaderTransition: false,
      title: "Milkinside",
      dribbbleIDs: [],
      aboutData: BackupAboutData,
      loaderAsset: null,
      placeholderReplacementCount: 0,
      fullscreen: false,
      autoadvance: false,
    };
    this.mobileVideo = React.createRef();

    this.loaderAssetLoader = new MediaLoader([
      {
        name: "loader",
        src: "https://d1i4luv9ibe252.cloudfront.net/Logo_v3_1_rgb.mp4",
        type: "video",
        forceEntireLoad: true
      }
    ]);

    this.isAnimating = true;

    // this.getMediumData();
    // this.getDribbbleShots();
    // this.getAboutSectionData();

    this.loaderAssetLoader.onSourceLoaded(this.handleLoaderAsset.bind(this));
    // this.placeholderAssetLoader.onAllSourcesLoaded(
    //   this.handlePlaceholderAssetsLoaded.bind(this)
    // );
    // this.assetLoader.onSourceLoaded(this.handleAssetLoaded.bind(this));

    this.handleScroll = this.handleScroll.bind(this);
    this.handleFinishLoaderTransition = this.handleFinishLoaderTransition.bind(
      this
    );

    this.homepageAssets = [null, null, null];
    this.projectsAssets = [null, null, null];
    this.aboutAssets = [null];
    this.contactAssets = [null];

    this.header = React.createRef();
    this.loader = React.createRef();
    // Foreground Content Ref
    this.homeContent = React.createRef();
    this.projectsContent = React.createRef();
    this.aboutContent = React.createRef();
    this.contactContent = React.createRef();

    this.background = null;
  }

  handleLoaderAsset(_, asset) {
    this.setState({ loaderAsset: asset });
    this.getMediumData();
  }

  handleAssetLoaded(name, asset) {
    if (name.includes("homepage")) {
      let homepageIndex = name[name.length - 1];
      this.homepageAssets[parseInt(homepageIndex, 10)] = asset;
      // this.background.forceUpdate();
      // this.background.slideshow.forceUpdate();
    } else if (name.includes("work")) {
      let workIndex = name[name.length - 1];
      this.projectsAssets[parseInt(workIndex, 10)] = asset;
      // this.background.forceUpdate();
      // this.background.slideshow.forceUpdate();
    }

    if (!this.state.loader) {
      this.setState({
        homepageAssets: this.homepageAssets,
        projectsAssets: this.projectsAssets,
        placeholderReplacementCount: this.state.placeholderReplacementCount + 1
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (["/about", "/contact"].includes(this.props.location.pathname)) {
        this.setState({
          loader: true,
          loaderTransition: true
        });
      } else if (["/about", "/contact"].includes(prevProps.location.pathname)) {
        this.setState({
          loader: true,
          loaderTransition: true
        });
      } 
      // else if (this.currentForeground.state.page !== 0) {
      //   this.setState({
      //     loader: true,
      //     loaderTransition: true
      //   });
      // }
    }
    this.setCurrentForegroundRef();

    if (prevProps.assetMode !== this.props.assetMode) {
      if (this.props.assetMode === LayoutMode.MOBILE) {
      }
    }
  }

  formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    // console.log(date);
    var day = date.getDay();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }

  getMediumData() {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/milkinside"
    )
      .then(res => res.json())
      .then(data => {
        //Do things
        let mediumData = [];
        data.items.forEach((item, idx) => {
          //  let date = new Date(Date.parse(item.pubDate));
          mediumData.push({
            title: item.title,
            icon: item.thumbnail.replace(/max\/[0-9]+/, "max/200"),
            date: moment(item.pubDate).format("d MMMM YYYY"), //this.formatDate(date),
            href: item.link
          });
          setBlogData(mediumData);
        });

        //  console.log(this.mediumData);
      });
  }

  getDribbbleShots() {
    fetch("https://web-cms.milkinside.digital/dribbbleShots")
      .then(res => res.json())
      .then(data => {
        setDribbbleData(data);
        this.setState({
          dribbbleIDs: dribbbleIDs
        });
        // console.log(data)
      });
  }

  getAboutSectionData() {
    fetch("https://web-cms.milkinside.digital/aboutsections")
      .then(res => res.json())
      .then(data => {
        let aboutData = [];
        data.sort((x, y) => (x.order > y.order ? 1 : -1));
        data.forEach(e => {
          e.aboutassets.sort((x, y) => (x.order > y.order ? 1 : -1));
          aboutData.push({
            title: e.title,
            description: e.description.split("\n").filter(x => x !== ""),
            topLine: e.topline,
            bottomLine: e.bottomline,
            images: e.aboutassets.map(x => ({
              type: x.type,
              src: `https://d1i4luv9ibe252.cloudfront.net/AboutAssets/${x.file.sha256}${x.file.ext}`
            }))
          });
        });
        this.setState({
          aboutData
        });
      })
      .catch(err => {
        this.setState({
          aboutData: BackupAboutData
        });
        return;
      });
  }

  componentDidMount() {
    console.log("%c Milkinside Inc. ", "color:white;background:#000000");

    // Should this be done in a DOM event handler?
    this.loaderAssetLoader.loadAll();

    document.addEventListener("touchend", function(e) {
      e.preventDefault();

      e.target.click();
    });

    this.setCurrentForegroundRef();
  }

  setCurrentForegroundRef() {
    switch (this.props.location.pathname) {
      case "/":
        this.currentForeground = this.homeContent.current;
        break;
      case "/work":
        this.currentForeground = this.projectsContent.current;
        break;
      case "/about":
        this.currentForeground = this.aboutContent.current;
        break;
      case "/contact":
        this.currentForeground = this.contactContent.current;
    }
  }

  handleFinishLoaderTransition() {
    this.setState({
      loader: false
    });
    if (this.currentForeground.appear) {
      this.currentForeground.appear();
    }
  }

  canPageHandleDirection(direction) {
    if (this.props.location.pathname === '/stand-with-ukraine') return false
    if (this.state.loader) {
      return false;
    }
    if (this.header.current.state.menu) {
      return false;
    }
    if (this.isAnimating) {
      return false;
    }
    if (
      [ScrollDirection.up, ScrollDirection.down].includes(direction) &&
      (!this.currentForeground.canScroll ||
        !this.currentForeground.canScroll(direction))
    ) {
      return false;
    }
    if (
      [ScrollDirection.right, ScrollDirection.left].includes(direction) &&
      this.currentForeground.canHandleRightLeftArrow &&
      !this.currentForeground.canHandleRightLeftArrow()
    ) {
      return false;
    }

    return true;
  }

  // This only works for backgrounds that have a max of two variations
  handleScroll(direction) {
    if (!this.canPageHandleDirection(direction)) return;

    if (direction === ScrollDirection.up) {
      if (this.currentForeground.shouldBackgroundScroll(direction)) {
        this.background.scrollUp();
        this.header.current.transitionToState(true);
      }
      this.currentForeground.scrollUp();
    } else if (direction === ScrollDirection.down) {
      if (this.currentForeground.shouldBackgroundScroll(direction)) {
        this.background.scrollDown();
        this.header.current.transitionToState(false);
      }
      this.currentForeground.scrollDown();
    } else if (direction === ScrollDirection.left) {
      if (this.props.location.pathname === "/") {
        this.background.previous();
        this.currentForeground.prevText();
      }
    } else if (direction === ScrollDirection.right) {
      if (this.props.location.pathname === "/") {
        this.background.next();
        this.currentForeground.nextText();
      }
    }
  }

  handleHomeRightArrow() {
    if (this.props.location.pathname === "/stand-with-ukraine") return
    if (this.props.location.pathname === "/" || this.props.location.pathname === "/work" && !this.isAnimating) {
    console.log("handleHomeRightArrow")
      this.background.next();
      this.currentForeground.nextText();
    }
  }

  handleHomeLeftArrow() {
    if (this.props.location.pathname === "/stand-with-ukraine") return
    if (this.props.location.pathname === "/" || this.props.location.pathname === "/work" && !this.isAnimating) {
    console.log("handleHomeLeftArrow")
      this.background.previous();
      this.currentForeground.prevText();
    }
  }


  render() {
    return (
      <ScrollWrapper
        onScroll={this.handleScroll}
        onKeypress={this.handleKeypress}
        enable={true}
      >
        <RoutedGLBackground
          onAnimating={() => {
            // console.log("gl backgroudn is animating");
            this.isAnimating = true;
          }}
          onEndAnimation={() => {
            // console.log("gl background is done animating");
            this.isAnimating = false;
          }}
          onAllPlaceholderSourcesLoaded={() => {
            this.isAnimating = false;
            this.setState({
              loader: false,
              autoadvance: true,
            });
          }}
          onRef={e => {
            this.background = e;
          }}
          assets={GLBackgroundAssets}
          
        />

        <Header
          ref={this.header}
          {...this.props}
          paged={this.state.backgroundPaged}
          fullscreen={this.state.fullscreen}
        />
        <ColumnGrid {...this.props} />

        {this.props.location.pathname === "/" && (
          <DocumentTitle title="Milkinside - Premium Design Partner" />
        )}
        {this.props.location.pathname === "/work" && (
          <DocumentTitle title="Milkinside - Work" />
        )}
        {this.props.location.pathname === "/about" && (
          <DocumentTitle title="Milkinside - About" />
        )}
        {this.props.location.pathname === "/contact" && (
          <DocumentTitle title="Milkinside - Contact" />
        )}

        <Route
          exact
          path="/"
          children={({ match, ...props }) => (
            <Fragment>
              <TransitionGroup component={null}>
                {match && (
                  <Home
                    {...this.props}
                    {...props}
                    onAnimating={() => {
                      console.log("home is animating");
                      this.isAnimating = true;
                    }}
                    onEndAnimation={() => {
                      console.log("home is done animating");
                      this.isAnimating = false;
                    }}
                    onOpenUkraine={() => this.setState({ukraine: true})}
                    ref={this.homeContent}
                    onRightArrow={() => this.handleHomeRightArrow()}
                    onLeftArrow={() => this.handleHomeLeftArrow()}
                    autoadvance={this.state.autoadvance}
                  />
                )}
              </TransitionGroup>
            </Fragment>
          )}
        />
        <Route
          exact
          path="/work"
          children={({ match, ...props }) => (
            <Fragment>
              <TransitionGroup component={null}>
                {match && (
                  <NewWork ref={this.projectsContent} {...this.props} {...props}
                    onRightArrow={() => this.handleHomeRightArrow()}
                    onLeftArrow={() => this.handleHomeLeftArrow()}
                    onFullscreen={(fs) => {
                      this.setState({
                        fullscreen: fs
                      });
                    }}
                  />
                )}
              </TransitionGroup>
            </Fragment>
          )}
        />
        <Route
          exact
          path="/about"
          children={({ match, ...props }) => (
            <Fragment>
              <TransitionGroup component={null}>
                {match && (
                  <About
                    {...this.props}
                    {...props}
                    data={this.state.aboutData}
                    ref={this.aboutContent}
                  />
                )}
              </TransitionGroup>
            </Fragment>
          )}
        />
        <Route
          exact
          path="/contact"
          children={({ match, ...props }) => (
            <Fragment>
              <TransitionGroup component={null}>
                {match && (
                  <Contact
                    {...this.props}
                    {...props}
                    ref={this.contactContent}
                    onAnimating={() => {
                      this.isAnimating = true;
                    }}
                    onEndAnimation={() => {
                      this.isAnimating = false;
                    }}
                  />
                )}
              </TransitionGroup>
            </Fragment>
          )}
        />
        <Loader
          {...this.props}
          ref={this.loader}
          asset={this.state.loaderAsset}
          in={this.state.loader}
          transition={this.state.loaderTransition}
          onFinishTransition={this.handleFinishLoaderTransition}
        />
        {/* <UkraineBanner
          top={`${this.props.logoWidth * 0.44}vw`}
          left={`${ this.props.leftMargin}vw`}
          width={`${5 * this.props.columnWidth}vw`}
        /> */}
        <Route
          exact
          path="/stand-with-ukraine"
          children={({ match, ...props }) => (
            <CSSTransition
             in={match}
             timeout={300}
             unmountOnExit
             classNames="fade"
            >
              <Ukraine 
                // onClose={() => this.setState({ukraine: false})}
              />
            </CSSTransition>
          )}
        />
       
        {!isMobile && <Cursor />}
        <GlobalStyle />
      </ScrollWrapper>
     
    );
  }
}

export default withRouter(withSizes(layoutSizesMapToProps)(App));
