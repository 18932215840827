import React, { Component } from "react";
import styled from "styled-components";

import { ColumnStepSizeContainer } from "components/ColumnStepSizeContainer";

export const ArrowButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  justifycontent: center;
  alignitems: center;
  height: 100%;
  width: 100%;
  transition: transform 0.1s ease-out;
  &:active {
    transform: scale(1.5);
  }
`;
export const ArrowSVG = styled.img`
  height: ${props => props.pheight}vw;
  pointer-events: none;
`;

// export const ArrowBlock = ({ aref, left, right, onClick, positionStyle = {}, cWidth, cHeight }) => (
export class ArrowBlock extends Component {
  render() {
    const { aref, left, right, onClick, positionStyle = {} } = this.props;
    return (
      <ColumnStepSizeContainer
        {...this.props}
        blockHeight={0.25}
        blockWidth={0.25}
        positioningStyle={positionStyle}
      >
        <ArrowButton
          ref={aref}
          onClick={onClick}
          style={{
            background: "none",
            border: "none",
            outline: "none",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%"
          }}
        >
          {left && (
            <ArrowSVG
              pheight={0.1 * this.props.columnWidth}
              src={require("assets/LeftArrow.svg")}
            />
          )}
          {right && (
            <ArrowSVG
              pheight={0.1 * this.props.columnWidth}
              src={require("assets/RightArrow.svg")}
            />
          )}
        </ArrowButton>
      </ColumnStepSizeContainer>
    );
  }
}
