import React, { Component } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";

// TODO: Convert components to functional components

const OverflowMask = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;


export class SlidingAppearBlock extends Component {
  constructor(props) {
    super(props);
    this.firstMount = true;
  }

  render() {
    const { left, top, image, width, height } = this.props;

    return (
      <div
        ref={this.props.aref}
        style={{
          position: "absolute",
          left: left,
          top: top,
          height: height,
          width: width
        }}
      >
        <Transition
          in={this.props.in}
          timeout={2000}
          appear={this.props.isAppear !== undefined ? this.props.isAppear : true}
          unmountOnExit={true}
          mountOnEnter={true}
          addEndListener={(node, done) => {
            if (!this.props.in && this.props.disableExitAnimation) {
              return;
            }

            if (this.props.in) {
              let delay;
              let duration;
              // Route
              duration = 0.55;
              if (this.props.isRoute) {
                delay = 0.95;
              }
              // Appear
              else if (this.firstMount && !this.props.isTransition) {
                delay = 0.35;
                this.firstMount = false;
              }
              // Transition
              else {
                delay = 0.55;
              }
              TweenMax.fromTo(
                node,
                duration,
                {
                  width: "0%"
                },
                {
                  width: "100%",
                  delay: delay,
                  onComplete: () => {
                    // TweenMax.set(node, { clearProps: "transform" });
                    done();
                    if (this.props.onEndAnimation) {
                      this.props.onEndAnimation(this.firstMount);
                    }
                    if (this.firstMount) {
                      this.firstMount = false;
                    }
                  },
                  ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00")
                }
              );
            } else {
              TweenMax.fromTo(
                node,
                0.5,
                {
                  width: "100%"
                },
                {
                  width: "0%",
                  delay: 0.0,
                  onComplete: () => {
                    done();
                  },
                  ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00")
                }
              );
            }
          }}
        >
          <OverflowMask>{this.props.children}</OverflowMask>
        </Transition>
      </div>
    );
  }
}
