import { MediaLoader } from "./MediaLoader";

let cloudfrontCDNURL = "https://d1i4luv9ibe252.cloudfront.net";
let accessCode = "15ef7dffb7a6b425efc84211bece13a27208f7a9f656720a9a23d6b9eec33daa"
let dribbbleAPI = "https://api.dribbble.com/v2/user/shots?access_token=";
const dribbbleAPISingle = id =>  `${cloudfrontCDNURL}/dribbbleShots/${id}.json`;

// export async function getDribbbleShots() {
//     let resp = await fetch(dribbbleAPI + accessCode);
//     let data = await resp.json()
//     return data;
// }

export async function getDribbbleShot(id) {
    let resp = await fetch(dribbbleAPISingle(id));
    let data = await resp.json()
    return data;
}

export const DribbleCache = {};

// const dribbleLoader = new MediaLoader()

