import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";
import { isMobileOnly, isMobile } from "react-device-detect";
import { AnimatedLine, LineAnimationStyle } from "components/SlidingText";

import { TweenMax } from "gsap";
import { LayoutMode, responsiveFontSize } from "../Layouts";
import CustomEase from "../CustomEase";
import { UkraineBanner } from "../pages/Ukraine";

// This hopefully should be reused?
const BoxedCenteredContent = styled.div`
  background: ${props => props.color};
  height: 100%;
  width: ${props => props.width};
  overflow: hidden;
  position: relative;
`;

// TODO: The font seems off
// const Logo = light, slide, hideSlide }) => (
const LogoTransition = {
  WHITE_TO_BLACK: "whitetoblack",
  BLACK_TO_WHITE: "blacktowhite"
};
const LogoTransitionDirection = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
  NONE: "none" // just color animation
};
class Logo extends Component {
  constructor(props) {
    super(props);
    this.vertLogoContainerSlider = React.createRef();
    this.horLogoContainerSlider = React.createRef();
    this.backgroundContainer = React.createRef();
    this.text = React.createRef();
  }

  setTo(black) {
    this.backgroundContainer.current.style.background = black
      ? "black"
      : "white";
    this.text.current.style.color = black ? "white" : "black";
  }

  animateTranstion(type, direction, completion) {
    // if (
    //   (type === LogoTransition.BLACK_TO_WHITE &&
    //     this.backgroundContainer.current.style.background === "white") ||
    //   (type === LogoTransition.WHITE_TO_BLACK &&
    //     this.backgroundContainer.current.style.background === "black")
    // ) {
    //   return;
    // }

    if (direction === LogoTransitionDirection.NONE) {
      TweenMax.fromTo(
        this.text.current,
        0.233,
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "white" : "black"
        },
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "black" : "white",
          delay: 0.166,
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
      TweenMax.fromTo(
        this.backgroundContainer.current,
        0.233,
        {
          background: type === LogoTransition.BLACK_TO_WHITE ? "black" : "white"
        },
        {
          background:
            type === LogoTransition.BLACK_TO_WHITE ? "white" : "black",
          delay: 0.166,
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
    } else if (
      [LogoTransitionDirection.LEFT, LogoTransitionDirection.RIGHT].includes(
        direction
      )
    ) {
      // MOBILE CASE
      let duration = 0.4;
      let delay = 0.0;
      TweenMax.set(this.horLogoContainerSlider.current, {
        left:
          direction === LogoTransitionDirection.RIGHT
            ? "100vw"
            : `-${this.props.logoWidth}vw`,
        background: type === LogoTransition.BLACK_TO_WHITE ? "white" : "black"
      });

      TweenMax.fromTo(
        this.horLogoContainerSlider.current,
        duration,
        {
          left: direction === LogoTransitionDirection.RIGHT ? "100vw" : `-100vw`
        },
        {
          left: "0vw",
          delay: delay,
          onComplete: () => {
            this.setTo(type === LogoTransition.BLACK_TO_WHITE ? false : true);
            this.horLogoContainerSlider.current.style.left = "100vw";
            if (completion) {
              completion();
            }
          },
          ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
        }
      );

      TweenMax.fromTo(
        this.text.current,
        0.15,
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "white" : "black"
        },
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "black" : "white",
          delay: 0.2,
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
    } else if (
      [LogoTransitionDirection.TOP, LogoTransitionDirection.BOTTOM].includes(
        direction
      )
    ) {
      // Desktop case
      let duration = 1.06;
      let delay = 0.0;
      TweenMax.set(this.vertLogoContainerSlider.current, {
        background: type === LogoTransition.BLACK_TO_WHITE ? "white" : "black"
      });

      TweenMax.fromTo(
        this.vertLogoContainerSlider.current,
        duration,
        {
          top: direction === LogoTransitionDirection.TOP ? "-100vh" : "100vh"
        },
        {
          top: "0vh",
          delay: delay,
          onComplete: () => {
            this.setTo(type === LogoTransition.BLACK_TO_WHITE ? false : true);
            this.vertLogoContainerSlider.current.style.top = "-100vh";
            if (completion) {
              completion();
            }
          },
          ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
        }
      );

      TweenMax.fromTo(
        this.text.current,
        0.3,
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "white" : "black"
        },
        {
          color: type === LogoTransition.BLACK_TO_WHITE ? "black" : "white",
          delay: type === LogoTransition.BLACK_TO_WHITE ? 0.15 : 0.6,
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
    }
  }

  render() {
    return (
      <BoxedCenteredContent
        ref={this.backgroundContainer}
        width={`${this.props.logoWidth}vw`}
      >
        <div
          ref={this.vertLogoContainerSlider}
          style={{
            position: "absolute",
            top: "-100vh",
            left: 0,
            width: `${this.props.logoWidth}vw`,
            height: "100vh"
          }}
        />
        <div
          ref={this.horLogoContainerSlider}
          style={{
            position: "absolute",
            top: 0,
            width: `100vw`,
            height: "100%"
          }}
        />
        <Link
          to="/"
          style={{
            textDecoration: "none",
            position: "absolute",
            height: "100%",
            width: "100%"
          }}
        >
          <h1
            ref={this.text}
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
              margin: 0,
              padding: 0,
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              textDecoration: "none",
              fontFamily: "Aktiv Grotesk W01 Bold1370651",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              textAlign: "center",
              textRendering: "optimizeLegibility",
              fontSize: `${responsiveFontSize(20, 16)}vw`,
              pointerEvents: "none"
            }}
          >
            MILKINSIDE.
          </h1>
        </Link>
      </BoxedCenteredContent>
    );
  }
}

const NavigationBarStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

// TODO: Add animation to hover
// TODO: Figure out why hover is not working
const NavigationLinkContainer = styled.div`
  padding-left: ${props => `${props.logoWidth * 0.1}vw`};
  width: ${props => props.logoWidth}vw;
`;

const NavigationText = styled.div`
  font-family: ${props =>
    props.selected
      ? "Aktiv Grotesk W01 Bold1370651"
      : "Aktiv Grotesk W01 Regular"};
  line-height: normal;
  font-weight: lighter;
  font-size: ${props => props.fontSize}vw;
  transition: transform 0.1s ease-out;
  transform-origin: 25% 35%;
  z-index: 1;
  pointer-events: none;

  ${props =>
    props.selected
      ? ""
      : `
      &:hover {
          transform: scale(1.3);
      }`}

  &:active {
    font-family: "Aktiv Grotesk W01 Bold1370651";
    transform: scale(1);
    opacity: 1;
  }

  ${props =>
    props.selected
      ? `
  opacity: 1;
  `
      : props.black || props.location !== "/"
      ? `opacity: 0.4;`
      : `opacity: 1;`}
`;

const NavigationLink = styled(Link)`
  text-decoration: none;
  position: relative;
  display: inline-block;
`;

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      black: props.location === "/contact" ? true : false
    };
    this.verticalSlider = React.createRef();
    this.projectsText = React.createRef();
    this.aboutText = React.createRef();
    this.contactText = React.createRef();
  }

  componentDidMount() {
    this.setTo(this.state.black);
  }

  setTo(black) {
    this.verticalSlider.current.style.top = black ? "0vh" : "100vh";
    this.aboutText.current.style.color = black ? "black" : "white";
    this.projectsText.current.style.color = black ? "black" : "white";
    this.contactText.current.style.color = black ? "black" : "white";
    this.setState({
      black: black
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.black !== this.props.black) {
      this.setState({
        black: this.props.black
      });

      this.animateTransition(this.props.black);
    }
  }

  animateTransition(isIn) {
    let duration = 1.006;
    this.setState({
      black: isIn ? true : false
    });
    TweenMax.fromTo(
      this.verticalSlider.current,
      duration,
      {
        top: isIn ? "100vh" : "0vh"
      },
      {
        top: isIn ? "0vh" : "100vh",
        delay: 0,
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    [this.projectsText, this.aboutText, this.contactText].forEach(e => {
      TweenMax.fromTo(
        e.current,
        0.3,
        {
          color: isIn ? "white" : "black"
        },
        {
          color: isIn ? "black" : "white",
          delay: isIn ? 0.6 : 0.15
        }
      );
    });
  }

  render() {
    return (
      <NavigationBarStack
        style={{
          position: "absolute",
          top: 0,
          left: `${(2 * this.props.columnDividerContainerWidth) /
            (this.props.nColumnDividors - 1)}vw`,
          width: `${(3 * this.props.columnDividerContainerWidth) /
            (this.props.nColumnDividors - 1)}vw`
        }}
        {...this.props}
      >
        <div
          ref={this.verticalSlider}
          style={{
            position: "absolute",
            top: this.state.black ? "0vh" : "100vh",
            width: `${3 * this.props.logoWidth}vw`,
            height: "100%",
            background: "white"
          }}
        />
        <NavigationLinkContainer {...this.props}>
          <NavigationLink to="/work">
            <NavigationText
              selected={this.props.location.pathname === "/work"}
              location={this.props.location.pathname}
              fontSize={responsiveFontSize(24)}
              black={this.state.black}
              ref={this.projectsText}
            >
              Work
            </NavigationText>
          </NavigationLink>
        </NavigationLinkContainer>

        <NavigationLinkContainer {...this.props}>
          <NavigationLink to="/about" fontSize={responsiveFontSize(24)}>
            <NavigationText
              selected={this.props.location.pathname === "/about"}
              location={this.props.location.pathname}
              fontSize={responsiveFontSize(24)}
              black={this.state.black}
              ref={this.aboutText}
            >
              About
            </NavigationText>
          </NavigationLink>
        </NavigationLinkContainer>

        <NavigationLinkContainer {...this.props}>
          <NavigationLink to="/contact" fontSize={responsiveFontSize(24)}>
            <NavigationText
              selected={this.props.location.pathname === "/contact"}
              location={this.props.location.pathname}
              fontSize={responsiveFontSize(24)}
              black={this.state.black}
              ref={this.contactText}
            >
              Contact
            </NavigationText>
          </NavigationLink>
        </NavigationLinkContainer>
      </NavigationBarStack>
    );
  }
}

const MobileMenuLinkText = styled(Link)`
  position: relative;
  display: block;
  text-decoration: none;
  color: white;
  font-family: Aktiv Grotesk W01 Light1370681;
`;
class MobileMenu extends Component {
  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={500}
        appear={false}
        unmountOnExit={true}
        mountOnEnter={true}
        addEndListener={(node, done) => {
          if (this.props.in) {
            TweenMax.fromTo(
              node,
              0.4,
              {
                right: "-100vw"
              },
              {
                right: "0vw",
                delay: 0,
                ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
              }
            );
          } else {
            TweenMax.fromTo(
              node,
              0.4,
              {
                right: "0vw"
              },
              {
                right: "-100vw",
                delay: 0.0,
                ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
              }
            );
          }
        }}
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            right: "100vw",
            width: `${this.props.mobileMenuWidth}vw`,
            height: `100vh`,
            background: "black",
            opacity: 0.99,
            WebkitBackdropFilter: "blur(7px)"
          }}
        >
          <div
            style={{
              position: "relative",
              top: `${2.5 * this.props.logoWidth * 0.44}vw`,
              left: `${this.props.leftMargin}vw`
            }}
          >
            {["Work", "About", "Contact"].map((e, i) => (
              <MobileMenuLinkText
                key={e}
                onClick={() => {
                  if (this.props.onLinkClick) this.props.onLinkClick();
                }}
                to={"/" + e.toLowerCase()}
                style={{
                  fontSize: `${responsiveFontSize(
                    46,
                    this.props.logoWidth,
                    this.props.headerMode === LayoutMode.MOBILE
                  )}vw`,
                  letterSpacing: `${responsiveFontSize(
                    0.57,
                    this.props.logoWidth,
                    this.props.headerMode === LayoutMode.MOBILE
                  )}`,
                  marginBottom: `${responsiveFontSize(
                    46,
                    this.props.logoWidth,
                    this.props.headerMode === LayoutMode.MOBILE
                  )}vw`,
                  left: 0,
                  textAlign: "left"
                }}
              >
                {e + "."}
              </MobileMenuLinkText>
            ))}
          </div>
        </div>
      </Transition>
    );
  }
}

class MenuIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topLineAppear: true
    };
    this.bottomLine = React.createRef();
    this.bottomLineContainer = React.createRef();
    this.topLine = React.createRef();
  }

  componentDidMount() {
    this.setTo(this.props.black);
  }

  animateTransition(isIn) {
    [this.topLine, this.bottomLine].forEach(e => {
      TweenMax.fromTo(
        e.current,
        0.3,
        {
          background: isIn ? "white" : "black"
        },
        {
          background: isIn ? "black" : "white",
          delay: isIn ? 0.6 : 0.15
        }
      );
    });
  }

  setTo(black) {
    this.bottomLine.current.style.background = black ? "black" : "white";

    if (this.topLine.current) {
      this.topLine.current.style.background = black ? "black" : "white";
    }
  }

  animateClose(black) {
    this.setState({
      topLineAppear: true
    });

    TweenMax.fromTo(
      this.bottomLineContainer.current,
      0.3,
      {
        top: "50%"
      },
      {
        top: "100%",
        delay: 0.15,
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    if (black) {
      [this.topLine, this.bottomLine].forEach(e => {
        if (e.current) {
          TweenMax.to(e.current, 0.15, {
            background: "black",
            delay: 0.05
          });
        }
      });
    }
  }

  animateOpen() {
    this.setState({
      topLineAppear: false
    });
    TweenMax.fromTo(
      this.bottomLineContainer.current,
      0.3,
      {
        top: "100%"
      },
      {
        top: "50%",
        delay: 0.0,
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    [this.topLine, this.bottomLine].forEach(e => {
      if (e.current) {
        TweenMax.to(e.current, 0.15, {
          background: "white",
          delay: 0.05
        });
      }
    });
  }

  render() {
    return (
      <button
        style={{
          width: `${this.props.leftMargin * 2 +
            (40 / 180) * this.props.logoWidth}vw`,
          height: `${this.props.leftMargin * 2 +
            (40 / 180) * this.props.logoWidth}vw`,
          ...this.props.wrapStyle
        }}
        onClick={() => {
          if (this.props.onClick) this.props.onClick();
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: `${(40 / 180) * this.props.logoWidth}vw`,
            height: `${(40 / 180) * this.props.logoWidth * 0.325}vw`
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%"
            }}
          >
            <AnimatedLine
              in={this.state.topLineAppear}
              isRoute={false}
              isTransition={true}
              style={LineAnimationStyle.center}
              wrapStyle={{
                position: "absolute",
                top: "0%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: `${(2 / 180) * this.props.logoWidth}vw`,
                width: "100%"
              }}
            >
              <div
                ref={this.topLine}
                style={{
                  background: this.props.black ? "black" : "white",
                  height: "100%",
                  width: "100%"
                }}
              />
            </AnimatedLine>
            <AnimatedLine
              lineRef={this.bottomLineContainer}
              isRoute={false}
              in={true}
              wrapStyle={{
                position: "absolute",
                left: "50%",
                top: "100%",
                transform: "translate(-50%, -50%)",
                height: `${(2 / 180) * this.props.logoWidth}vw`,
                width: "100%"
              }}
            >
              <div
                ref={this.bottomLine}
                style={{
                  background: this.props.black ? "black" : "white",
                  height: "100%",
                  width: "100%"
                }}
              />
            </AnimatedLine>
          </div>
        </div>
      </button>
    );
  }
}

const HeaderStack = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 50%;
  opacity: ${props => props.fullscreen ? "0" : "1"};
  ${props => props.fullscreen ? "pointer-events: none" : ""};
  transform: translate(-50%, 0%);
  transition: opacity 0.3s;
  height: ${props => props.logoWidth * 0.44}vw;
  width: ${props =>
    props.headerMode === LayoutMode.DESKTOP
      ? props.columnDividerContainerWidth
      : 100}vw;
`;

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      black: props.location.pathname === "/contact" ? true : false,
      paged: false
    };

    this.logo = React.createRef();
    this.navbar = React.createRef();
    this.menuIcon = React.createRef();
  }

  componentDidMount() {
    if (this.state.black && !this.state.paged) {
      // Set comoments
      this.setToState(true);
    } else {
      this.setToState(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.headerMode !== this.props.headerMode) {
      if (this.state.paged) {
        this.setState({
          paged: false
        });
      }
      if (this.props.headerMode === LayoutMode.DESKTOP) {
        this.setState({
          menu: false
        });
      }
    }
    if (
      this.props.mobileMenuWidth !== 100 &&
      prevProps.mobileMenuWidth !== this.props.mobileMenuWidth
    ) {
      this.setToState(this.state.black);
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // If new rout is home it didnt come from menu
      let newState = this.props.location.pathname === "/contact" ? true : false;
      this.setState({
        black: newState
      });

      if (this.props.headerMode === LayoutMode.MOBILE && this.state.menu) {
        this.handleMenuIconClick(newState);
      } else {
        this.setToState(newState);
      }
    }
  }

  setToState(black) {
    this.logo.current.setTo(black);
    if (this.navbar.current) {
      this.navbar.current.setTo(black);
    }
    if (this.menuIcon.current) {
      this.menuIcon.current.setTo(black);
    }

    this.setState({ black: black });
  }

  // True is black white is false
  transitionToState(black) {
    // if (this.state.black === black) {
    //   console.log("header state already there");
    //   return;
    // }

    if (black) {
      this.logo.current.animateTranstion(
        LogoTransition.WHITE_TO_BLACK,
        LogoTransitionDirection.BOTTOM,
        () => {
          this.setState({ black: black });
        }
      );
    } else {
      this.logo.current.animateTranstion(
        LogoTransition.BLACK_TO_WHITE,
        LogoTransitionDirection.TOP,
        () => {
          this.setState({ black: black });
        }
      );
    }

    if (this.props.headerMode === LayoutMode.DESKTOP) {
      this.navbar.current.animateTransition(black);
    } else {
      this.menuIcon.current.animateTransition(black);
    }
  }

  scrollUp() {
    if (!this.state.paged) {
      this.transitionToState(true);
      this.setState({
        paged: true
      });
    }
  }

  scrollDown() {
    if (this.state.paged) {
      this.transitionToState(false);
      this.setState({
        paged: false
      });
    }
  }

  handleMenuIconClick(newState) {
    if (!this.state.menu) {
      this.openMobileMenu();
    } else {
      if (newState === undefined || newState === null) {
        this.closeMobileMenu(this.state.black);
      } else {
        this.closeMobileMenu(newState);
      }
    }
  }

  closeMobileMenu(newState) {
    if (this.props.mobileMenuWidth === 100) {
      let type = newState
        ? LogoTransition.WHITE_TO_BLACK
        : LogoTransition.BLACK_TO_WHITE;

      this.logo.current.animateTranstion(type, LogoTransitionDirection.LEFT);
    }

    this.menuIcon.current.animateClose(newState);

    if (this.state.menu) {
      this.setState({
        menu: false
      });
    }
  }

  openMobileMenu() {
    // ToggleLogo
    if (this.props.mobileMenuWidth === 100) {
      let type = this.state.black
        ? LogoTransition.BLACK_TO_WHITE
        : LogoTransition.WHITE_TO_BLACK;
      this.logo.current.animateTranstion(type, LogoTransitionDirection.RIGHT);
    }

    this.menuIcon.current.animateOpen();

    if (!this.state.menu) {
      this.setState({
        menu: true
      });
    }
  }

  render() {
    return (
      <HeaderStack {...this.props} >
        {this.props.nColumnDividors === 6 && (
          <NavigationBar ref={this.navbar} {...this.props} />
        )}

        {this.props.nColumnDividors < 6 && (
          <Fragment>
            <MobileMenu in={this.state.menu} {...this.props} />
            <MenuIcon
              ref={this.menuIcon}
              {...this.props}
              wrapStyle={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 0 //`${this.props.leftMargin}vw`
              }}
              onClick={() => this.handleMenuIconClick()}
              black={this.state.black}
            />
          </Fragment>
        )}

        <Logo ref={this.logo} {...this.props} />
      </HeaderStack>
    );
  }
}
