import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Route, withRouter } from "react-router-dom";
import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import { MediaLoader } from "../MediaLoader";
import {
  isMobileOnly,
  isMobile,
  isChrome,
  isFirefox,
} from "react-device-detect";

import {
  FOVShaderImage,
  FOVTransitionType,
} from "components/FOVShaderImageSlideshow";
import { mod } from "../utils";

let duration = 1.006;
let delay = 0.0;
const HomepageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #f9f9f9;
`;

const ProjectsBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #f9f9f9;
`;

var upperCaseFirstLetter = function (str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

var propertyPrefixes = ["", "moz", "ms", "o", "webkit"];
var getPrefixedProperty = function (obj, propertyName) {
  for (var ii = 0; ii < propertyPrefixes.length; ++ii) {
    var prefix = propertyPrefixes[ii];
    var name = prefix + propertyName;
    console.log(name);
    var property = obj[name];
    if (property) {
      return property;
    }
    if (ii == 0) {
      propertyName = upperCaseFirstLetter(propertyName);
    }
  }
  return undefined;
};

var _requestAnimFrame;
var requestAnimFrame = function (callback) {
  if (!_requestAnimFrame) {
    _requestAnimFrame =
      getPrefixedProperty(window, "requestAnimationFrame") ||
      function (callback, element) {
        return window.setTimeout(callback, 1000 / 70);
      };
  }
  _requestAnimFrame.call(window, callback);
};

class MappedFOVShaderSlideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
    };

    this.slideshow = null;
    this.container = React.createRef();

    this.lazyload = this.lazyload.bind(this);
    this.updateAsset = this.updateAsset.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log("MappedFOVShaderSlideshow did update: ");
    if (this.props.assets.homepage && !prevProps.assets.homepage) {
      this.sortedAssetKeys = Object.keys(this.props.assets);
      this.sortedAssetKeys.sort();
      this.assetKeyOffset = {};

      this.sortedAssets = [];
      this.sortedAssetsNameAssetIndexMap = {};
      this.sortedAssetsFull = [];

      this.sortedAssetKeys.forEach((pagekey, i) => {
        if (i === 0) {
          this.assetKeyOffset[pagekey] = 0;
        } else {
          let lastkey = this.sortedAssetKeys[i - 1];
          this.assetKeyOffset[pagekey] =
            this.assetKeyOffset[lastkey] + this.props.assets[lastkey].length;
        }

        if (this.props.assets[pagekey]) {
          this.props.assets[pagekey].forEach((e, i) => {
            this.sortedAssets.push(e.img);
            this.sortedAssetsFull.push(e);
            this.sortedAssetsNameAssetIndexMap[e.name] = i;
          });
        }
      });

      if (this.sortedAssets) {
        this.setState({
          assets: this.sortedAssets,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  getAssetIndex(key, index) {
    return this.assetKeyOffset[key] + index;
  }

  transitionForKey(key, index) {
    if (!this.state.assets) {
      return;
    }

    this.slideshow.setImageIndex(this.getAssetIndex(key, index), true, () => {
      let assetInQuestion = this.sortedAssetsFull[
        this.getAssetIndex(key, index)
      ];
      let isVideoUrl = typeof assetInQuestion.video === "string";

      if (!!assetInQuestion.video && isVideoUrl) {
        console.log("lazy loading video url: " + assetInQuestion.video);

        this.lazyload(key, index, assetInQuestion.video);
      }
    });
  }

  lazyload(key, index, videoURL) {
    var timeWatcher = function () {
      if (video.currentTime > 0) {
        video.pause();
        video.currentTime = 0.1;
        this.updateAsset(key, index, video);
      } else {
        requestAnimFrame.call(window, timeWatcher.bind(this));
      }
    };

    requestAnimFrame.call(window, timeWatcher.bind(this));

    let video = document.createElement("video");
    video.crossOrigin = this.crossOrigin || "anonymous";
    // video.preload = "none"; //true;
    video.preload = "auto"; //true;

    video.muted = true;
    video.loop = true;
    video.setAttribute("playsinline", "");

    video.src = videoURL;

    if (video.readyState > 3) {
      this.updateAsset(key, index, video);
    } else {
      video.load();
    }

    video.oncanplaythrough = () => {
      // console.log("lazy load function: on canplay through");

      video.oncanplaythrough = null;
      video.play();
    };
  }

  updateAsset(key, index, video) {
    let updateKey = this.getAssetIndex(key, index);
    this.sortedAssets[updateKey] = video;
    this.sortedAssetsFull[updateKey].video = video;
    this.slideshow.updateAssets(updateKey, video);
  }

  changeToKey(key, index) {
    this.slideshow.setImageIndex(this.getAssetIndex(key, index), false); // no animation
    let assetInQuestion = this.sortedAssetsFull[this.getAssetIndex(key, index)];
    if (!!assetInQuestion.video && typeof assetInQuestion.video === "string") {
      console.log("lazy loading video url: " + assetInQuestion.video);

      this.lazyload(key, index, assetInQuestion.video);
    }
  }

  // No animation
  fullscreen() {
    TweenMax.set(this.container.current, { height: "100vh" });
    this.slideshow.glPlane1.planeResize();
    this.slideshow.glPlane2.planeResize();
  }

  animateGrow() {
    this.slideshow.curtain.onRender(() => {
      // this.slideshow.curtain.resize();
    });
    TweenMax.fromTo(
      this.container.current,
      duration,
      {
        transform: "translate(0, -100%)",
      },
      {
        transform: "translate(0, 0)",
        delay: delay,
        onComplete: () => {
          this.slideshow.curtain.onRender(() => {});
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
  }

  animateShrink() {
    this.slideshow.curtain.onRender(() => {
      // this.slideshow.curtain.resize();
    });
    TweenMax.fromTo(
      this.container.current,
      duration,
      {
        transform: "translate(0, 0)",
      },
      {
        transform: "translate(0, -100%)",
        delay: delay,
        onComplete: () => {
          this.slideshow.curtain.onRender(() => {});
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
  }

  render() {
    return (
      <div
        ref={this.container}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100vh",
          width: "100vw",
          background: "black",
        }}
      >
        {this.state.assets[0] && this.state.assets.length > 0 && (
          <FOVShaderImage
            onRef={(e) => {
              this.slideshow = e;
            }}
            onAnimating={() => {
              if (this.props.onAnimating) {
                this.props.onAnimating();
              }
            }}
            onEndAnimation={() => {
              if (this.props.onEndAnimation) {
                this.props.onEndAnimation();
              }
            }}
            type={this.props.type}
            style={{
              width: "100%",
              height: "100%",
            }}
            assets={this.state.assets}
            updateCount={this.state.updateCount}
            startAssetIdx={this.getAssetIndex(this.props.startRoute, 0)}
          />
        )}
        {this.state.assets.length === 0 && (
          <img
            src={this.props.placeholder}
            style={{ height: "100%", width: "100%" }}
          />
        )}
      </div>
    );
  }
}

class GLBackground extends Component {
  constructor(props) {
    super(props);

    this.routeBackground = React.createRef();
    this.currentHomepageIdx = 0;
    this.currentWorkIdx = 0;

    this.state = {
      homepageAssets: null,
      projectsAssets: null,
      aboutAssets: null,
      contactAssets: null,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    // Load the assets
    this.placeholderNameMap = {};
    this.props.assets.forEach((e) => {
      this.placeholderNameMap[e.name] = {
        video: e.video || null,
      };
    });
    let placeholderAssets = this.props.assets.map((e) => {
      return {
        src: e.placeholder,
        type: "img",
        name: e.name,
      };
    });

    this.placeholderAssetLoader = new MediaLoader(placeholderAssets);
    this.placeholderAssetLoader.onAllSourcesLoaded(
      this.handlePlaceholderAssetsLoaded.bind(this)
    );
    this.placeholderAssetLoader.loadAll();
  }

  handlePlaceholderAssetsLoaded() {
    Object.keys(this.placeholderAssetLoader.loadedAssets).forEach((name) => {
      let asset = this.placeholderAssetLoader.loadedAssets[name];
      if (name.includes("homepage")) {
        let homepageIndex = name[name.length - 1];
        if (!this.homepageAssets) {
          this.homepageAssets = {};
        }
        this.homepageAssets[parseInt(homepageIndex, 10)] = {
          name: name,
          img: asset,
          video: this.placeholderNameMap[name].video,
        };
      } else if (name.includes("work")) {
        let workIndex = name[name.length - 1];
        if (!this.projectsAssets) {
          this.projectsAssets = {};
        }
        this.projectsAssets[parseInt(workIndex, 10)] = {
          name: name,
          img: asset,
          video: this.placeholderNameMap[name].video,
        };
      } else if (name.includes("about")) {
        if (!this.aboutAssets) {
          this.aboutAssets = [
            {
              name: name,
              img: asset,
              video: null,
            },
          ];
        }
      } else if (name.includes("contact")) {
        if (!this.contactAssets) {
          this.contactAssets = [
            {
              name: name,
              img: asset,
              video: null,
            },
          ];
        }
      }
    });

    this.homepageAssets.length = Object.keys(this.homepageAssets).length;
    this.projectsAssets.length = Object.keys(this.projectsAssets).length;
    this.homepageAssets = Array.from(this.homepageAssets);
    this.projectsAssets = Array.from(this.projectsAssets);

    this.setState(
      {
        homepageAssets: this.homepageAssets,
        projectsAssets: this.projectsAssets,
        aboutAssets: this.aboutAssets,
        contactAssets: this.contactAssets,
      },
      () => {
        if (this.props.onAllPlaceholderSourcesLoaded) {
          this.props.onAllPlaceholderSourcesLoaded();

          this.startCurrentVideoLoading();
        }
      }
    );
  }

  startCurrentVideoLoading() {
    if (this.props.location.pathname === "/work") {
      this.slideshow.lazyload("work", 0, this.state.projectsAssets[0].video);
    } else if (this.props.location.pathname === "/") {
      console.log("Lazy loading homepage 0");
      this.slideshow.lazyload(
        "homepage",
        0,
        this.state.homepageAssets[0].video
      );
    }
  }

  componentDidUpdate(prevProps) {
    // Change GL Slider
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname !== "/stand-with-ukraine") {
      if (this.routeBackground.current.style.top === "0vh") {
        TweenMax.set(this.routeBackground.current, { top: "100vh" });
        this.slideshow.fullscreen();
        this.slideshow.changeToKey(
          this.convertPathnameToKeyname(this.props.location.pathname),
          0
        );
        this.slideshow.slideshow.curtain.resize();
      } else {
        this.slideshow.transitionForKey(
          this.convertPathnameToKeyname(this.props.location.pathname),
          0
        );
      }

      if (this.props.location.pathname === "/") {
        this.currentHomepageIdx = 0;
      } else {
        this.currentWorkIdx = 0;
      }
    }
  }

  next() {
    if (this.props.location.pathname === "/") {
      this.currentHomepageIdx += 1;
      let homepageVideoIdx = mod(
        this.currentHomepageIdx,
        this.state.homepageAssets.length
      );
      this.slideshow.transitionForKey("homepage", homepageVideoIdx);
    }

    if (this.props.location.pathname === "/work") {
      this.currentWorkIdx += 1;
      let workVideoIdx = mod(
        this.currentWorkIdx,
        this.state.projectsAssets.length
      );
      this.slideshow.transitionForKey("work", workVideoIdx);
    }
  }

  previous() {
    if (this.props.location.pathname === "/") {
      this.currentHomepageIdx -= 1;
      let homepageVideoIdx = mod(
        this.currentHomepageIdx,
        this.state.homepageAssets.length
      );
      this.slideshow.transitionForKey("homepage", homepageVideoIdx);
    }
    if (this.props.location.pathname === "/work") {
      this.currentWorkIdx -= 1;
      let workVideoIdx = mod(
        this.currentWorkIdx,
        this.state.projectsAssets.length
      );
      this.slideshow.transitionForKey("work", workVideoIdx);
    }
  }

  convertPathnameToKeyname(pathname) {
    let strippedPathnanme = pathname.split("/")[1];
    switch (strippedPathnanme) {
      case "":
        return "homepage";
      case "work":
        return "work";
      case "about":
        return "about";
      case "contact":
        return "contact";
      default:
        return "hompage";
    }
  }

  scrollUp() {
    if (["/about", "/contact"].includes(this.props.location.pathname)) {
      return;
    }

    if (this.routeBackground.current.style.top === "0vh") {
      return;
    }

    if (this.props.onAnimating) {
      this.props.onAnimating();
    }
    TweenMax.fromTo(
      this.routeBackground.current,
      duration,
      { top: "100vh" },
      {
        top: "0vh",
        delay: delay,
        onComplete: () => {
          if (this.props.onEndAnimation) this.props.onEndAnimation();
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
    this.slideshow.animateShrink();
  }

  scrollDown() {
    if (["/about", "/contact"].includes(this.props.location.pathname)) {
      return;
    }

    if (this.routeBackground.current.style.top === "100vh") {
      return;
    }

    if (this.props.onAnimating) {
      this.props.onAnimating();
    }
    TweenMax.fromTo(
      this.routeBackground.current,
      duration,
      { top: "0vh" },
      {
        top: "100vh",
        delay: delay,
        onComplete: () => {
          if (this.props.onEndAnimation) this.props.onEndAnimation();
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
    this.slideshow.animateGrow();
  }

  render() {
    return (
      <Fragment>
        <MappedFOVShaderSlideshow
          onRef={(e) => {
            this.slideshow = e;
          }}
          onAnimating={() => {
            if (this.props.onAnimating) {
              this.props.onAnimating();
            }
          }}
          onEndAnimation={() => {
            if (this.props.onEndAnimation) {
              this.props.onEndAnimation();
            }
          }}
          assets={{
            homepage: this.state.homepageAssets,
            work: this.state.projectsAssets,
            about: this.state.aboutAssets,
            contact: this.state.contactAssets,
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "black",
          }}
          startRoute={this.convertPathnameToKeyname(
            this.props.location.pathname
          )}
          type={FOVTransitionType.HOMEPAGE}
        />

        <div
          style={{
            position: "absolute",
            top: "100vh",
            height: "100vh",
            width: "100vw",
          }}
          ref={this.routeBackground}
        >
          <Route exact path="/" render={() => <HomepageBackground />} />
          <Route path="/work" render={() => <ProjectsBackground />} />
        </div>
      </Fragment>
    );
  }
}

export const RoutedGLBackground = withRouter(GLBackground);
