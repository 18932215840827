import React, { Component } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import { FullPageArea } from "components/ColumnGrid";
import { getDribbbleShots } from "../DribbbleApi";
import SectionTitle from "components/SectionTitle";
import { Alignment } from "../Layouts";
import Observer from "@researchgate/react-intersection-observer";
import { opacityRouteAnimation } from "animations/GlobalAnimations";
import { ColumnGrid } from "../components/ColumnGrid";
import { LoaderLine } from "./Loader";
import { DribbleCache, getDribbbleShot } from "../DribbbleApi";
import Footer from "../components/Footer";
import { SlidingText } from "../components/SlidingText";
import { responsiveFontSize } from "../Layouts";

export let dribbbleIDs = [];
export const setDribbbleData = data => {
  dribbbleIDs = data;
};
//[
//   5987338,
//   5848124,
//   3799399,
//   4079921,
//   5766594,
//   6180874,
//   5931418,
//   5987739,
//   5891802,
//   6001911,
//   5982977,
//   6148178,
//   5533562,
//   5982995,
//   5983002,
//   6485134,
//   6406097,
//   6495847,
//   6001897,
//   7190190,
//   5452179,
//   4225064,
//   4937969,
//   2261336,
//   5217648,
//   6403406,
//   7062008,
// ];

const gridColumnGap = columnWidth => {
  return `${columnWidth * (0.44 * 0.2824)}vw`;
};
const gridRowGap = columnWidth => {
  return `${columnWidth * (0.44 * 0.267)}vw`;
};

const SampleWorkGridContainer = styled.div`
  position: relative;
  display: grid;
  ${props =>
    props.dribbbleShotNumberCol === 1 ? "grid-template-columns: auto;" : ""}
  ${props =>
    props.dribbbleShotNumberCol === 1
      ? `grid-gap: ${
          props.blogLeftAlignment === Alignment.LEFT
            ? "0"
            : gridRowGap(props.columnWidth)
        }`
      : `grid-gap: ${gridRowGap(props.columnWidth)} ${gridColumnGap(
          props.columnWidth
        )};`}
  ${props =>
    props.dribbbleShotNumberCol === 2
      ? "grid-template-columns: auto auto;"
      : ""}
  ${props =>
    props.dribbbleShotNumberCol === 3
      ? "grid-template-columns: auto auto auto;"
      : ""}
  width: ${props =>
    props.blogLeftAlignment === Alignment.LEFT
      ? "100"
      : props.columnDividerContainerWidth}vw;
  left: ${props =>
    props.blogLeftAlignment === Alignment.LEFT ? "0" : props.leftMargin}vw;
  margin: 0;
  // padding-bottom: 150px;
  // margin-bottom: 150px;
`;

const GridItemContainer = styled.div`
  background: ${props => (props.loader ? "black" : "transparent")};
  height: calc(
    (
        ${props =>
            props.blogLeftAlignment === Alignment.LEFT
              ? "100"
              : props.columnDividerContainerWidth}vw -
          ${props => props.dribbbleShotNumberCol - 1} *
          ${props => 0.44 * 0.2824 * props.columnWidth}vw
      ) / ${props => props.dribbbleShotNumberCol} * 0.788
  );

  overflow: visible;
  position: relative;

  ${props =>
    props.blogLeftAlignment !== Alignment.LEFT && !props.loader
      ? `&:hover {
    box-shadow: 25px 25px 95px -26px rgba(0, 0, 0, 0.10);
    transform: scale(1.08);
  }`
      : ``}
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

const ImgBackground = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

class GridItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      video: null,
      image: null,
      gif: null,
      loader: true
    };
    this.handleAppearIntersectionObserver = this.handleAppearIntersectionObserver.bind(
      this
    );
    this.handleHoverstateIntersectionObserver = this.handleHoverstateIntersectionObserver.bind(
      this
    );
    this.container = React.createRef();
    this.img = React.createRef();
    this.loaderline = React.createRef();

    this.didAppearBefore = false;

    this.previousY = 0;
    this.previousRatio = 0;
    this.isAnimating = false;

    // this.setSrcIfPreloadedElseFetch(props.dribbbleId);
  }

  setSrcIfPreloadedElseFetch(id) {
    // console.log("Dribble cachce: " + JSON.stringify(DribbleCache));
    if (id.toString() in DribbleCache) {
      // console.log("GETTINGFROM CACHE!");
      let data = DribbleCache[id];
      this.setDribbleData(data);
    } else {
      getDribbbleShot(id.toString()).then(response => {
        // console.log("response: " + response);
        DribbleCache[id] = response;
        this.setDribbleData(response);
      });
    }
  }

  setDribbleData(data) {
    let stateData = {};
    if (!!data.video && !!data.video.xlarge_preview_url) {
      stateData["video"] = data.video.xlarge_preview_url;
    }
    if (!!data.images && data.images.normal) {
      stateData["image"] = data.images.normal;
    }

    if (!!data.images && data.images.hidpi) {
      stateData["gif"] = data.images.hidpi;
    }

    this.setState({
      ...stateData
      // loader: false
    });
  }

  handleHoverstateIntersectionObserver(event) {
    if (event.isIntersecting) {
      if (this.state.hovered) {
        return;
      }
      this.setState({
        hovered: true
      });
    } else {
      if (!this.state.hovered) {
        return;
      }
      this.setState({
        hovered: false
      });
    }
  }

  handleAppearIntersectionObserver(event, unobserve) {
    const currentY = event.boundingClientRect.y;
    const currentRatio = event.intersectionRatio;
    const isIntersecting = event.isIntersecting;

    // Scrolling down/up
    if (currentY < this.previousY) {
      if (currentRatio > this.previousRatio && isIntersecting) {
        // console.log("Scrolling down enter: " + currentRatio);

        this.setSrcIfPreloadedElseFetch(this.props.dribbbleId);
        if (this.props.blogLeftAlignment !== Alignment.LEFT) {
          this.appearIntersectionAnimation(event.isIntersecting, unobserve);
        }
      } else {
        // console.log("Scrolling down leave");
      }
    } else if (currentY > this.previousY && isIntersecting) {
      if (currentRatio < this.previousRatio) {
        // console.log("Scrolling up leave");
      } else {
        // console.log("Scrolling up enter");
      }
    }

    this.previousY = currentY;
    this.previousRatio = currentRatio;
  }

  appearIntersectionAnimation(appear, unobserve) {
    let translateY;

    // Left
    if (this.props.idx % this.props.dribbbleShotNumberCol === 0) {
      translateY = `calc(((${
        this.props.blogLeftAlignment === Alignment.LEFT
          ? `100vw`
          : `${this.props.columnDividerContainerWidth}vw`
      } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
        this.props.columnWidth
      )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * ${
        this.props.dribbbleShotNumberCol === 1 ? 0.1 : 0.825
      })`;
    }
    // Middle
    else if (this.props.idx % this.props.dribbbleShotNumberCol === 1) {
      translateY = `calc(((${
        this.props.blogLeftAlignment === Alignment.LEFT
          ? `100vw`
          : `${this.props.columnDividerContainerWidth}vw`
      } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
        this.props.columnWidth
      )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * 0.55)`;
    }
    // Right
    else if (this.props.idx % this.props.dribbbleShotNumberCol === 2) {
      translateY = `calc(((${
        this.props.blogLeftAlignment === Alignment.LEFT
          ? `100vw`
          : `${this.props.columnDividerContainerWidth}vw`
      } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
        this.props.columnWidth
      )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * 0.375)`;
    }

    // Super hacky but works
    if (
      appear &&
      !this.isAnimating &&
      (!this.container.current.dataset.isAnimating ||
        this.container.current.dataset.isAnimating === "false")
    ) {
      // unobserve();
      this.isAnimating = true;
      TweenMax.fromTo(
        this.container.current,
        this.props.blogLeftAlignment === Alignment.LEFT ? 0.37 : 0.7,
        {
          transform: `translate(0, ${translateY})`,
          opacity: 0
        },
        {
          transform: "translate(0, 0)",
          opacity: 1,
          delay: 0.0,
          onStart: () => {
            this.container.current.style.transition = null;
          },
          onComplete: () => {
            TweenMax.set(this.container.current, { clearProps: "all" });
            this.container.current.style.transition =
              "transform 0.52s cubic-bezier(0.66, 0.00, 0.43, 1.00), box-shadow 0.52s cubic-bezier(0.56, 0.00, 0.43, 1.00), background 0.52s linear 1.2s";
            this.isAnimating = false;
          },
          ease: CustomEase.create("custom2", "0.49, 0.00, 0.19, 1.00")
        }
      );
    }
    this.didAppearBefore = true;
    if (appear) {
      // unobserve();
    }
  }

  render() {
    return (
      <Observer
        rootMargin="-40% 0px -40% 0px"
        onChange={this.handleHoverstateIntersectionObserver}
        disabled={this.props.dribbbleShotNumberCol > 1}
      >
        <Observer
          rootMargin={`${
            this.props.blogLeftAlignment === Alignment.LEFT ? "50%" : "100px"
          } 0px ${
            this.props.blogLeftAlignment === Alignment.LEFT ? "50%" : "100px"
          } 0px`}
          onChange={this.handleAppearIntersectionObserver}
        >
          <GridItemContainer
            {...this.props}
            ref={this.container}
            loader={this.state.loader}
            onMouseEnter={() => {
              if (this.props.dribbbleShotNumberCol > 1) {
                this.setState({ hovered: true });
              }
            }}
            onMouseLeave={() => {
              if (this.props.dribbbleShotNumberCol > 1) {
                this.setState({ hovered: false });
              }
            }}
          >
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                overflow: "hidden"
              }}
            >
              {this.state.image && (
                <ImgBackground
                  onLoad={() => {
                    this.setState({ loader: false });
                  }}
                  crossorigin="anonymous"
                  style={{ opacity: 0 }}
                  ref={this.img}
                  src={this.state.image}
                />
              )}

              {this.state.hovered && !this.state.video && this.state.gif && (
                <ImgBackground
                  onload={() => {
                    this.setState({ loader: false });
                  }}
                  crossorigin="anonymous"
                  src={this.state.gif}
                />
              )}
              {this.state.hovered && !!this.state.video && (
                <VideoBackground
                  crossorigin="anonymous"
                  autoPlay
                  playsInline
                  muted
                  src={this.state.video}
                />
              )}
              <Transition
                in={this.state.loader}
                timeout={1000}
                appear={true}
                unmountOnExit={true}
                addEndListener={() => {
                  if (this.state.loader === false) {
                    if (this.img.current) {
                      opacityRouteAnimation(
                        this.img.current,
                        true,
                        this.props.blogLeftAlignment === Alignment.LEFT
                          ? 1.0
                          : 0.6,
                        0.5
                      );
                    }
                    opacityRouteAnimation(
                      this.loaderline.current.loaderRef.current,
                      false,
                      0.6
                    );

                    if (this.props.blogLeftAlignment !== Alignment.LEFT) {
                      TweenMax.fromTo(
                        this.container.current,
                        1.2,
                        { background: "black" },
                        {
                          background: "#f9f9f9",
                          delay: 1,
                          onComplete: () => {
                            // this.loaderline.current.animation.pause();
                          }
                        }
                      );
                    }
                  }
                  if (!!this.loaderline && this.state.loader) {
                    this.loaderline.current.play();
                  }
                }}
              >
                <LoaderLine
                  ref={this.loaderline}
                  left="50%"
                  top="50%"
                  width={`${0.4006 * this.props.columnWidth}vw`}
                  height={`${0.006734 * this.props.columnWidth}vw`}
                  wrapStyle={{
                    transform: "translate(-50%, -50%)"
                  }}
                />
              </Transition>
            </div>
          </GridItemContainer>
        </Observer>
      </Observer>
    );
  }
}

export default class WorkSamplePage extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.scrollContainer = React.createRef();
    this.state = {
      viewAllHover: false
    };
  }

  componentDidMount() {
    if (!this.props.in) {
      this.container.current.style.top = "100vh";
    }
  }

  animatePageDown() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    // pageAnimation(this.container.current, false, false, () => {
    //   this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    // });

    TweenMax.fromTo(
      this.container.current,
      0.92,
      {
        top: "100vh"
      },
      {
        top: "0vh",
        delay: 0.3,
        ease: CustomEase.create("custom1", "0.33, 0.00, 0.05, 1.00")
      }
    );
  }

  animatePageUp() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    // pageAnimation(this.container.current, true, false, () => {
    //   this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    // });
    TweenMax.fromTo(
      this.container.current,
      0.92,
      {
        top: "0vh"
      },
      {
        top: "100vh",
        delay: 0.05,
        ease: CustomEase.create("custom1", "0.33, 0.00, 0.05, 1.00"),
        onComplete: () => {
          this.scrollContainer.current.scrollTop = 0;
        }
      }
    );
  }

  leftMarginForLayout() {
    return this.props.blogLeftAlignment === Alignment.LEFT
      ? `0vh`
      : `${this.props.leftMargin}vw`;
  }

  animateGridItem(node, i, done) {
    if (!this.props.in) {
      return;
    }
    if (i > this.calculateNumberOfItemsToAnimate()) {
      return;
    }
    let translateY;
    // Left
    if (i % this.props.dribbbleShotNumberCol === 0) {
      translateY = `calc(((${
        this.props.blogLeftAlignment === Alignment.LEFT
          ? `100vw`
          : `${this.props.columnDividerContainerWidth}vw`
      } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
        this.props.columnWidth
      )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * 0.825)`;
    }
    // Middle
    else if (i % this.props.dribbbleShotNumberCol === 1) {
      translateY =
        i === 1
          ? "0"
          : `calc(((${
              this.props.blogLeftAlignment === Alignment.LEFT
                ? `100vw`
                : `${this.props.columnDividerContainerWidth}vw`
            } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
              this.props.columnWidth
            )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * 0.55)`;
    }
    // Right
    else if (i % this.props.dribbbleShotNumberCol === 2) {
      translateY = `calc(((${
        this.props.blogLeftAlignment === Alignment.LEFT
          ? `100vw`
          : `${this.props.columnDividerContainerWidth}vw`
      } - ${this.props.dribbbleShotNumberCol - 1} * ${gridColumnGap(
        this.props.columnWidth
      )}) * 0.788 / ${this.props.dribbbleShotNumberCol}) * 0.375)`;
    }
    if (this.props.in) {
      node.dataset.isAnimating = true;
      TweenMax.fromTo(
        node,
        1.12,
        {
          transform: `translate(0, ${translateY})`
        },
        {
          transform: "translate(0, 0)",
          delay: 0.3,
          onStart: () => {
            node.style.transition = null;
          },
          onComplete: () => {
            node.dataset.isAnimating = false;
            TweenMax.set(node, { clearProps: "all" });
            node.style.transition =
              "transform 0.30s cubic-bezier(0.66, 0.00, 0.43, 1.00), box-shadow 0.52s cubic-bezier(0.56, 0.00, 0.43, 1.00), background 0.52s cubic-bezier(0.56, 0.00, 0.43, 1.00)";
            done();
          },
          ease: CustomEase.create("custom2", "0.49, 0.00, 0.19, 1.00")
        }
      );
    } else {
      node.dataset.isAnimating = true;
      TweenMax.fromTo(
        node,
        1.12,
        {
          transform: "translate(0, 0)"
        },
        {
          transform: `translate(0, ${translateY})`,
          delay: 0.05,
          onComplete: () => {
            node.dataset.isAnimating = false;
            done();
          },
          ease: CustomEase.create("custom2", "0.49, 0.00, 0.19, 1.00")
        }
      );
    }
  }

  canScroll() {
    // console.log("Work page canScroll: " + this.scrollContainer.current.scrollTop);
    if (this.scrollContainer.current.scrollTop <= 0) {
      return true;
    }
    return false;
  }

  calculateNumberOfItemsToAnimate() {
    let itemHeight =
      (((this.props.blogLeftAlignment
        ? 100
        : this.props.columnDividerContainerWidth) -
        (this.props.dribbbleShotNumberCol - 1) *
          0.44 *
          0.2824 *
          this.props.columnWidth) /
        this.props.dribbbleShotNumberCol) *
      0.788;
    itemHeight = (itemHeight / 100) * window.innerWidth;
    let containerHeight = this.container.current.clientHeight;
    containerHeight -=
      ((this.props.columnWidth * 0.6791) / 100) * window.innerWidth;

    let numberOfRowsToLoad = Math.ceil(containerHeight / itemHeight);
    return numberOfRowsToLoad * this.props.dribbbleShotNumberCol;
  }

  render() {
    let animationTime = 1300;
    return (
      <Transition
        timeout={animationTime}
        in={this.props.in}
        addEndListener={() => {
          this.props.in ? this.animatePageDown() : this.animatePageUp();
        }}
      >
        <FullPageArea
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
          ref={this.container}
        >
          <ColumnGrid {...this.props} style={{ zIndex: -10 }} dark={true} />
          <div
            ref={this.scrollContainer}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              overflowScrolling: "touch",
              WebkitOverflowScrolling: "touch",
              overflowY: "scroll"
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                top: `${this.props.columnWidth * 0.6791}vw`,
                left: 0
              }}
            >
              <SectionTitle
                {...this.props}
                style={{
                  position: "relative",
                  top: 0,
                  left: `${this.props.leftMargin}vw`,
                  width: "max-content"
                }}
                firstLine="We don't just build products"
                secondLine="We create worlds"
              />

              <SampleWorkGridContainer {...this.props}>
                {this.props.dribbbleIDs.map((x, i) => (
                  <Transition
                    key={i.toString() + "gridItem"}
                    timeout={2000}
                    onEnter={(_, isAppear) => {}}
                    in={this.props.in}
                    addEndListener={(node, done) => {
                      this.animateGridItem(node, i, done);
                    }}
                  >
                    <GridItem {...this.props} idx={i} dribbbleId={x.shot_id} />
                  </Transition>
                ))}
              </SampleWorkGridContainer>
              <div
                style={{
                  position: "relative",
                  marginTop: `${this.props.columnWidth * (61 / 297)}vw`
                }}
              >
                <button
                  onMouseEnter={() => {
                    this.setState({ viewAllHover: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ viewAllHover: false });
                  }}
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: `${this.props.columnWidth * (30 / 297)}vw`,
                    width: "auto"
                  }}
                  onClick={() => {
                    window.open("https://dribbble.com/glebich", "_blank");
                  }}
                >
                  <div
                    style={{
                      pointerEvents: "none",
                      display: "grid",
                      gridTemplateColumns: `auto ${this.props.columnWidth *
                        0.0505}vw auto`,
                      gridTemplateRows: `auto`
                    }}
                  >
                    <SlidingText
                      in={true}
                      ref={this.viewmore}
                      noDelay={true}
                      style={{
                        fontFamily: "Aktiv Grotesk W01 Medium",
                        fontSize: `${responsiveFontSize(20, 14)}vw`,
                        lineHeight: `${responsiveFontSize(24, 18)}vw`,
                        color: this.state.viewAllHover ? "#000000" : "#A6A6A6",
                        transition: "color 0.5s"
                      }}
                      wrapStyle={{
                        gridColumnStart: 1,
                        gridColumnEnd: 2
                      }}
                    >
                      View All
                    </SlidingText>
                    <img
                      style={{
                        position: "relative",
                        gridColumnStart: 3,
                        gridColumnEnd: 4,
                        top: "50%",
                        transform: "translate(0, -50%)",
                        width: `${(38 / 297) * this.props.columnWidth}vw`,
                        height: `${(7.5 / 297) * this.props.columnWidth}vw`
                      }}
                      src={require("assets/longArrow.svg")}
                    />
                  </div>
                </button>
              </div>

              <Footer
                {...this.props}
                style={{
                  position: "relative",
                  marginTop: "150px",
                  left: 0
                }}
              />
            </div>
          </div>
        </FullPageArea>
      </Transition>
    );
  }
}
