import React, { Component } from "react";

import { TimelineMax, TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import { Transition } from "react-transition-group";
import { opacityRouteAnimation } from "../animations/GlobalAnimations";

const VideoStyle = width => `
  width: ${(width / 0.61) * 0.8}vw;
  height: ${((width / 0.61) * 0.8) * (720 / 1280)}vw;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%);
`;

export class LoaderLine extends Component {
  constructor(props) {
    super(props);
    this.loaderRef = React.createRef();
    this.animation = null;
  }

  play() {
    if (this.animation) {
      this.animation.play();
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //0.33, 0.00, 0.00, 1.00
    this.animation = new TimelineMax({
      repeat: -1,
      repeatDelay: 0.1
    });

    this.animation
      .set(this.loaderRef.current, {
        transform: "translate(-100%, 0)",
        opacity: 1
      })
      .to(this.loaderRef.current, 0.56, {
        transform: "translate(0, 0)",
        ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
      })
      .to(this.loaderRef.current, 0.36, {
        transform: "translate(0, 0)",
        ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
      })
      .to(this.loaderRef.current, 0.56, {
        transform: "translate(100%, 0)",
        ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00")
      });
    this.animation.pause();
  }

  render() {
    const { left, top, width, height } = this.props;

    return (
      <div
        style={{
          position: "absolute",
          left: left,
          top: top,
          height: height,
          width: width,
          overflow: "hidden",
          ...this.props.wrapStyle
        }}
      >
        <div
          ref={this.loaderRef}
          style={{
            background: "white",
            height: "100%",
            width: "100%",
            opacity: 0
          }}
        />
      </div>
    );
  }
}

export class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null
    };
    this.background = React.createRef();
    this.bar = React.createRef();
    this.logo = React.createRef();
    this.assetsLoaded = false;
  }

  hideLoader() {
    this.bar.current.hide();
    opacityRouteAnimation(this.video, false);
  }

  handleLoaderAppear(entering, done) {
    let duration = 0.36;
    if (entering) {
      if (this.background.current.childNodes.length === 1 && this.video) {
        this.background.current.insertBefore(
          this.video,
          this.background.current.childNodes[0]
        );
      }
      this.background.current.style.visibility = "visible";
      TweenMax.fromTo(
        this.background.current,
        duration,
        {
          opacity: 0.0
        },
        {
          opacity: 0.9,
          delay: 0,
          ease: CustomEase.create("custom", "0.33, 0.00, 0.67, 1.00")
        }
      );
    }
    if (entering && this.video) {
      this.video.currentTime = 0;
      this.video.playbackRate = this.props.transition ? 1.85 : 1.0;
      this.video.play().then(e => {
        if (this.props.transition) {
          this.bar.current.animation.restart();
          this.bar.current.animation.play();
          setTimeout(() => {
            if (this.props.onFinishTransition) {
              this.props.onFinishTransition();
            }
          }, 800);
        }
      });
    } else if (!entering) {
      TweenMax.fromTo(
        this.background.current,
        duration,
        {
          opacity: 0.9
        },
        {
          opacity: 0,
          delay: !this.props.transition ? 1.0 : 0,
          onComplete: () => {
            done();
          },
          ease: CustomEase.create("custom", "0.33, 0.00, 0.83, 0.83")
        }
      );
    }
  }

  barWidth() {
    return (
      (this.props.nColumnDividors ? 0.724 : 0.5531) * this.props.columnWidth
    );
  }

  startLoading(asset) {
    this.video = asset;
    this.video.style = VideoStyle(this.barWidth());
    this.video.currentTime = 0;
    this.background.current.insertBefore(
      this.video,
      this.background.current.childNodes[0]
    );
    this.video.play().then(e => {
      if (this.bar.current) {
        this.bar.current.play();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nColumnDividors !== this.props.nColumnDividors) {
      if (this.video) {
        this.video.style = VideoStyle(this.barWidth());
      }
    }
    if (prevProps.asset !== this.props.asset) {
      this.startLoading(this.props.asset);
    }
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={1300}
        addEndListener={(node, done) => {
          this.handleLoaderAppear(this.props.in, done);
        }}
      >
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "101vh",
            backdropFilter: "blur(7px)",
            WebkitBackdropFilter: "blur(7px)",
            opacity: 1,
            zIndex: 30
          }}
        >
          <div
            ref={this.background}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background: "black",
              opacity: 0.0
            }}
          >

            <div
              style={{
                position: "absolute",
                top: "35%",
                left: "50%",
                transform: "translateX(-50%)",
                width: `${(this.props.nColumnDividors ? 0.724 * 0.8 : 0.5531) * this.props.columnWidth / 0.61}vw`,
                height: `${((this.props.nColumnDividors ? 0.724 * 0.8 : 0.5531) * this.props.columnWidth / 0.61) * (720 / 1280)}vw`
              }}
            >
              <LoaderLine
                ref={this.bar}
                width={`${(this.props.nColumnDividors ? 0.70 * 0.8 : 0.5331) *
                  this.props.columnWidth}vw`}
                height={`${(2 / 297) * this.props.columnWidth}vw`}
                wrapStyle={{
                  bottom: "33%",
                  left: "51.5%",
                  transform: "translateX(-50%)"
                }}
              />
            </div>
          </div>
        </div>
      </Transition>
    );
  }
}
