export function getResponsiveDescriptionSizeInfo(props) {
  let retVals;
  if (props.nColumnDividors === 6) {
    retVals = {
      topMargin: 0.7643 * props.columnWidth,
      textWidth: (props.leftStyle ? 2.49 : 2.447) * props.columnWidth,
      textLeftMargin:
        props.leftMargin + (props.leftStyle ? 2.49 * props.columnWidth : 0)
    };
  } else if (props.nColumnDividors === 5) {
    retVals = {
      topMargin: 0.7643 * props.columnWidth,
      textWidth: 2 * props.columnWidth,
      textLeftMargin:
        props.leftMargin + (props.leftStyle ? 2.15 * props.columnWidth : 0)
    };
  } else if (props.nColumnDividors < 5) {
    let imageHeight =
      getResponsiveImageSizeInfo(props).imageWidth *
      getResponsiveImageSizeInfo(props).imageAspectRatio;
    retVals = {
      topMargin:
        0.34 * props.logoWidth + imageHeight + 0.3333 * props.columnWidth,
      textWidth: props.columnDividerContainerWidth,
      textLeftMargin: props.leftMargin
    };
  }
  return retVals;
}

export function getResponsiveImageSizeInfo(props) {
  let retVals;
  if (props.nColumnDividors === 6) {
    retVals = {
      imageLeftMargin: props.leftMargin,
      // imageTopMargin: 0.7643 * props.columnWidth,
      imageTopMargin: 0.44 * props.logoWidth,
      imageWidth: props.columnWidth * 2,
      imageAspectRatio: 1.3389
    };
  } else if (props.nColumnDividors === 5) {
    retVals = {
      imageLeftMargin: 0,
      // imageTopMargin: 0.7643 * props.columnWidth,
      imageTopMargin: 0.44 * props.logoWidth,
      imageWidth: props.columnWidth * 1.6 + props.leftMargin,
      imageAspectRatio: 1.587
    };
  } else if (props.nColumnDividors === 4) {
    retVals = {
      imageLeftMargin: 0,
      imageTopMargin: 0.44 * props.logoWidth,
      imageWidth: props.columnWidth * 2 + props.leftMargin,
      imageAspectRatio: 1.587
    };
  } else if (props.nColumnDividors === 3) {
    retVals = {
      imageLeftMargin: 0,
      imageTopMargin: 0.44 * props.logoWidth,
      imageWidth: props.columnWidth * 1.76 + props.leftMargin,
      imageAspectRatio: 1.587
    };
  }

  return retVals;
}

export function getResponsiveTopMargin(props) {
  let contentSize =
    getResponsiveImageSizeInfo(props).imageWidth *
    getResponsiveImageSizeInfo(props).imageAspectRatio;
  let minMargin = getResponsiveImageSizeInfo(props).imageTopMargin; // 0.7643 * props.columnWidth;
  if (
    props.windowHeight <
    ((minMargin + contentSize) / 100) * props.windowWidth
  ) {
    return minMargin;
  } else {
    return (
      minMargin +
      ((props.windowHeight / props.windowWidth) * 100 -
        (contentSize + minMargin)) *
        0.50
    );
  }
}

export function getResponsiveArrowSizeInfo(props) {
    let retVals;
    if (props.nColumnDividors === 6) {
      retVals = {
        blockWidth: (113 / 297) * props.columnWidth,
        arrowAspect: 11 / 113
      };
    } else if (props.nColumnDividors === 5) {
      retVals = {
        blockWidth: 0.4 * props.columnWidth,
        arrowAspect: 7 / 90
      };
    } else if (props.nColumnDividors === 4) {
      retVals = {
        blockWidth: 0.65 * props.columnWidth,
        arrowAspect: 7 / 90
      };
    } else if (props.nColumnDividors < 4) {
      retVals = {
        blockWidth: 100 - getResponsiveImageSizeInfo(props).imageWidth,
        arrowAspect: 7 / 90
      };
    }
    return retVals;
  }