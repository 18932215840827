import React, { Component, Fragment } from "react";
import { FullPageArea } from "components/ColumnGrid";
import { responsiveFontSize, Alignment } from "../../Layouts";
import styled from "styled-components";
import { SlidingText } from "components/SlidingText";
import {
  opacityRouteAnimation,
  pageAnimation
} from "animations/GlobalAnimations";
import { TweenMax } from "gsap";
import CustomEase from "../../CustomEase";
import { Transition } from "react-transition-group";
import SectionTitle from "components/SectionTitle";
import Hammer from "hammerjs";

let dynamicBlogData = null;

export const setBlogData = (data) => {
  dynamicBlogData = data;
}


let BlogData = [
  {
    href: "https://medium.com/milkinside/8-golden-rules-for-ar-design-2818637388c7?source=rss----3f6346e703a3---4",
    icon: "https://cdn-images-1.medium.com/max/200/1*e2X7CuG_Sp6OGUgg55zEkw.png",
    title: "8 golden rules for AR design",
    date: "2 October 2019"
  },
  {
    href: "https://medium.com/milkinside/a-helpful-guide-for-overcoming-design-frustration-53dc685e117a?source=rss----3f6346e703a3---4",
    icon: "https://cdn-images-1.medium.com/max/200/0*glgwfHyt5qpQZCG3",
    title: "A helpful guide for overcoming design frustration",
    date: "4 July 2019"
  },
  {
    href: "https://medium.com/milkinside/what-i-learned-from-designing-ar-apps-e9773baad181?source=rss----3f6346e703a3---4",
    icon: "https://cdn-images-1.medium.com/max/200/0*CzhzdyiVZcmO784E.png",
    title: "What I Learned From Designing AR Apps",
    date: "2 October 2019"
  },
  {
    href: "https://medium.com/milkinside/how-i-fell-in-love-with-ai-a86ef2aadee6?source=rss----3f6346e703a3---4",
    icon: "https://cdn-images-1.medium.com/max/200/1*430tA4j2Dj_v10IAYCuvXw.jpeg",
    title: "How I fell in love with AI.",
    date: "3 June 2018"
  },
];

const getBlogData = () => {
  return dynamicBlogData || BlogData;
}

const BlogItemContainer = styled.button`
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  background: #f9f9f9;
  transition: background 0.35s, box-shadow 0.35s;
  width: 100%;
  outline: none;
  padding: 0;

  margin-top: -2.5px;
  user-select: none;
  touch-action: pan-x;
  // &::after {
  //   content: "",
  //   opacity: ${props => props.hover ? 1 : 0};
  //   transition: opacity 0.2s;
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   box-shadow: 2px 11px 55px rgba(0,0,0, 0.07);
  // }
`;

const BlogItemAspectRatio = 0.424;
export class BlogItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleUnhover = this.handleUnhover.bind(this);
    this.title = React.createRef();
    this.date = React.createRef();
    this.img = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    this.hammer = new Hammer(this.container.current);
    this.hammer.on("tap", () => {
      // console.log("tapped");
      window.open(
        this.props.href,
        "_blank"
      )
    });
  }

  handleTextScale(node, big) {
    // 0.33, 0.0, 0.67, 1.0;
    TweenMax.fromTo(
      node,
      0.28,
      {
        transform: big ? "scale(1)" : "scale(1.04)"
      },
      {
        transform: big ? "scale(1.04)" : "scale(1)",
        delay: 0,
        ease: CustomEase.create("custom", "0.33, 0.00, 0.83, 1.00")
      }
    );
  }

  handleImgScale(node, big) {
    TweenMax.fromTo(
      node,
      0.28,
      {
        transform: big ? "scale(1)" : "scale(1.2)"
      },
      {
        transform: big ? "scale(1.20)" : "scale(1)",
        delay: 0,
        ease: CustomEase.create("custom", " 0.33, 0.00, 0.67, 1.00")
      }
    );
  }

  handleHover(e) {
    e.preventDefault();
    this.hoverTimout = setTimeout(() => {
      this.hoverTimout = null;
      if (this.state.hover) {
        return;
      }
      this.setState({
        hover: true
      });

      // opacityRouteAnimation(this.date.current, false);
      // this.handleTextScale(this.title.current, true);
      // this.handleImgScale(this.img.current, true);
    }, 120);
  }

  handleUnhover() {
    if (this.hoverTimout) {
      clearTimeout(this.hoverTimout);
      return;
    }
    this.setState({
      hover: false
    });

    // opacityRouteAnimation(this.date.current, true);
    // this.handleTextScale(this.title.current, false);
    // this.handleImgScale(this.img.current, false);
  }

  render() {
    return (
      <BlogItemContainer
        ref={this.container}
        last={this.props.last}
        style={{
          height: `${this.props.columnWidth * BlogItemAspectRatio}vw`,
          background: this.state.hover ? "white" : "",
          // outline: this.state.hover ? "none" ""
          // boxShadow: this.state.hover ? "22px 11px 55px rgba(0,0,0, 0.07)" : "",
        }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleUnhover}
        onTouchStart={this.handleHover}
        onTouchEnd={e => {
          if ("ontouchstart" in document.documentElement) {
            this.handleUnhover(e);
          }
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "1px",
            width: "100%",
            // background: "rgba(229, 229, 229, 0.65)",
            borderTop: "0.5px solid rgba(229, 229, 229, 0.65)",
          }}
        />
        {this.props.last && (
        <div
          style={{
            position: "absolute",
            borderBottom: "0.5px solid rgba(229, 229, 229, 0.65)",
            bottom: 0,
            left: 0,
            height: "1px",
            width: "100%",
            // background: "rgba(229, 229, 229, 0.65)",
          }}
        />
        )}
        <div
          style={{
            position: "absolute",
            boxSizing: "border-box",
            left: 0,
            top: 0,
            height: `${this.props.columnWidth * BlogItemAspectRatio}vw`,
            width: `${this.props.columnWidth * BlogItemAspectRatio * 1.111}vw`,
            overflow: `hidden`,
            background: !this.props.icon ? "black" : null,
            pointerEvents: "none"
          }}
        >
          <div
            ref={this.img}
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              backgroundImage: `url(${this.props.icon})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              transformOrigin: "50% 50%",
              transform: this.state.hover ? "translate(-50%, -50%) scale(1.2)" : "translate(-50%, -50%) scale(1)",
              transition: "transform 0.28s cubic-bezier(0.33, 0.00, 0.67, 1.00)"
            }}
          />
        </div>
        <p
          ref={this.date}
          style={{
            fontFamily: "Aktiv Grotesk W01 Medium",
            fontSize: `${responsiveFontSize(13, 7)}vw`,
            letterSpacing: "0.07em",
            position: "absolute",
            left: `${this.props.columnWidth * BlogItemAspectRatio +
              (44 / 297) * this.props.columnWidth}vw`,
            top: `${(12 / 297) * this.props.columnWidth}vw`,
            color: "#CCCCCC",
            textTransform: "uppercase",
            pointerEvents: "none",
            // transition: "opacity 0.2s",
            // opacity: this.state.hover ? 0 : 1
          }}
        >
          {this.props.date}
        </p>
        <p
          ref={this.title}
          style={{
            transformOrigin: "bottom left",
            fontFamily: "Aktiv Grotesk W01 Regular",
            fontSize: `${responsiveFontSize(27, 14)}vw`,
            position: "absolute",
            top: `${(22 / 297) * this.props.columnWidth}vw`,
            left: `${this.props.columnWidth * BlogItemAspectRatio +
              (44 / 297) * this.props.columnWidth}vw`,
            color: "black",
            whiteSpace: "nowrap",
            maxWidth: `${0.725 * this.props.columnDividerContainerWidth}%`,
            textOverflow: "ellipsis",
            overflow: "hidden",
            pointerEvents: "none",
            backfaceVisibility: "hidden",
            WebkitFontSmoothing: "subpixel-antialiased",
            transform: this.state.hover ? "translateZ(0) scale(1.04)" : "translateZ(0) scale(1.0)",
            transition: "transform 0.20s cubic-bezier(0.33, 0.00, 0.83, 1.00)"
          }}
        >
          {this.props.title}
        </p>
        <div
          style={{
            position: "absolute",
            right: `${(40 / 297) * this.props.columnWidth}vw`,
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pointerEvents: "none"
          }}
        >
          <p
            style={{
              fontFamily: "Aktiv Grotesk W01 Medium",
              fontSize: `${responsiveFontSize(16, 10)}vw`,
              color: "#D6D6D6",
              transition: "opacity 0.2s",
              opacity: this.state.hover ? 1 : 0
            }}
          >
            view more
          </p>
          <img
            style={{
              width: `${(38 / 297) * this.props.columnWidth}vw`,
              height: `${(7.5 / 297) * this.props.columnWidth}vw`,
              marginLeft: `${(14 / 297) * this.props.columnWidth}vw`,
            }}
            src={require("assets/longArrow.svg")}
          />
        </div>
      </BlogItemContainer>
    );
  }
}

export default class BlogPage extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.state = {
      viewAllHover: false
    };
  }

  componentDidMount() {
    if (!this.props.in) {
      this.container.current.style.top = "100vh";
    }
  }

  animatePageDown() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    pageAnimation(this.container.current, false, false, () => {
      let _ = this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    });
  }

  animatePageUp() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    pageAnimation(this.container.current, true, false, () => {
      let _ = this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.footer !== this.props.footer) {
      if (this.props.footer) {
        this.animatePartialPageUp();
      } else {
        this.animateRestore();
      }
    }
  }

  animateRestore() {
    TweenMax.to(this.container.current, 0.5, {
      top: "0px",
      delay: 0,
      onComplete: () => {
        if (this.props.onEndAnimation) {
          this.props.onEndAnimation();
        }
      },
      ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
    });
  }

  animatePartialPageUp() {
    TweenMax.fromTo(
      this.container.current,
      0.5,
      {
        top: "0px"
      },
      {
        top: `-${this.props.footer.current.scrollHeight}px`,
        onComplete: () => {
          if (this.props.onEndAnimation) {
            this.props.onEndAnimation();
          }
        },
        delay: 0,
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );
  }

  leftMarginForLayout() {
    return this.props.blogLeftAlignment === Alignment.LEFT
      ? `0vh`
      : `${this.props.leftMargin}vw`;
  }

  blogWidthForLayout() {
    return this.props.blogLeftAlignment === Alignment.LEFT
      ? `100%`
      : `${this.props.columnDividerContainerWidth}vw`;
  }

  render() {
    let blogListSpaceHeight =
      this.props.windowHeight -
      ((this.props.logoWidth * 0.44 + 2 * 0.7 * this.props.columnWidth) / 100) *
        this.props.windowWidth;
    let itemHeight =
      ((this.props.columnWidth * BlogItemAspectRatio) / 100) *
      this.props.windowWidth;
    let visibleBlogCount = Math.min(
      getBlogData().length,
      Math.floor(blogListSpaceHeight / itemHeight)
    );
    return (
      <Transition
        in={this.props.in}
        timeout={1006}
        addEndListener={() => {
          this.props.in ? this.animatePageUp() : this.animatePageDown();
        }}
      >
        <FullPageArea
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
          ref={this.container}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%"
            }}
          >
            <div
              style={{
                position: "relative",
                left: 0,
                top: `${this.props.columnWidth * 0.6}vw`,
                width: "100%"
              }}
            >
              <SectionTitle
                {...this.props}
                style={{
                  position: "absolute",
                  top: 0,
                  left: `${this.props.leftMargin}vw`
                }}
                firstLine="Our blogs and news"
                secondLine="What's new?"
              />
              <div
                style={{
                  position: "relative",
                  top: `${0.6 * this.props.columnWidth}vw`,
                  left: this.leftMarginForLayout(),
                  width: this.blogWidthForLayout()
                }}
              >
                {[...Array(visibleBlogCount + 1)].map((_, i) => (
                  <Fragment>
                    {i < visibleBlogCount && (
                      <BlogItem
                        {...this.props}
                        key={`blogitem${i}`}
                        last={i === visibleBlogCount - 1}
                        spacing={this.props.columnWidth * (-2 / 297)}
                        icon={getBlogData()[i].icon}
                        href={getBlogData()[i].href}
                        title={getBlogData()[i].title}
                        date={getBlogData()[i].date}
                      />
                    )}
                    {i === visibleBlogCount && (
                      <div
                        style={{
                          marginTop: `${this.props.columnWidth * (61 / 297)}vw`,
                          position: "relative"
                        }}
                      >
                        <button
                          onMouseEnter={() => {
                            this.setState({ viewAllHover: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ viewAllHover: false });
                          }}
                          style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            height: `${this.props.columnWidth * (30 / 297)}vw`,
                            width: "auto"
                          }}
                          onClick={() => {
                            window.open(
                              "https://medium.com/milkinside",
                              "_blank"
                            )
                          }}
                        >
                          <div
                            style={{
                              pointerEvents: "none",
                              display: "grid",
                              gridTemplateColumns: `auto ${this.props
                                .columnWidth * 0.0505}vw auto`,
                              gridTemplateRows: `auto`
                            }}
                          >
                            <SlidingText
                              in={true}
                              ref={this.viewmore}
                              noDelay={true}
                              style={{
                                fontFamily: "Aktiv Grotesk W01 Medium",
                                fontSize: `${responsiveFontSize(20, 14)}vw`,
                                lineHeight: `${responsiveFontSize(24, 18)}vw`,
                                color: this.state.viewAllHover
                                  ? "#000000"
                                  : "#A6A6A6",
                                transition: "color 0.5s",
                              }}
                              wrapStyle={{
                                gridColumnStart: 1,
                                gridColumnEnd: 2
                              }}
                            >
                              View All
                            </SlidingText>
                            <img
                              style={{
                                position: "relative",
                                gridColumnStart: 3,
                                gridColumnEnd: 4,
                                top: "50%",
                                transform: "translate(0, -50%)",
                                width: `${(38 / 297) *
                                  this.props.columnWidth}vw`,
                                height: `${(7.5 / 297) *
                                  this.props.columnWidth}vw`
                              }}
                              src={require("assets/longArrow.svg")}
                            />
                          </div>
                        </button>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </FullPageArea>
      </Transition>
    );
  }
}
