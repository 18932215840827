import React, { Component } from "react";
import { responsiveFontSize } from "../Layouts";
import { SlidingText } from "components/SlidingText";
import { mod } from "../utils";
import Observer from "@researchgate/react-intersection-observer";
import { EmailMessage, EmailSubject } from "../pages/Contact";

let FooterTitles = [
  "Experience Design",
  "Visual Design",
  "Motion Design",
  "Product Design",
  "Branding",
  "Development"
];

let FooterBottomLine = [
  { text: "HQ San Francisco, CA" },
  { text: "interact@milkinside.com", href: `mailto:interact@milkinside.com?subject=${EmailSubject}&body=${EmailMessage}` },
  {
    icon: require("assets/dribbble-logo.png"),
    text: "Dribbble",
    href: "https://dribbble.com/milkinside"
  },
  {
    icon: require("assets/ig-logo.png"),
    text: "Instagram",
    href: "https://instagram.com/milkinsideinc"
  }
];

class SocialBottomLine extends Component {
  render() {
    return (
      <a href={this.props.href} target="_blank">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            pointerEvents: "none"
          }}
        >
          <img
            style={{
              height: `${0.0538 * this.props.columnWidth}vw`,
              width: `${0.0538 * this.props.columnWidth}vw`
            }}
            src={this.props.icon}
          />
          <p
            style={{
              color: "white",
              marginLeft: `${(8 / 297) * this.props.columnWidth}vw`,
              fontFamily: "Aktiv Grotesk W01 Medium",
              fontSize: `${responsiveFontSize(14, 12)}vw`,
              lineHeight: `${responsiveFontSize(17, 14.52)}vw`
            }}
          >
            {this.props.text}
          </p>
        </div>
      </a>
    );
  }
}

class FooterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topLineIn: true,
      topLine: props.topLine
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.topLine !== this.props.topLine) {
      setTimeout(() => {
        this.setState({
          topLineIn: false
        });

        setTimeout(() => {
          this.setState({
            topLineIn: true,
            topLine: this.props.topLine
          });
        }, 400);
      }, this.props.delay);
    }
  }

  render() {
    return (
      <div
        style={{
          position: "relative"
        }}
      >
        <SlidingText
          in={this.state.topLineIn}
          isTransition={true}
          transitionDuration={0.35}
          transitionDelay={0.25}
          style={{
            color: "white",
            fontFamily: "Aktiv Grotesk W01 Bold1370651",
            fontSize: `${responsiveFontSize(16, 14)}vw`,
            lineHeight: `${responsiveFontSize(19, 16.62)}vw`
          }}
        >
          {this.state.topLine}
        </SlidingText>
        <div
          style={{
            marginTop: `${(6 / 297) * this.props.columnWidth}vw`,
            height: "1px",
            width: "100%",
            background: "white"
          }}
        />
        {this.props.icon && <SocialBottomLine {...this.props} />}
        {!this.props.icon && this.props.href && (
          <a href={this.props.href} target="_blank">
            <p
              style={{
                color: "white",
                marginBottom: 0,
                fontFamily: "Aktiv Grotesk W01 Medium",
                fontSize: `${responsiveFontSize(14, 12)}vw`,
                lineHeight: `${responsiveFontSize(17, 14.52)}vw`,
                pointerEvents: "none"
              }}
            >
              {this.props.text}
            </p>
          </a>
        )}
        {!this.props.icon && !this.props.href && (
          <p
            style={{
              color: "white",
              marginBottom: 0,
              fontFamily: "Aktiv Grotesk W01 Medium",
              fontSize: `${responsiveFontSize(14, 12)}vw`,
              lineHeight: `${responsiveFontSize(17, 14.52)}vw`
            }}
          >
            {this.props.text}
          </p>
        )}
        {/* {this.props.isFirst && (
          <Fragment>
            <p
              style={{
                color: "white",
                marginBottom: 0,
                fontFamily: "Aktiv Grotesk W01 Medium",
                fontSize: `${responsiveFontSize(14, 12)}vw`,
                lineHeight: `${responsiveFontSize(17, 14.52)}vw`
              }}
            >
              San Francisco, USA
            </p>
            <p
              style={{
                color: "white",
                marginTop: 0,
                fontFamily: "Aktiv Grotesk W01 Medium",
                fontSize: `${responsiveFontSize(14, 12)}vw`,
                lineHeight: `${responsiveFontSize(17, 14.52)}vw`
              }}
            >
              interact@milkinside.com
            </p>
          </Fragment>
        )} */}
      </div>
    );
  }
}

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.intervalId = null;
    this.changeFooterToplines = this.changeFooterToplines.bind(this);
    this.setupAutoAdvanceFooter = this.setupAutoAdvanceFooter.bind(this);
    this.tearDownAutoAdvanceFooter = this.tearDownAutoAdvanceFooter.bind(this);
    this.handleObserver = this.handleObserver.bind(this);

    this.state = {
      footerItemOffsetIdx: 0
    };
  }
  getResponsiveFooterColumns() {
    if (this.props.nColumnDividors === 6) {
      //4
      return `1fr 1fr 1fr 1fr`;
    } else if (this.props.nColumnDividors === 5) {
      // 3
      return `1fr 1fr 1fr`;
    } else if (this.props.nColumnDividors === 4) {
      // 2
      return `1fr 1fr`;
    } else {
      // 1
      return "1fr";
    }
  }

  setupAutoAdvanceFooter() {
    this.intervalId = setInterval(this.changeFooterToplines, 3600);
    // console.log("setup the footer autoadvanced");
  }

  tearDownAutoAdvanceFooter() {
    if (this.intervalId) {
      // console.log("cleared the footer autoadvanced");
      clearInterval(this.intervalId);
    }
  }

  handleObserver(e) {
    if (e.isIntersecting) {
      setTimeout(() => {
        this.changeFooterToplines();
        this.setupAutoAdvanceFooter();
      }, 1000);
    } else {
      this.tearDownAutoAdvanceFooter();
    }
  }

  changeFooterToplines() {
    this.setState({
      footerItemOffsetIdx: this.state.footerItemOffsetIdx - 1
    });
  }

  render() {
    return (
      <Observer onChange={this.handleObserver} threshold={0.8}>
        <div
          ref={this.props.aref}
          style={{
            position: "absolute",
            background: "#070707",
            width: `${this.props.windowWidth}px`,
            height: `${1.5 * this.props.columnWidth}vw`,
            bottom: 0,
            left: 0,
            ...this.props.style
          }}
        >
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, 0)",
                display: "grid",
                width: `${0.7 * this.props.windowWidth}px`,
                top: `${(97 / 297) * this.props.columnWidth}vw`,
                gridTemplateColumns: this.getResponsiveFooterColumns(),
                gridGap: `0 ${(25 / 297) * this.props.columnWidth}vw`
              }}
            >
              {[...Array(this.props.nColumnDividors - 2)].map((_, i) => (
                <FooterItem
                  {...this.props}
                  key={i}
                  delay={i * 350}
                  {...FooterBottomLine[i]}
                  topLine={
                    FooterTitles[
                      mod(
                        i + this.state.footerItemOffsetIdx,
                        FooterTitles.length
                      )
                    ]
                  }
                />
              ))}
            </div>

            <p
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, 0)",
                bottom: `${(97 / 297) * this.props.columnWidth}vw`,
                color: "rgba(255, 255, 255, 0.3)",
                fontFamily: "Aktiv Grotesk W01 Regular",
                fontSize: `${responsiveFontSize(14, 12)}vw`,
                textAlign: "center"
              }}
            >
              Copyrights © 2022 by{" "}
              <span style={{ color: "white" }}>Milkinside</span>. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </Observer>
    );
  }
}
