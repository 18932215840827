import { isMobileOnly, isMobile } from "react-device-detect";

export const LayoutMode = {
  DESKTOP: "desktop",
  MOBILE: "mobile"
};

export const Alignment = {
  COLUMN_DIVIDER: "column_divider",
  LEFT: "left"
};

export const AboutLayout = {
  DESKTOP: "desktop",
  HYBRID: "hybrid",
  MOBILE: "mobile"
};

export const columnGridAttributesForSize = (width, height) => {
  let attributes = {
    leftMargin: null,
    columnDividerContainerWidth: null,
    nColumnDividors: null,
    logoWidth: null,
    headerWidth: null,
    headerMode: null,
    assetMode: null,
    blogLeftAlignment: null,
    dribbbleShotNumberCol: null,
    contactLayoutMode: null,
    aboutLayout: null,
    mobileMenuWidth: null
  };

  if (width >= 1100) {
    attributes.leftMargin = (100 - 77.2) / 2;
    attributes.columnDividerContainerWidth = 77.2;
    attributes.nColumnDividors = 6;
    attributes.logoWidth = 77.2 / (6 - 1);
    attributes.headerWidth = 77.2;
    attributes.headerMode = LayoutMode.DESKTOP;
    attributes.assetMode = LayoutMode.DESKTOP;
    attributes.blogLeftAlignment = Alignment.COLUMN_DIVIDER;
    attributes.dribbbleShotNumberCol = 3;
    attributes.contactLayoutMode = LayoutMode.DESKTOP;
    attributes.aboutLayout = AboutLayout.DESKTOP;
    attributes.mobileMenuWidth = 0;
  } else if (width <= 1100 && width >= 920) {
    let columnDividerMarginReductionPercentage =
      Math.min(1100 - width, 180.95) / 180.95;

    let cdcw = 77.2 + 16.45 * columnDividerMarginReductionPercentage;
    attributes.leftMargin = (100 - cdcw) / 2;
    attributes.columnDividerContainerWidth = cdcw;
    attributes.nColumnDividors = 6;
    attributes.logoWidth = cdcw / (attributes.nColumnDividors - 1);
    attributes.headerWidth = cdcw;
    attributes.headerMode = LayoutMode.DESKTOP;
    attributes.assetMode = LayoutMode.DESKTOP;
    attributes.blogLeftAlignment = Alignment.COLUMN_DIVIDER;
    attributes.dribbbleShotNumberCol = 3;
    attributes.contactLayoutMode = LayoutMode.DESKTOP;
    attributes.aboutLayout = AboutLayout.DESKTOP;
    attributes.mobileMenuWidth = 0;
  } else if (width < 920 && width >= 700) {
    let leftMarginPercentage = (920 - width) / 420;
    let cdcw = 93.65 - leftMarginPercentage * 19.85;

    attributes.leftMargin = (100 - cdcw) / 2;
    attributes.columnDividerContainerWidth = cdcw;
    attributes.nColumnDividors = 5;
    attributes.logoWidth =
      cdcw / (attributes.nColumnDividors - 1) + attributes.leftMargin;
    attributes.headerWidth = 100;
    attributes.headerMode = LayoutMode.MOBILE;
    attributes.assetMode = width < 810 ? LayoutMode.MOBILE : LayoutMode.DESKTOP;
    attributes.blogLeftAlignment = Alignment.COLUMN_DIVIDER;
    attributes.dribbbleShotNumberCol = width > 835 ? 3 : 2;
    attributes.contactLayoutMode = LayoutMode.DESKTOP;
    attributes.aboutLayout = AboutLayout.HYBRID;
    attributes.mobileMenuWidth = 50;
  } else if (width < 750 && width > 500) {
    let leftMarginPercentage = (920 - width) / 420;
    let cdcw = 93.65 - leftMarginPercentage * 19.85;

    attributes.leftMargin = (100 - cdcw) / 2;
    attributes.columnDividerContainerWidth = cdcw;
    attributes.nColumnDividors = 4;
    attributes.logoWidth =
      cdcw / (attributes.nColumnDividors - 1) + attributes.leftMargin;
    attributes.headerWidth = 100;
    attributes.headerMode = LayoutMode.MOBILE;
    attributes.assetMode = LayoutMode.MOBILE;
    attributes.blogLeftAlignment =
      width > 580 ? Alignment.COLUMN_DIVIDER : Alignment.LEFT;
    attributes.dribbbleShotNumberCol = width > 650 ? 2 : 1;
    attributes.contactLayoutMode = LayoutMode.MOBILE;
    attributes.aboutLayout = AboutLayout.MOBILE;
    attributes.mobileMenuWidth =
      width > 580
        ? (2 * cdcw) / (attributes.nColumnDividors - 1) + attributes.leftMargin
        : 100;
  } else if (width <= 500) {
    attributes = {
      width: `73.8vw`,
      nGridLines: 3,
      lastHidden: false,
      leftMargin: `10.66vw`,
      logoWidth: `calc(73.8vw / ${3 - 1} + 10.66vw)`
    };

    let cdcw = 73.8;
    attributes.leftMargin = 10.66;
    attributes.columnDividerContainerWidth = cdcw;
    attributes.nColumnDividors = 3;
    attributes.logoWidth =
      cdcw / (attributes.nColumnDividors - 1) + attributes.leftMargin;
    attributes.headerWidth = 100;
    attributes.headerMode = LayoutMode.MOBILE;
    attributes.assetMode = LayoutMode.MOBILE;
    attributes.blogLeftAlignment = Alignment.LEFT;
    attributes.dribbbleShotNumberCol = 1;
    attributes.contactLayoutMode = LayoutMode.MOBILE;
    attributes.aboutLayout = AboutLayout.MOBILE;
    attributes.mobileMenuWidth = 100;
  }
  attributes.columnWidth =
    attributes.columnDividerContainerWidth / (attributes.nColumnDividors - 1);
  attributes.windowWidth = width;
  attributes.windowHeight = height;
  return attributes;
};

export const layoutSizesMapToProps = ({ width, height }) => {
  let attributes = columnGridAttributesForSize(width, height);
  return {
    ...attributes
  };
};

export const responsiveFontSize = (originalSize, mobileSize) => {
  let attributes = columnGridAttributesForSize(
    window.innerWidth,
    window.innerHeight
  );
  let isMobile = attributes.headerMode === LayoutMode.MOBILE;
  let designLogoWidth = isMobile ? 180 : 297;

  let size = originalSize;
  if (mobileSize && isMobile)  {
    size = mobileSize;
  }
  return (size/ designLogoWidth) * attributes.logoWidth;
};

export const sizeInTermsOfColumnWidth = (size, currentColumnWidth) => {
  return size * currentColumnWidth;
};
