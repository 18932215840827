import React, { Component, Fragment } from "react";
import VideoControlsPage from "./VideoControlsPage";
import WorkSamplePage from "./SampleWork";

import { Transition } from "react-transition-group";
import { ScrollDirection } from "components/ScrollWrapper";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.totalPages = 2;
    this.state = {
      page: 0
    };

    this.workpage = React.createRef();
    this.handlePageTransition = this.handlePageTransition.bind(this);
  }

  handlePageTransition(node, done) {
    this.props.in ? this.mountAnimation() : this.unmountAnimation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.in && this.props.in !== prevProps.in) {
      this.workpage.current.container.current.style.visibility = "hidden";
    }
    if (this.props.in) {
      this.workpage.current.container.current.style.visibility = "visible";
    }
  }

  mountAnimation() {}

  unmountAnimation() {}

  shouldBackgroundScroll(direction) {
    if (
      (this.state.page === 0 && direction === ScrollDirection.up) ||
      (this.state.page === 1 && direction === ScrollDirection.down)
    ) {
      return true;
    }
    return false;
  }

  canScroll(direction) {
    if (this.state.page === 1 && !this.workpage.current.canScroll()) {
      return false;
    } 

    if (direction === ScrollDirection.down) {
      if (this.state.page < this.totalPages) return true;
    } else if (direction === ScrollDirection.up) {
      if (this.state.page >= 0) return true;
    }

    return false;
  }

  scrollUp() {
    this.setState({ page: 1 });
  }
  scrollDown() {
    this.setState({ page: 0 });
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={1000}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        onExit={() => this.setState({ page: 0 })}
        addEndListener={this.handlePageTransition}
      >
        <Fragment>
          <VideoControlsPage
            {...this.props}
            in={this.props.in && this.state.page === 0}
          />
          <WorkSamplePage
            {...this.props}
            ref={this.workpage}
            in={this.props.in && this.state.page === 1}
            dribbbleIDs={this.props.dribbbleIDs}
          />
        </Fragment>
      </Transition>
    );
  }
}
