import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";

const CursorBase = styled.div`
  position: fixed;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #867d78;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0.8;
  z-index: 200;
  transition: width 0.3s, height 0.3s;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -2px;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    transform: rotate(-45deg);
    border-bottom: none;
    border-right: none;
    opacity: 0;
    transition: opacity 0s;
  }
`;

const CursorClick = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  border-radius: 100%;
  background-color: #867d78;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 1;
  z-index: 199;
`;

export const CursorClickTransitions = `
{
    &.cursorClick-entering {
        width: 70px;
        height: 70px;
        opacity: 0;
        transition: 0.5s

    }

    &.cursorClick-entered {
        width: 0;
        height: 0;
        opacity: 1;
    }

    &.cursorClick-exiting {
        width: 70px;
        height: 70px;
        opacity: 0;
        transition: 0.5s
    }

    &.cursorClick-exited {
        width: 0;
        height: 0;
        opacity: 1;
    }
}
`;
export class Cursor extends Component {
  //export const Cursor = ({left, top, click}) => {
  constructor(props) {
    super(props);
    this.state = {
      curLeft: -1,
      curTop: -1,
      curClick: false,
      curShow: true,
      curHover: false
    };

    this.curLeftToUpdate = -1;
    this.curTopToUpdate = -1;
    this.needForRAF = true;
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClick.bind(this));
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    document.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
    document.addEventListener("mouseover", this.handleMouseOver.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick.bind(this));
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener(
      "mouseenter",
      this.handleMouseEnter.bind(this)
    );
    document.removeEventListener(
      "mouseleave",
      this.handleMouseLeave.bind(this)
    );
    document.removeEventListener("mouseover", this.handleMouseOver.bind(this));
  }

  handleMouseOver(event) {
    if (
      ["A", "BUTTON", "INPUT", "TEXTAREA", "SPAN"].includes(
        event.target.tagName
      ) &&
      this.state.curHover === false
    ) {
      if (
        event.target.tagName === "SPAN" &&
        !["Submit", "Submit again"].includes(event.target.innerText)
      ) {
        return;
      }

      if (event.target.tagName === "TEXTAREA") {
        // console.log("isFocus: " + JSON.stringify(event.target.attributes, 2));
        // console.log("isFocus: " + event.target.getAttribute("data-is-focus"));
      }

      this.setState({ curHover: true });
    } else if (
      !["A", "BUTTON", "INPUT", "SPAN", "TEXTAREA"].includes(
        event.target.tagName
      ) &&
      this.state.curHover === true
    ) {
      this.setState({ curHover: false });
    } else {
      return;
    }
  }

  handleClick(event) {
    this.setState({
      curLeft: event.clientX,
      curTop: event.clientY,
      curClick: !this.state.curClick,
      curShow: true
    });
  }
  handleMouseMove(event) {
    event.preventDefault();
    if (event.target.tagName === "TEXTAREA") {
      if (
        event.target.getAttribute("data-is-focus") === "true" &&
        this.state.curHover === true
      ) {
        this.setState({ curHover: false });
      }
    }

    this.curLeftToUpdate = event.clientX;
    this.curTopToUpdate = event.clientY;
    if (this.needForRAF) {
      this.needForRAF = false;
      requestAnimationFrame(this.updateCursorPosition.bind(this));
    }
  }
  handleMouseEnter(event) {
    this.setState({ curShow: true });
  }

  handleMouseLeave(event) {
    this.setState({ curShow: false });
  }

  updateCursorPosition() {
    this.needForRAF = true;
    this.setState({
      curLeft: this.curLeftToUpdate,
      curTop: this.curTopToUpdate,
      curShow: true
    });
  }
  render() {
    const { curLeft, curTop, curClick } = this.state;
    let hoverStyle = this.state.curHover
      ? {
          height: "50px",
          width: "50px"
        }
      : {};
    return (
      <Fragment>
        {this.state.curShow && (
          <CursorBase
            style={{
              left: `${curLeft}px`,
              top: `${curTop}px`,
              ...hoverStyle
            }}
          />
        )}
        <Transition in={curClick} timeout={500}>
          {status => (
            <CursorClick
              style={{
                left: `${curLeft}px`,
                top: `${curTop}px`
              }}
              className={`cursorClick-${status}`}
            />
          )}
        </Transition>
      </Fragment>
    );
  }
}
