import React, { Component, Fragment } from "react";
import TextSlideshowPage from "pages/home/TextSlideshow";
import BlogPage from "pages/home/BlogSection";
import { Transition } from "react-transition-group";
import { ScrollDirection } from "components/ScrollWrapper";
import Footer from "components/Footer";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.totalPages = 2;
    this.state = {
      page: 0,
      footer: false,
      isRoute: props.history.action === "PUSH" ? true : false
    };

    this.blogpage = React.createRef();
    this.footer = React.createRef();
    this.textSlideshow = React.createRef();
    this.resetAutoIncrement = this.resetAutoIncrement.bind(this);
  }

  resetAutoIncrement() {
    if (this.autoUpdateInterval) {
      clearInterval(this.autoUpdateInterval);
    }
    this.autoUpdateInterval = setInterval(() => {
      if (this.isAnimating) return;

      if (this.props.onRightArrow) {
        this.props.onRightArrow();
      }

      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 800);

    }, 12000);
  }

  componentWillUnmount() {
    if (this.autoUpdateInterval) {
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = null;
    }
  }

  componentDidMount() {
    if (this.props.autoadvance) {
      this.resetAutoIncrement();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.in !== this.props.in) {
      this.setState({
        isRoute: true
      });

      if (this.props.in) {
        this.resetAutoIncrement();
      }
      if (!this.props.in) {
        if (this.autoUpdateInterval) {
          clearInterval(this.autoUpdateInterval);
          this.autoUpdateInterval = null;
        }
      }
    }

    if (prevProps.in && prevProps.in !== this.props.in) {
      this.blogpage.current.container.current.style.visibility = "hidden";
    }

    if (this.props.in && this.state.page === 1) {
      this.blogpage.current.container.current.style.visibility = "";
    }

    if (
      this.props.in && (prevState.page !== this.state.page ||
      prevState.footer !== this.state.footer)
    ) {
      if (this.props.onAnimating) {
        this.props.onAnimating();
      }
    }

    if (prevProps.autoadvance !== this.props.autoadvance) {
      if (this.props.autoadvance) {
        this.resetAutoIncrement();
      }
    }
  }

  canHandleRightLeftArrow() {
    if (this.state.page === 0) {
      return true; 
    }
    return false;
  }

  prevText() {
    if (this.isAnimating) return;
    this.resetAutoIncrement();
    this.textSlideshow.current.prevText();
    this.isAnimating = true;
    setTimeout(() => {
      this.isAnimating = false;
    }, 800);

  }

  nextText() {
    if (this.isAnimating) return;
    this.resetAutoIncrement();
    this.textSlideshow.current.nextText();
    this.isAnimating = true;
    setTimeout(() => {
      this.isAnimating = false;
    }, 800);
  }

  shouldBackgroundScroll(direction) {
    if (
      (this.state.page === 0 && direction === ScrollDirection.up) ||
      (this.state.page === 1 &&
        !this.state.footer &&
        direction === ScrollDirection.down)
    ) {
      return true;
    }
    return false;
  }

  canScroll(direction) {
    if (direction === ScrollDirection.down) {
      if (this.state.page > 0) return true;
    } else if (direction === ScrollDirection.up) {
      if (!this.state.footer) return true;
    }

    return false;
  }

  scrollUp() {
    if (this.state.page === 1 && !this.state.footer) {
      this.setState({
        footer: this.footer
      });
    } else {
      this.setState({ page: 1, isRoute: false });
    }
  }
  scrollDown() {
    if (this.state.footer) {
      this.setState({
        footer: null,
        page: 1
      });
    } else {
      this.setState({ page: 0, isRoute: false });
    }
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={1200}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        onExited={() => this.setState({ page: 0, footer: null })}
        addEndListener={this.handlePageTransition}
      >
        <Fragment>
          <TextSlideshowPage
            {...this.props}
            ref={this.textSlideshow}
            in={this.props.in && this.state.page === 0}
            page={this.state.page}
            isRoute={this.state.isRoute}
            onEndAnimation={this.props.onEndAnimation}
            onRightArrow={() => {
              if (this.props.onRightArrow) {
                this.props.onRightArrow()
              }
            }}
            onLeftArrow={() => {
              if (this.props.onLeftArrow) { 
                this.props.onLeftArrow()
              }
            }}
          />
          <BlogPage
            onEndAnimation={this.props.onEndAnimation}
            ref={this.blogpage}
            {...this.props}
            in={this.props.in && this.state.page === 1}
            footer={this.state.footer}
          />
          <Footer
            {...this.props}
            onEndAnimation={this.props.onEndAnimation}
            aref={this.footer}
            style={{
              transform: `translate(0, ${this.state.footer ? "0" : "100%"})`,
              transition: "transform 0.5s cubic-bezier(0.60, 0.00, 0.39, 1.00)"
            }}
          />
        </Fragment>
      </Transition>
    );
  }
}
