import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { LayoutMode } from "../Layouts";
import { isMobile } from "react-device-detect";

export const ColumnDividor = styled.div`
  background: ${props => (props.dark ? "#6F6F6F" : "white")};
  opacity: ${props => props.dark ? "0.12" : (isMobile ? "0.10" : "0.10")};
  height: 100%;
  width: 1px;
  z-index: 0;
`;

export const FullPageArea = styled.div`
  position: fixed;
  height: ${props =>
    props.windowHeight ? `${props.windowHeight}px` : "100vh"};
  width: ${props => (props.windowWidth? `${props.windowWidth}px` : "100vw")};
  left: 0;
  top: 0;
`;


export class ColumnGrid extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          left: `${this.props.leftMargin}vw`,
          width: `${this.props.columnDividerContainerWidth}vw`,
          pointerEvents: 'none',
          ...this.props.style
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pointerEvents: "none",
            zIndex: -5
          }}
        >
          {[...Array(this.props.nColumnDividors)].map((x, i) => (
            <ColumnDividor
              dark={this.props.dark}
              style={{
                visibility:
                  this.props.headerMode === LayoutMode.MOBILE &&
                  i === this.props.nColumnDividors - 1
                    ? "hidden"
                    : "visible"
              }}
              key={i}
            />
          ))}
        </div>
      </div>
    );
  }
}