import React, { Component, Fragment } from "react";
import CustomEase from "../CustomEase";

import { Transition } from "react-transition-group";
import { FullPageArea } from "components/ColumnGrid";
import { responsiveFontSize } from "../Layouts";
import { ArrowBlock } from "components/ArrowButtons";
import { TweenMax } from "gsap";
import { mod } from "../utils";

const WorkReels = [
  {
    title: "Product Design",
    description:
      "Some hire an agency to design their screens. The best partner with Milkinside to build products. Our partners choose us because we understand their business and their users, and together we define the future through elegant product design.",
    vimeoID: "337038798",
  },
  {
    title: "Motion Graphics",
    description:
      "Digital product companies work with Milkinside to capture their product vision in motion. Whether the audience is investors and futurists at CES, or a global product release, our in-house CGI production teams bridge the virtual and real worlds to create moments of magic.",
    vimeoID: "391358922",
  },
  {
    title: "Milkinside knows AI",
    description:
      "Artificial and algorithmic intelligence continues to reshape our every commercial interaction. With every product and experience we build, we are deeply aware that systems perform only as they are designed to. Designing for the protection and service of humanity is our conscious choice.",
    vimeoID: "381923863",
  },
  {
    title: "Airbus app Design",
    description:
      "Travel planning is only beginning when you book your tickets, and Airbus saw the opportunity to be a companion throughout the journey.  We're proud to have partnered with them on several cutting edge mobile releases that support their carriers and their passengers.",
    vimeoID: "283909154",
  },
  {
    title: "Automotive HMI",
    description:
      "We have worked with automakers in China, Japan, Germany and the US creating and improving countless aspects of the human machine interface. From console design, to AI integration and visualization, to companion mobile applications, Milkinside partners with the best to make them better.",
    vimeoID: "336892078",
  },
  {
    title: "Brand Vision",
    description:
      "Based in San Francisco, Milkinside works locally in Silicon Valley and across the globe. Our clients and partners from dozens of industries and every time zone turn to us to tell their brand story, and build their products.",
    vimeoID: "337644485",
  },
];

class TextGroup extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.firstMount = true;
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={1350}
        appear={true}
        onEntered={() => (this.firstMount = false)}
        mountOnEnter={true}
        unmountOnExit={true}
        addEndListener={() => {
          TweenMax.fromTo(
            this.container.current,
            0.233,
            {
              opacity: this.props.in ? 0 : 1,
            },
            {
              opacity: this.props.in ? 1 : 0,
              delay: this.props.in ? 0.693 : 0.2,
              ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83"),
            }
          );
        }}
      >
        <div
          ref={this.container}
          style={{
            position: "absolute",
            top: this.props.nColumnDividors < 5 ? "35%" : "50%",
            left: `${this.props.leftMargin + 0.25 * this.props.columnWidth}vw`,
            width: `${
              (this.props.nColumnDividors === 6
                ? 1.75
                : this.props.nColumnDividors > 3
                ? 2.25
                : 1.7) * this.props.columnWidth
            }vw`,
            transform: "translate(0, -50%)",
          }}
        >
          <p
            style={{
              position: "relative",
              color: "white",
              fontFamily: "Aktiv Grotesk W01 Medium",
              fontWeight: "lighter",
              fontSize: `${responsiveFontSize(
                55,
                this.props.nColumnDividors === 5 ? 28 : 28
              )}vw`,
              // lineHeight: `${responsiveFontSize(
              //   0,
              //   this.props.nColumnDividors === 5 ? 22 : 22
              // )}vw`,
              // whiteSpace: "nowrap",
              margin: "0px",
              letterSpacing: `${
                this.props.columnWidth *
                (this.props.nColumnDividors < 5 ? 0.325 / 180 : 0)
              }vw`,
              textAlign: "left",
            }}
          >
            {this.props.title}
          </p>
          <p
            style={{
              marginTop: "25px",
              color: "rgba(255,255,255,0.9)",
              position: "relative",
              letterSpacing: "0.3px",
              fontFamily: "Neue Haas Grotesk Disp W01_55R",
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: `${responsiveFontSize(
                17,
                this.props.nColumnDividors === 5 ? 12 : 12
              )}vw`,
              lineHeight: `${responsiveFontSize(
                30,
                this.props.nColumnDividors === 5 ? 15 : 18
              )}vw`,
              textAlign: "left",
            }}
          >
            {this.props.description}
          </p>
        </div>
      </Transition>
    );
  }
}

export default class NewWork extends Component {
  constructor(props) {
    super(props);

    this.topBar = React.createRef();
    this.bottomBar = React.createRef();
    this.leftArrow = React.createRef();
    this.rightArrow = React.createRef();
    this.overlayHole = React.createRef();
    this.watchButton = React.createRef();

    this.workpage = React.createRef();
    this.vimeoBG = React.createRef();
    this.handlePageTransition = this.handlePageTransition.bind(this);
    this.windowSlide = this.windowSlide.bind(this);
    this.dissapearReappearButton = this.dissapearReappearButton.bind(this);

    this.state = {
      reelIdx: 0,
      vimeo: false,
    };
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.vimeo !== this.state.vimeo) {
      if (this.props.onFullscreen) {
        this.props.onFullscreen(this.state.vimeo);
      }
    }

    if (this.props.in && nextProps.in !== this.props.in) {
      this.setState({ reelIdx: 0 });
    }
  }

  handlePageTransition(node, done) {
    this.props.in ? this.mountAnimation() : this.unmountAnimation();
  }

  mountAnimation() {
    TweenMax.fromTo(
      this.topBar.current,
      0.6,
      {
        transform: "translate(0%, -100%)",
      },
      {
        transform: "translate(0%, 0%)",
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
    TweenMax.fromTo(
      this.bottomBar.current,
      0.6,
      {
        transform: "translate(0%, 100%)",
      },
      {
        transform: "translate(0%, 0%)",
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );

    // Overlay Hole
    TweenMax.set(this.overlayHole.current, { opacity: 0, left: 0, width: 0 });
    TweenMax.fromTo(
      this.overlayHole.current,
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
    TweenMax.fromTo(
      this.overlayHole.current,
      0.7,
      {
        width: 0,
      },
      {
        width: `100%`,
        ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00"),
        delay: 0.5,
      }
    );

    TweenMax.fromTo(
      this.leftArrow.current,
      0.233,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.5,
      }
    );
    TweenMax.fromTo(
      this.rightArrow.current,
      0.233,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.5,
      }
    );
    TweenMax.fromTo(
      this.watchButton.current,
      0.233,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.5,
      }
    );
  }

  unmountAnimation() {
    TweenMax.fromTo(
      this.topBar.current,
      0.6,
      {
        transform: "translate(0%, 0%)",
      },
      {
        transform: "translate(0%, -100%)",
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );
    TweenMax.fromTo(
      this.bottomBar.current,
      0.6,
      {
        transform: "translate(0%, 0%)",
      },
      {
        transform: "translate(0%, 100%)",
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00"),
      }
    );

    TweenMax.set(this.overlayHole.current, {
      opacity: 1,
      left: 0,
      width: "100%",
    });
    TweenMax.fromTo(
      this.overlayHole.current,
      0.4,
      {
        width: "100%",
      },
      {
        width: 0,
        ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00"),
      }
    );
    TweenMax.fromTo(
      this.overlayHole.current,
      0.4,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        delay: 0.4,
      }
    );

    TweenMax.fromTo(
      this.leftArrow.current,
      0.233,
      {
        opacity: 1,
      },
      {
        opacity: 0,
      }
    );
    TweenMax.fromTo(
      this.rightArrow.current,
      0.233,
      {
        opacity: 1,
      },
      {
        opacity: 0,
      }
    );

    TweenMax.fromTo(
      this.watchButton.current,
      0.233,
      {
        opacity: 1,
      },
      {
        opacity: 0,
      }
    );
  }

  shouldBackgroundScroll(direction) {
    return false;
  }

  canScroll(direction) {
    return false;
  }

  animateEnter() {}

  animateLeave() {}

  windowSlide() {
    TweenMax.set(this.overlayHole.current, {
      opacity: 1,
      right: 0,
      width: "100%",
      clearProps: "left",
    });
    TweenMax.fromTo(
      this.overlayHole.current,
      0.4,
      {
        width: "100%",
      },
      {
        width: 0,
        ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00"),
        onComplete: () => {
          TweenMax.set(this.overlayHole.current, {
            opacity: 1,
            left: 0,
            width: 0,
            clearProps: "right",
          });
        },
      }
    );
    TweenMax.fromTo(
      this.overlayHole.current,
      0.5,
      {
        width: 0,
      },
      {
        width: "100%",
        delay: 0.7,
      }
    );
  }

  dissapearReappearButton() {
    TweenMax.fromTo(
      this.watchButton.current,
      0.24,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        // delay: 0.2
      }
    );
    TweenMax.fromTo(
      this.watchButton.current,
      0.24,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.7,
      }
    );
  }

  nextText() {
    this.setState({
      reelIdx: mod(this.state.reelIdx + 1, WorkReels.length),
    });
    this.windowSlide();
    this.dissapearReappearButton();
  }

  prevText() {
    this.setState({
      reelIdx: mod(this.state.reelIdx - 1, WorkReels.length),
    });

    this.windowSlide();
    this.dissapearReappearButton();
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={1000}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        addEndListener={this.handlePageTransition}
      >
        <FullPageArea>
          <div
            ref={this.topBar}
            style={{
              position: "absolute",
              background: "black",
              width: "100vw",
              height: `${this.props.logoWidth * 0.44}vw`,
              top: 0,
            }}
          />
          <div
            ref={this.bottomBar}
            style={{
              position: "absolute",
              background: "black",
              width: "100vw",
              height: `${this.props.logoWidth * 0.44}vw`,
              bottom: 0,
            }}
          />

          <div
            style={{
              position: "absolute",
              height: `${
                (this.props.nColumnDividors === 6
                  ? 1
                  : this.props.nColumnDividors > 3
                  ? 1.25
                  : 1) * this.props.columnWidth
              }vw`,
              width: `${
                (this.props.nColumnDividors === 6
                  ? 1.75
                  : this.props.nColumnDividors > 3
                  ? 2.0
                  : 1.55) * this.props.columnWidth
              }vw`,
              top: "50%",
              left: "50%",
              transform:
                this.props.nColumnDividors === 6
                  ? "translate(-50%, -50%)"
                  : this.props.nColumnDividors === 5
                  ? "translate(-0%, -60%)"
                  : "translate(-30%, -50%)",
            }}
          >
            <button
              onClick={() => {
                this.setState({ vimeo: true });
              }}
              ref={this.overlayHole}
              style={{
                position: "absolute",
                height: `100%`,
                width: `100%`,
                boxShadow: `0 0 0 ${Math.max(
                  this.props.windowWidth,
                  this.props.windowHeight
                )}px rgba(0, 0, 0, .6)`,
              }}
            />
          </div>

          {WorkReels.map((e, i) => (
            <TextGroup
              {...this.props}
              in={this.props.in && i === this.state.reelIdx}
              title={e.title}
              description={e.description}
            />
          ))}

          <ArrowBlock
            {...this.props}
            left={true}
            aref={this.leftArrow}
            positionStyle={{
              left: `${
                this.props.leftMargin + -0.25 * this.props.columnWidth
              }vw`,
              top: `calc(50% + ${
                this.props.columnWidth *
                (this.props.nColumnDividors === 6 ? 0.5 : 0.6)
              }vw)`,
            }}
            onClick={this.props.onLeftArrow}
          />
          <ArrowBlock
            {...this.props}
            aref={this.rightArrow}
            right={true}
            positionStyle={{
              left: `${this.props.leftMargin}vw`,
              top: `calc(50% + ${
                this.props.columnWidth *
                (this.props.nColumnDividors === 6 ? 0.5 : 0.6)
              }vw)`,
            }}
            onClick={this.props.onRightArrow}
          />

          <button
            onClick={() => {
              this.setState({ vimeo: true });
            }}
            ref={this.watchButton}
            style={{
              position: "absolute",
              left: `${
                this.props.leftMargin +
                (this.props.nColumnDividors < 5
                  ? this.props.nColumnDividors > 3
                    ? 2.2
                    : 1.4
                  : 3.25) *
                  this.props.columnWidth
              }vw`,
              top: `calc(50% + ${
                this.props.columnWidth *
                (this.props.nColumnDividors < 5
                  ? this.props.nColumnDividors > 3
                    ? 0.45
                    : 0.45
                  : 0.3)
              }vw)`,
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                background: "white",
                height: "1px",
                width: `${this.props.columnWidth * 0.25}vw`,
                pointerEvents: "none",
              }}
            />
            <p
              style={{
                marginLeft: "10px",
                color: "white",
                WebkitTextFillColor: "white",
                position: "relative",
                letterSpacing: "0.3px",
                fontFamily: "Aktiv Grotesk W01 Medium",
                fontWeight: "lighter",
                fontStyle: "normal",
                fontSize: `${responsiveFontSize(
                  17,
                  this.props.nColumnDividors === 5 ? 10 : 10
                )}vw`,
                lineHeight: `${responsiveFontSize(
                  0,
                  this.props.nColumnDividors === 5 ? 15 : 18
                )}vw`,
                textAlign: "left",
                pointerEvents: "none",
              }}
            >
              WATCH VIDEO
            </p>
          </button>
          <Transition
            in={this.state.vimeo}
            timeout={500}
            appear={false}
            mountOnEnter={true}
            unmountOnExit={true}
            addEndListener={() => {
              TweenMax.fromTo(
                this.vimeoBG.current,
                0.3,
                {
                  opacity: this.state.vimeo ? 0 : 1,
                },
                {
                  opacity: this.state.vimeo ? 1 : 0,
                  ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83"),
                }
              );
            }}
          >
            <div
              ref={this.vimeoBG}
              onClick={() => {
                this.setState({
                  vimeo: false,
                });
              }}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                background: "black",
              }}
            >
              <iframe
                src={`https://player.vimeo.com/video/${
                  WorkReels[this.state.reelIdx].vimeoID
                }?autoplay=1&muted=0&playsinline=1`}
                width={
                  this.props.windowWidth -
                  2 *
                    (this.props.nColumnDividors === 6
                      ? 200
                      : this.props.nColumnDividors <= 4
                      ? 0
                      : 60)
                }
                height={
                  (this.props.windowWidth -
                    2 *
                      (this.props.nColumnDividors === 6
                        ? 200
                        : this.props.nColumnDividors <= 4
                        ? 0
                        : 60)) *
                  (9 / 16)
                }
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />

              <button
                onClick={() => {
                  this.setState({
                    vimeo: false,
                  });
                }}
                style={{
                  position: "absolute",
                  backgroundImage: `url('${require("../assets/close.png")}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  top: `calc(50% - ${
                    (this.props.windowWidth -
                      2 *
                        (this.props.nColumnDividors === 6
                          ? 200
                          : this.props.nColumnDividors <= 4
                          ? 0
                          : 60)) *
                    (9 / 16) *
                    0.5
                  }px)`,
                  transform: `translate(${
                    this.props.nColumnDividors > 4 ? "50%" : "0%"
                  }, -50%)`,
                  right:
                    this.props.nColumnDividors <= 4
                      ? "10px"
                      : `calc(50% - ${
                          (this.props.windowWidth -
                            2 *
                              (this.props.nColumnDividors === 6
                                ? 200
                                : this.props.nColumnDividors <= 4
                                ? 0
                                : 60)) *
                          0.5
                        }px)`,
                  height: "40px",
                  width: "40px",
                }}
              />
            </div>
          </Transition>
        </FullPageArea>
      </Transition>
    );
  }
}
