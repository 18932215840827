import React, { Component, Fragment } from "react";
import { FullPageArea } from "components/ColumnGrid";
import { AnimatedLine } from "components/SlidingText";
import { Transition } from "react-transition-group";
import {
  SlidingText,
  DescriptionText,
  LineAnimationStyle
} from "components/SlidingText";
import { ColumnStepSizeContainer } from "components/ColumnStepSizeContainer";
import { MilkContactUsForm } from "components/Form";
import { responsiveFontSize } from "../Layouts";
import { ColumnGrid } from "components/ColumnGrid";
import { opacityRouteAnimation } from "../animations/GlobalAnimations";
import Footer from "../components/Footer";
import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import { ScrollDirection } from "components/ScrollWrapper";

export const EmailMessage = encodeURI(`
At Milkinside we build bespoke digital experiences.
We create brands and design languages for clients that range from startups to multinational conglomerates, with products that include apps and websites, to 3D graphics and CGI videos.
We aim to take fewer, cutting edge projects to let our UX, visual, and motion design teams focus on high quality work.
We have a minimum engagement requirement of USD $100,000, but we'll engage on smaller scopes when the opportunity is right.
Tell us about your timeline, your budget, and how we can bring your product or experience to a whole new level.
`);
export const EmailSubject = encodeURI("Partnership Inquiry");

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionIdx: 0,
      isRoute: props.history.action === "PUSH" ? true : false,
      footer: false
    };
    this.container = React.createRef();
    this.footer = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.in !== this.props.in) {
      this.setState({
        isRoute: true
      });
    }

    if (prevState.footer !== this.state.footer) {
      if (this.state.footer) {
        this.animatePartialPageUp();
      } else {
        this.animateRestore();
      }
      if (this.props.onAnimating) {
        this.props.onAnimating();
      }
    }
  }

  getResponsiveTopMargin() {
    let height = this.getResponsiveHeight();
    return this.props.nColumnDividors > 4
      ? Math.max(
          0.7643 * this.props.columnWidth,
          (this.props.windowHeight / (2 * this.props.windowWidth)) * 100 -
            height / 2
        )
      : this.props.columnWidth;
  }

  getResponsiveDescriptiveTextWidth() {
    if (this.props.nColumnDividors < 5 && this.props.nColumnDividors > 3) {
      return this.props.columnDividerContainerWidth * 0.8;
    }
    if (this.props.nColumnDividors === 3) {
      return this.props.columnDividerContainerWidth * 0.92;
    }
    return 1.6599 * this.props.columnWidth;
  }

  getResponsiveHeight() {
    if (this.props.nColumnDividors === 5) {
      return 3.2 * this.props.columnWidth;
    }
    if (this.props.nColumnDividors < 5 && this.props.nColumnDividors > 3) {
      return 3.4 * this.props.columnWidth;
    }
    if (this.props.nColumnDividors === 3) {
      return 3.1 * this.props.columnWidth;
    }
    return 2 * this.props.columnWidth;
  }

  getResponsiveFormLeftMargin() {
    if (this.props.nColumnDividors === 6) {
      return 3 * this.props.columnWidth;
    }
    if (this.props.nColumnDividors === 5) {
      return 2 * this.props.columnWidth;
    }
  }

  getResponsiveTitleFont() {
    if (this.props.nColumnDividors < 5) {
      return;
    }
  }

  animateRestore() {
    TweenMax.to(this.container.current, 0.5, {
      top: "0px",
      delay: 0,
      onComplete: () => {
        if (this.props.onEndAnimation) {
          this.props.onEndAnimation();
        }
      },
      ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
    });
  }

  animatePartialPageUp() {
    TweenMax.fromTo(
      this.container.current,
      0.5,
      {
        top: "0px"
      },
      {
        top: `-${this.footer.current.scrollHeight}px`,
        onComplete: () => {
          if (this.props.onEndAnimation) {
            this.props.onEndAnimation();
          }
        },
        delay: 0,
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );
  }

  shouldBackgroundScroll(direction) {
    return false;
  }

  canScroll(direction) {
    if (direction === ScrollDirection.down) {
      if (this.state.footer) return true;
    } else if (direction === ScrollDirection.up) {
      if (!this.state.footer) return true;
    }

    return false;
  }

  scrollUp() {
    if (!this.state.footer) {
      this.setState({
        footer: true
      });
    } else {
      this.setState({ isRoute: false });
    }
  }
  scrollDown() {
    if (this.state.footer) {
      this.setState({
        footer: false
      });
    } else {
      this.setState({ isRoute: false });
    }
  }

  render() {
    return (
      <Fragment>
        <Transition
          in={this.props.in}
          appear={true}
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={1300}
          addEndListener={() => {
            if (this.props.in) {
              opacityRouteAnimation(this.container.current, true, 0.35, 0);
            }
          }}
        >
          <FullPageArea
            ref={this.container}
            windowHeight={this.props.windowHeight}
            windowWidth={this.props.windowWidth}
            style={{
              background: this.props.in ? "#F9F9F9" : ""
            }}
          >
            <ColumnGrid {...this.props} style={{ zIndex: -10 }} dark={true} />
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: `${this.props.columnDividerContainerWidth}vw`,
                top: `${this.getResponsiveTopMargin()}vw`,
                left: `${this.props.leftMargin}vw`
              }}
            >
              <ColumnStepSizeContainer
                {...this.props}
                blockHeight={
                  this.getResponsiveHeight() / this.props.columnWidth
                }
                blockWidth={this.props.nColumnDividors - 1}
                positioningStyle={{
                  left: 0,
                  top: 0
                }}
              >
                <SlidingText
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  wrapStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0
                  }}
                  style={{
                    color: "black",
                    fontFamily: "AktivGrotesk-Thin",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: `${responsiveFontSize(
                      100,
                      this.props.nColumnDividors === 5 ? 46 : 37
                    )}vw`,
                    lineHeight: `${responsiveFontSize(113, 52)}vw`,
                    textAlign: "left"
                  }}
                >
                  The future awaits
                </SlidingText>
                <AnimatedLine
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  style={LineAnimationStyle.left}
                  top={`${this.props.columnWidth * 0.44 * 1.04}vw`}
                  left={0}
                  width={`${this.props.columnWidth * 0.44 * 0.274}vw`}
                  height={`${(5 / 297) * this.props.columnWidth}vw`}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "black"
                    }}
                  />
                </AnimatedLine>
                <div
                  style={{
                    position: "absolute",
                    top: `${this.props.columnWidth * 0.44 * 1.38}vw`,
                    width: `${this.getResponsiveDescriptiveTextWidth()}vw`,
                    left: 0
                  }}
                >
                  <DescriptionText
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.state.isRoute}
                    style={{
                      left: 0,
                      width: "100%",
                      color: "rgb(111, 111, 111)",
                      fontFamily: "Neue Haas Grotesk Disp W01_55R",
                      fontWeight: "lighter",
                      fontSize: `${responsiveFontSize(
                        22,
                        this.props.nColumnDividors === 5 ? 13 : 14
                      )}vw`,
                      lineHeight: `${responsiveFontSize(
                        28,
                        this.props.nColumnDividors === 5 ? 22 : 23
                      )}vw`,
                      textAlign: "left",
                      position: "relative"
                    }}
                  >
                    At Milkinside, we work with fewer clients to allow our
                    creative teams to focus on building ground-breaking
                    experiences.
                  </DescriptionText>
                  <DescriptionText
                    in={this.props.in}
                    isRoute={this.state.isRoute}
                    style={{
                      left: 0,
                      width: "100%",
                      color: "rgb(111, 111, 111, 0.9)",
                      fontFamily: "Neue Haas Grotesk Disp W01_55R",
                      fontWeight: "400",
                      fontStyle: "normal",
                      fontSize: `${responsiveFontSize(
                        22,
                        this.props.nColumnDividors === 5 ? 13 : 14
                      )}vw`,
                      lineHeight: `${responsiveFontSize(
                        28,
                        this.props.nColumnDividors === 5 ? 22 : 23
                      )}vw`,
                      textAlign: "left",
                      position: "relative"
                    }}
                  >
                    Whether we’re working on a fixed project basis, or for
                    equity and a monthly retainer, we select partners where we
                    can create maximum business value.
                  </DescriptionText>
                </div>

                <SlidingText
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  wrapStyle={{
                    position: "absolute",
                    left: `${this.props.columnWidth * -0.121}vw`,
                    bottom: `${this.props.columnWidth *
                      (this.props.nColumnDividors < 6 ? 0.46 : 0.2866)}vw`
                  }}
                  style={{
                    color: "black",
                    fontFamily: "Aktiv Grotesk W01 Bold1370651",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: `${responsiveFontSize(15, 13)}vw`,
                    lineHeight: `${responsiveFontSize(18, 15)}vw`,
                    textAlign: "left"
                  }}
                >
                  A:
                </SlidingText>
                <SlidingText
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  wrapStyle={{
                    position: "absolute",
                    left: 0,
                    bottom: `${this.props.columnWidth *
                      (this.props.nColumnDividors < 6 ? 0.45 : 0.2766)}vw`
                  }}
                  style={{
                    color: "black",
                    fontFamily: "Aktiv Grotesk W01 Light1370681",
                    fontSize: `${responsiveFontSize(17, 13)}vw`,
                    lineHeight: `${responsiveFontSize(23, 15)}vw`,
                    textAlign: "left"
                  }}
                >
                  San Francisco, CA, USA
                </SlidingText>
                <SlidingText
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  wrapStyle={{
                    position: "absolute",
                    left: `${this.props.columnWidth * -0.121}vw`,
                    bottom: `${(this.props.nColumnDividors < 6
                      ? 0.32
                      : 0.1995) * this.props.columnWidth}vw`
                  }}
                  style={{
                    color: "black",
                    fontFamily: "Aktiv Grotesk W01 Bold1370651",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: `${responsiveFontSize(15, 13)}vw`,
                    lineHeight: `${responsiveFontSize(18, 15)}vw`,
                    textAlign: "left"
                  }}
                >
                  E:
                </SlidingText>
                <SlidingText
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  wrapStyle={{
                    position: "absolute",
                    left: 0,
                    bottom: `${(this.props.nColumnDividors < 6
                      ? 0.32
                      : 0.1895) * this.props.columnWidth}vw`
                  }}
                  style={{
                    color: "black",
                    fontFamily: "Aktiv Grotesk W01 Light1370681",
                    fontSize: `${responsiveFontSize(17, 13)}vw`,
                    lineHeight: `${responsiveFontSize(23, 15)}vw`,
                    textAlign: "left"
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none"
                    }}
                    href={`mailto:interact@milkinside.com?subject=${EmailSubject}&body=${EmailMessage}`}
                  >
                    interact@milkinside.com
                  </a>
                </SlidingText>
                <a
                  style={{
                    textDecoration: "none"
                  }}
                  href={`mailto:interact@milkinside.com?subject=${EmailSubject}&body=${EmailMessage}`}
                >
                  <SlidingText
                    in={this.props.in}
                    isRoute={this.state.isRoute}
                    wrapStyle={{
                      position: "absolute",
                      left: 0,
                      bottom: 0
                    }}
                    style={{
                      color: "black",
                      fontFamily: "Aktiv Grotesk W01 Medium",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: `${responsiveFontSize(18, 14)}vw`,
                      lineHeight: `${responsiveFontSize(22, 18)}vw`,
                      textAlign: "left",
                      pointerEvents: "none"
                    }}
                  >
                    WHAT SHOULD WE CREATE TOGETHER?
                  </SlidingText>
                </a>
                <AnimatedLine
                  in={this.props.in}
                  isRoute={this.state.isRoute}
                  style={LineAnimationStyle.left}
                  bottom={`${this.props.columnWidth *
                    0.44 *
                    (this.props.nColumnDividors < 6 ? 0.12 : 0.068)}vw`}
                  left={`${this.props.columnWidth * 0.44 * -0.35}vw`}
                  width={`${this.props.columnWidth * 0.44 * 0.22}vw`}
                  height={"1px"}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "#A9A9A9"
                    }}
                  />
                </AnimatedLine>
                {this.props.nColumnDividors >= 5 && (
                  <MilkContactUsForm
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.state.isRoute}
                    left={`${this.getResponsiveFormLeftMargin()}vw`}
                    top={`${(this.props.nColumnDividors < 6 ? 1 : 0.68) *
                      this.props.columnWidth}vw`}
                  />
                )}
              </ColumnStepSizeContainer>
            </div>
          </FullPageArea>
        </Transition>
        <Footer
          aref={this.footer}
          {...this.props}
          onEndAnimation={this.props.onEndAnimation}
          style={{
            transform: `translate(0, ${this.state.footer ? "0" : "100%"})`,
            transition: "transform 0.5s cubic-bezier(0.60, 0.00, 0.39, 1.00)"
          }}
        />
      </Fragment>
    );
  }
}
