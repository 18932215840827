import React, { Component } from "react";
import { TweenMax, Linear } from "gsap";
import { CustomEase } from "../CustomEase";
import { VideoControls } from "components/VideoControls";
import { FullPageArea } from "components/ColumnGrid";
import { Transition } from "react-transition-group";
import { ArrowButton } from "components/ArrowButtons";

export default class VideoControlsPage extends Component {
  constructor(props) {
    super(props);
    this.scrollButton = React.createRef();
    this.video = null;
    this.controls = null;
  }

  componentWillUnmount() {
    // console.log("Project Video Page will unmount");
    this.teardown();
  }
  componentDidMount() {
    // console.log("Project Video Page did mount");
    let duration = 0.4;
    let delay = this.props.in ? 0.95 : 0;

    TweenMax.fromTo(
      this.scrollButton.current,
      duration,
      {
        opacity: this.props.in ? 0 : 1
      },
      {
        opacity: !this.props.in ? 0 : 1,
        delay: delay,
        onComplete: () => {
          // TweenMax.set(node, { clearProps: "transform" });
        },
        ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
      }
    );

    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.video && !prevProps.video) {
      this.setup();
      setTimeout(() => {
        // console.log("should be triggering the bounce");
        if (this.controls) {
          this.controls.playIconBounceAnimation();
        }
      }, 2000);
    } else if (this.props.in && !prevProps.in && this.video) {
      this.video
        .play()
        .then(e => {
          // console.log("Project page going back to page 1. Resume playing");
        })
        .catch(e => {
          // console.log("Project page going to page 1. play error");
        });
    } else if (!this.props.in && prevProps.in && this.video) {
      // console.log("Project page going to page 2. pause playing");
      // this.video.pause();
    }

    if (prevProps.in !== this.props.in) {
      let duration = 0.4;
      let delay = this.props.in ? 0.95 : 0;

      TweenMax.fromTo(
        this.scrollButton.current,
        duration,
        {
          opacity: this.props.in ? 0 : 1
        },
        {
          opacity: !this.props.in ? 0 : 1,
          delay: delay,
          onComplete: () => {
            // TweenMax.set(node, { clearProps: "transform" });
          },
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
      if (!!this.video) {
        TweenMax.fromTo(
          this.video,
          0.7,
          {
            volume: this.props.in ? 0 : 1
          },
          {
            volume: !this.props.in ? 0 : 1,
            delay: 0,
            onComplete: () => {
              // TweenMax.set(node, { clearProps: "transform" });

              if (!this.props.in) {
                this.video.pause();
              }
              this.video.volume = 1;
            },
            ease: Linear.ease
          }
        );
      }
    }
  }

  teardown() {
    // console.log("Project Page teardown");
    if (this.video) {
      this.teardownPlayer();
    }
    if (this.controls) {
      this.controls.componentWillUnmount();
    }
  }

  setup() {
    // console.log("Project Page setup");
    // console.log("video: " + JSON.stringify(this.video));
    if (this.props.video) {
      this.setupPlayer();

      if (this.controls) {
        this.controls.setState({
          isMuted: this.video.muted
        });
      }
    }
  }

  teardownPlayer() {
    if (!this.video) {
      return;
    }
    this.video.removeEventListener(
      "play",
      this.handleVideoPlayHandler.bind(this)
    );
    this.video.removeEventListener("timeupdate", this.updateControl.bind(this));
    this.video.removeEventListener(
      "pause",
      this.handleVideoPauseHandler.bind(this)
    );
  }

  setupPlayer() {
    this.video = this.props.video; //videoCache.project;
    this.video.addEventListener("play", this.handleVideoPlayHandler.bind(this));
    this.video.addEventListener("timeupdate", this.updateControl.bind(this));
    this.video.addEventListener(
      "pause",
      this.handleVideoPauseHandler.bind(this)
    );

    if (this.video.paused) {
      this.video.currentTime = 0;
      setTimeout(() => {
        this.video
          .play()
          .then(e => {
            // console.log(
              // "ProjectPage -> setupPlayer has successfully played the video"
            // );
          })
          .catch(e => {
            // console.log("Project -> setupPlayer has failed with error: " + e);
            // console.log(
              // "Project Page -> setupPlayer has failed with error(stringify): " +
                // JSON.stringify(e)
            // );
          });
      }, 700);
    }
  }

  handleVideoPlayHandler(e) {
    // console.log("Project video play event: " + JSON.stringify(e));
  }

  handleVideoPauseHandler(e) {
    // console.log("Project video pause event: " + JSON.stringify(e));
  }

  updateControl() {
    if (!this.video) {
      return;
    }

    if (!this.video.paused) {
      this.controls.setState({
        progress: this.video.currentTime / this.video.duration
      });
    }
  }

  handleVolumeToggle() {
    if (!this.video) {
      return;
    }

    if (this.video && this.video.muted) {
      this.video.muted = false;
    } else if (this.video && !this.video.muted) {
      this.video.muted = true;
    }

    if (this.controls) {
      this.controls.setState({
        isMuted: this.video.muted
      });
    }
  }

  handleVideoSeeking(progress) {
    if (!this.video) {
      return;
    }
    // console.log(
      // "handle video seeking before current time: " + this.video.currentTime
    // );
    this.video.currentTime = progress * this.video.duration;
    let playPromise = this.video.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // console.log("handle video seeking play success");
        // console.log(
          // "handle video seeking current time: " + this.video.currentTime
        // );
      });
    }
  }

  render() {
    return (
      <Transition timeout={1300} in={this.props.in}>
        <FullPageArea
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
          ref={this.container}
        >
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <VideoControls
              {...this.props}
              onRef={b => {
                this.controls = b;
              }}
              in={this.props.in}
              isRoute={this.props.isRoute}
              bottom={`${this.props.columnWidth * 0.269}vw`}
              onSoundToggle={() => this.handleVolumeToggle()}
              onVideoPause={() => {
                if (this.video) {
                  this.video.pause();
                }
              }}
              onVideoResume={progress => this.handleVideoSeeking(progress)}
            />

            <ArrowButton
              onClick={this.props.onScrollDownButton}
              ref={this.scrollButton}
              style={{
                position: "absolute",
                bottom: `${this.props.columnWidth * 0.084175}vw`,
                height: `${this.props.columnWidth * 0.03367}vw`,
                width: `${this.props.columnWidth * 0.134}vw`,
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <img
                style={{
                  pointerEvents: "none",
                  height: `${this.props.columnWidth * 0.03367}vw`,
                  width: `${this.props.columnWidth * 0.134}vw`
                }}
                src={require("assets/downPageArrow.png")}
              />
            </ArrowButton>
          </div>
        </FullPageArea>
      </Transition>
    );
  }
}
