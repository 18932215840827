import React, { Component } from "react";
import { AnimatedLine, LineAnimationStyle } from "components/SlidingText";
import { ArrowButton } from "components/ArrowButtons";
import { TweenMax, TimelineMax } from "gsap";
import CustomEase from "../CustomEase";
import { isMobile } from "react-device-detect";
import Hammer from "hammerjs";

export class VideoControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0.5,
      hovered: false,
      isScrubberHidden: false
    };
    this.isScrubbing = false;
    this.scrubber = React.createRef();
    this.musicIcon = React.createRef();
    this.intervalId = null;

    this.state = {
      isMuted: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.in !== prevProps.in) {
      let duration = 0.4;
      let delay = 0.2;
      if (!this.props.in) {
        delay = 0;
      } else if (this.props.isRoute) {
        delay = 0.95;
      }

      TweenMax.fromTo(
        this.musicIcon.current,
        duration,
        {
          opacity: this.props.in ? 0 : 1
        },
        {
          opacity: !this.props.in ? 0 : 1,
          delay: delay,
          onComplete: () => {
            // TweenMax.set(node, { clearProps: "transform" });
          },
          ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
        }
      );
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(() => this.timer(), 4000);
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    let duration = 0.4;
    let delay = 0.2;
    if (!this.props.in) {
      delay = 0;
    } else if (this.props.isRoute) {
      delay = 0.95;
    }

    TweenMax.fromTo(
      this.musicIcon.current,
      duration,
      {
        opacity: this.props.in ? 0 : 1
      },
      {
        opacity: !this.props.in ? 0 : 1,
        delay: delay,
        onComplete: () => {
          // TweenMax.set(node, { clearProps: "transform" });
        },
        ease: CustomEase.create("custom", "0.17, 0.17, 0.83, 0.83")
      }
    );

    this.bindEvents();
  }

  bindEvents() {
    if (!isMobile) {
      document.addEventListener(
        "mousemove",
        this.handleScrubberMouseMove.bind(this)
      );
      document.addEventListener(
        "mouseup",
        this.handleScrubberMouseUp.bind(this)
      );
    } else {
      this.hammer = new Hammer(this.scrubber.current);
      this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
      this.hammer.on("panstart", this.handleScrubberMouseDown.bind(this));
      this.hammer.on("panmove", this.handleScrubberTouchMove.bind(this));
      this.hammer.on("panend", this.handleScrubberTouchEnd.bind(this));
      document.addEventListener(
        "touchstart",
        this.handleTapScrubberShow.bind(this)
      );
    }
  }

  unbindEvents() {
    if (!isMobile) {
      document.removeEventListener(
        "mousemove",
        this.handleScrubberMouseMove.bind(this)
      );
      document.removeEventListener(
        "mouseup",
        this.handleScrubberMouseUp.bind(this)
      );
    } else {
      this.hammer.off("panstart", this.handleScrubberMouseDown.bind(this));
      this.hammer.off("panmove", this.handleScrubberTouchMove.bind(this));
      this.hammer.off("panend", this.handleScrubberTouchEnd.bind(this));
      document.removeEventListener(
        "touchstart",
        this.handleTapScrubberShow.bind(this)
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);

    this.unbindEvents();
  }

  timer() {
    if (!this.props.in) {
      return;
    }
    if (!this.state.isScrubberHidden) {
      this.setState({
        isScrubberHidden: true
      });
    }
  }

  handleTapScrubberShow() {
    if (this.state.isScrubberHidden) {
      this.setState({
        isScrubberHidden: false
      });
    }
  }

  handleScrubberTouchMove(e) {
    if (!this.props.in) {
      return;
    }
    if (this.state.isScrubberHidden) {
      this.setState({
        isScrubberHidden: false
      });
    }
    if (!this.isScrubbing || !this.scrubber) {
      return;
    }

    let offsetX = this.scrubber.current.getBoundingClientRect().x;
    let progress = (e.center.x - offsetX) / this.scrubber.current.clientWidth;
    progress = progress > 1 ? 1 : progress;
    progress = progress < 0 ? 0 : progress;
    this.setState({ progress: progress, hovered: true });
  }

  handleScrubberTouchEnd(e) {
    if (!this.props.in) {
      return;
    }
    if (!this.isScrubbing) {
      return;
    }
    // console.log("Video Controls Mouse Up");
    if (this.state.hovered) {
      this.setState({
        hovered: false
      });
    }

    let offsetX = this.scrubber.current.getBoundingClientRect().x;
    let progress = (e.center.x - offsetX) / this.scrubber.current.clientWidth;
    progress = progress > 1 ? 1 : progress;
    progress = progress < 0 ? 0 : progress;

    if (this.props.onVideoResume && this.isScrubbing) {
      this.props.onVideoResume(progress);
    }
    this.isScrubbing = false;
  }

  handleScrubberMouseMove(e) {
    if (!this.props.in) {
      return;
    }
    if (this.state.isScrubberHidden) {
      this.setState({
        isScrubberHidden: false
      });
    }
    if (!this.isScrubbing || !this.scrubber) {
      return;
    }

    let offsetX = this.scrubber.current.getBoundingClientRect().x;
    let progress = (e.clientX - offsetX) / this.scrubber.current.clientWidth;
    progress = progress > 1 ? 1 : progress;
    progress = progress < 0 ? 0 : progress;
    this.setState({ progress: progress, hovered: true });
  }

  handleScrubberMouseDown(e) {
    if (!this.props.in) {
      return;
    }
    // console.log("Video Controls Mouse Down");
    if (this.props.onVideoPause) {
      this.props.onVideoPause();
    }
    this.isScrubbing = true;
  }

  handleScrubberMouseUp(e) {
    if (!this.props.in) {
      return;
    }
    if (!this.isScrubbing) {
      return;
    }
    // console.log("Video Controls Mouse Up");
    if (this.state.hovered) {
      this.setState({
        hovered: false
      });
    }

    let offsetX = this.scrubber.current.getBoundingClientRect().x;
    let progress = (e.clientX - offsetX) / this.scrubber.current.clientWidth;
    progress = progress > 1 ? 1 : progress;
    progress = progress < 0 ? 0 : progress;

    if (this.props.onVideoResume && this.isScrubbing) {
      this.props.onVideoResume(progress);
    }
    this.isScrubbing = false;
  }

  playIconBounceAnimation() {
    let duration = 0.5;

    let bounceAnimation = new TimelineMax();
    bounceAnimation
      .to(this.musicIcon.current, 0.5 * duration, {
        transform: "scale3d(1.45, 1.45, 1.45)"
      })
      .to(this.musicIcon.current, 0.5 * duration, {
        transform: "scale3d(1, 1, 1)"
      })
      .to(this.musicIcon.current, 0.6 * duration, {
        transform: "scale3d(1, 1, 1)"
      })
      .to(this.musicIcon.current, 0.5 * duration, {
        transform: "scale3d(1.45, 1.45, 1.45)"
      })
      .to(this.musicIcon.current, 0.5 * duration, {
        transform: "scale3d(1, 1, 1)"
      });
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          bottom: this.props.bottom,
          left: "50%",
          transform: "translateX(-50%)",
          width: `85vw`
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%"
          }}
        >
          <ArrowButton
            onClick={() => this.props.onSoundToggle()}
            ref={this.musicIcon}
            style={{
              position: "absolute",
              right: 0,
              bottom: `${this.props.columnWidth * 0.124}vw`,
              height: `${this.props.columnWidth *
                (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`,
              width: `${this.props.columnWidth *
                (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`
            }}
          >
            {!this.state.isMuted && (
              <img
                style={{
                  pointerEvents: "none",
                  height: `${this.props.columnWidth *
                    (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`,
                  width: `${this.props.columnWidth *
                    (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`
                }}
                src={require("assets/soundIcon.png")}
              />
            )}
            {this.state.isMuted && (
              <img
                style={{
                  pointerEvents: "none",
                  height: `${this.props.columnWidth *
                    (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`,
                  width: `${this.props.columnWidth *
                    (this.props.nColumnDividors < 6 ? 0.2 : 0.121)}vw`
                }}
                src={require("assets/Mute.png")}
              />
            )}
          </ArrowButton>

          <ArrowButton
            onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}
            onMouseDown={e => this.handleScrubberMouseDown(e)}
            // onClick={e => this.handleClickSeek(e)}
            ref={this.scrubber}
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, 50%)",
              width: `85vw`,
              height: "10px",
              transition: "opacity 1s",
              opacity: this.state.isScrubberHidden ? 0 : 1
            }}
          >
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "auto",
                  width: "100%"
                }}
              >
                <AnimatedLine
                  in={this.props.in}
                  isRoute={this.props.isRoute}
                  style={LineAnimationStyle.center}
                  width={"100%"}
                  height={
                    this.state.hovered
                      ? `2px`
                      : `1px`
                  }
                  wrapStyle={{
                    transform: "translateY(-50%)",
                    transition: "height 0.6",
                    position: "relative"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: "#656565"
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: "white",
                        transition: this.props.in ? "transform 150ms" : null,
                        transform: this.props.in
                          ? `translateX(calc(-100% + ${this.state.progress *
                              100}%))`
                          : "translateX(-100%)"
                      }}
                    />
                  </div>
                </AnimatedLine>
              </div>
            </div>
          </ArrowButton>
        </div>
      </div>
    );
  }
}
