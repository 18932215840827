import React, { Component, Fragment } from "react";
import Hammer from "hammerjs";

export const ScrollDirection = Object.freeze({
  up: "up",
  down: "down",
  right: "right",
  left: "left",
  none: "none"
});

let panMode = false;

export class ScrollWrapper extends Component {
  constructor(props) {
    super(props);
    this.supportsWheel = false;
    this.handleScroll = this.handleScroll.bind(this);
    this.lastEventTime = null;

    this.wheelEvent = null;
    this.ticking = false;

    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  bindEvents() {
    document.addEventListener("wheel", this.handleScroll, {
      passive: true
    });
    document.addEventListener("keydown", this.handleKeypress, {
      passive: true
    });

    if ("ontouchstart" in document.documentElement) {
      let hammertime = new Hammer(document, {
        recognizers: [
          [Hammer.Swipe, { direction: Hammer.DIRECTION_ALL }],
          // [Hammer.Pan, { direction: Hammer.DIRECTION_ALL }]
        ],
        inputClass: Hammer.SUPPORT_POINTER_EVENTS
          ? Hammer.PointerEventInput
          : Hammer.TouchInput
      });

      // hammertime.on("panstart", () => {
      //   panMode = true;
      // });

      // hammertime.on("panend pancancel", () => {
      //   panMode = false;
      // });

      hammertime.on("swipeup", e => {
        // console.log("hammertime swipe up");
        this.props.onScroll(ScrollDirection.up);
      });
      hammertime.on("swipedown", e => {
        // console.log("hammertime swipe down");
        this.props.onScroll(ScrollDirection.down);
      });

      hammertime.on("swipeleft", e => {
        this.props.onScroll(ScrollDirection.left);
      });
      hammertime.on("swiperight", e => {
        this.props.onScroll(ScrollDirection.right);
      });

      document.body.addEventListener("click", allowClick, true);
      function allowClick(event) {
        if (panMode) {
          event.stopPropagation();
        }
      }
    }
  }

  unbindEvents() {
    document.removeEventListener("wheel", this.handleScroll, { passive: true });
    document.removeEventListener("keydown", this.handleKeypress, {
      passive: true
    });
  }

  componentDidMount() {
    this.bindEvents();
  }

  componentWillUnmount() {
    this.unbindEvents();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.enable === true && this.props.enable === false) {
      this.bindEvents();
    } else if (nextProps.enable === false && this.props.enable === true) {
      this.unbindEvents();
    }

    return true;
  }

  handleScroll(e) {
    if (!this.props.enable) {
      return;
    }

    this.wheelEvent = e;
    this.requestTick();
  }

  handleKeypress(e) {
    if (e.keyCode === 40) {
      if (this.props.onScroll) {
        this.props.onScroll(ScrollDirection.up);
      }
    } else if (e.keyCode === 38) {
      if (this.props.onScroll) {
        this.props.onScroll(ScrollDirection.down);
      }
    } else if (e.keyCode === 37) {
      if (this.props.onScroll) {
        this.props.onScroll(ScrollDirection.left);
      }
    } else if (e.keyCode === 39) {
      if (this.props.onScroll) {
        this.props.onScroll(ScrollDirection.right);
      }
    }
  }

  update() {
    if (Math.abs(this.wheelEvent.deltaY) > 5) {
      if (this.wheelEvent.deltaY > 0) {
        this.props.onScroll(ScrollDirection.up);
      } else {
        this.props.onScroll(ScrollDirection.down);
      }
      this.wheelEvent.stopImmediatePropagation();
    }

    this.ticking = false;
  }

  requestTick() {
    if (!this.ticking) {
      requestAnimationFrame(this.update.bind(this));
      this.ticking = true;
    }
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
