import React, { Component, Fragment } from "react";
import {
  AnimatedLine,
  LineAnimationStyle,
  SlidingText
} from "components/SlidingText";
import { TweenMax, TimelineMax, Linear, Power2 } from "gsap";
import CustomEase from "../CustomEase";
import styled from "styled-components";

import anime from "animejs/lib/anime.es.js";
import { Transition } from "react-transition-group";
import { DescriptionText } from "./SlidingText";
import { ValidateEmail } from "../FormValidation";
import { responsiveFontSize } from "../Layouts";

const NonStyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  border: none;
  background: transparent;
  padding-left: 1px;
`;

const NonStyledTextArea = styled.textarea`
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;
  background: transparent;

  ::-webkit-scrollbar {
    width: var(--scrollbarwidth, 0px);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
  }
`;

const CSSVariables = styled.div`
  --scrollbarwidth: ${props => props.scrollbarwidth};
`;

let initialTextTransform = `translate(0, 0)`;

const initialTextAreaTransform = columnWidth =>
  `translate(0, calc(${columnWidth * 0.44 * 0.053}vw))`;

let focusTextTransform = `scale(0.6) `;

export class MilkInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withBar: true
    };

    this.textRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.in !== this.props.in) {
      let duration;
      let delay;
      if (this.props.in && this.props.isRoute) {
        duration = 0.86;
        delay = 0.95;
      } else if (this.props.in && !this.props.isRoute) {
        duration = 0.86;
        delay = 0.2;
      } else {
        duration = 0.4;
        delay = 0;
      }
      TweenMax.fromTo(
        this.inputRef.current,
        duration,
        {
          opacity: this.props.in ? 0 : 1
        },
        {
          opacity: this.props.in ? 1 : 0,
          delay: delay,
          onComplete: () => {
            // TweenMax.set(node, { clearProps: "transform" });
          },
          ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
        }
      );
    }
  }

  handleOnBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    let duration = 0.28;

    if (this.inputRef.current.value) {
      return;
    }
    TweenMax.fromTo(
      this.textRef.current,
      duration,
      {
        transform: focusTextTransform,
        yPercent: -100
      },
      {
        transform: initialTextTransform,
        yPercent: 0,
        delay: 0,
        onComplete: () => {
          // TweenMax.set(node, { clearProps: "transform" });
        },
        ease: CustomEase.create("custom", "0.33, 0.00, 0.67, 1.00")
      }
    );
  }

  handleOnFocus(e) {
    // Scale and translate
    //0.33, 0.00, 0.67, 1.00
    let duration = 0.28;
    if (this.inputRef.current.value) {
      return;
    }
    TweenMax.fromTo(
      this.textRef.current,
      duration,
      {
        transform: initialTextTransform,
        yPercent: 0
      },
      {
        transform: focusTextTransform,
        yPercent: -100,
        delay: 0,
        onComplete: () => {
          // TweenMax.set(node, { clearProps: "transform" });
        },
        ease: CustomEase.create("custom", "0.33, 0.00, 0.67, 1.00")
      }
    );

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  reset() {
    this.setState({ withBar: true });
  }

  render() {
    let lineSwitch;
    let isRouteSwitch = this.props.isRoute;
    if (this.props.in && !this.state.withBar) {
      lineSwitch = false;
      isRouteSwitch = false;
    } else if (!this.props.in) {
      lineSwitch = false;
    } else if (this.props.in && this.state.withBar) {
      lineSwitch = true;
    }
    return (
      <div
        ref={this.props.aref}
        style={{
          ...this.props.wrapStyle
        }}
      >
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <SlidingText
            in={lineSwitch}
            isRoute={isRouteSwitch}
            Sref={this.textRef}
            wrapStyle={{
              pointerEvents: "none",
              position: "absolute",
              top: "0",
              width: "100%",
              left: 0,
              transform: initialTextTransform,
              transformOrigin: "0% 100%"
            }}
            style={{
              color: "#A4A4A4",
              fontFamily: "Aktiv Grotesk W01 Light1370681",
              fontSize: `${responsiveFontSize(24, 15)}vw`,
              lineHeight: `${responsiveFontSize(27, 19)}vw`,
              textAlign: "left"
            }}
          >
            {this.props.humanName}
          </SlidingText>

          <NonStyledInput
            ref={this.inputRef}
            onBlur={e => this.handleOnBlur(e)}
            onFocus={e => this.handleOnFocus(e)}
            onChange={e => this.props.onChange(e)}
            name={this.props.name}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              color: this.props.isValid ? "black" : "#cc0000",
              fontFamily: "Aktiv Grotesk W01 Regular",
              fontSize: `${responsiveFontSize(24, 14)}vw`,
              lineHeight: `${responsiveFontSize(27, 18)}vw`,
              textAlign: "left"
            }}
          />

          <AnimatedLine
            in={this.props.in}
            lineRef={this.props.lineRef}
            isRoute={this.props.isRoute}
            style={LineAnimationStyle.left}
            bottom={0}
            left={0}
            width={"100%"}
            height={"1px"}
          >
            <div
              style={{ height: "100%", width: "100%", background: "black" }}
            />
          </AnimatedLine>
        </div>
      </div>
    );
  }
}

export class MilkTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false
    };

    this.textRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.in !== this.props.in) {
      let duration;
      let delay;
      if (this.props.in && this.props.isRoute) {
        duration = 0.86;
        delay = 0.95;
      } else if (this.props.in && !this.props.isRoute) {
        duration = 0.86;
        delay = 0.2;
      } else {
        duration = 0.4;
        delay = 0;
      }
      TweenMax.fromTo(
        this.inputRef.current,
        duration,
        {
          opacity: this.props.in ? 0 : 1
        },
        {
          opacity: this.props.in ? 1 : 0,
          delay: delay,
          onComplete: () => {
            // TweenMax.set(node, { clearProps: "transform" });
          },
          ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
        }
      );
    }
  }

  handleOnBlur(e) {
    let duration = 0.28;
    this.setState({
      isFocus: false
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    TweenMax.fromTo(
      this.inputRef.current,
      duration,
      {
        height: `${0.694 * this.props.columnWidth}vw`
      },
      {
        height: `${0.124 * this.props.columnWidth}vw`,
        delay: 0,
        onComplete: () => {},
        ease: Power2.easeOut
      }
    );

    if (this.inputRef.current.value) {
      return;
    }

    TweenMax.fromTo(
      this.textRef.current,
      duration,
      {
        transform: focusTextTransform,
        yPercent: -100
      },
      {
        transform: initialTextAreaTransform(this.props.columnWidth),
        yPercent: 0,
        delay: 0,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.33, 0.00, 0.00, 1.00")
      }
    );
  }

  handleOnFocus(e) {
    // Scale and translate
    //0.33, 0.00, 0.67, 1.00

    this.setState({
      isFocus: true
    });
    if (this.props.onFocus) {
      this.props.onFocus();
    }

    let duration = 0.28;
    TweenMax.fromTo(
      this.inputRef.current,
      duration,
      {
        height: `${0.124 * this.props.columnWidth}vw`
      },
      {
        height: `${0.694 * this.props.columnWidth}vw`,
        delay: 0,
        onComplete: () => {},
        ease: Power2.easeIn
      }
    );
    if (this.inputRef.current.value) {
      return;
    }
    TweenMax.fromTo(
      this.textRef.current,
      duration,
      {
        transform: initialTextAreaTransform(this.props.columnWidth),
        yPercent: 0
      },
      {
        transform: focusTextTransform,
        yPercent: -100,
        delay: 0,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.33, 0.00, 0.67, 1.00")
      }
    );
  }

  render() {
    return (
      <div
        ref={this.props.aref}
        style={{
          ...this.props.wrapStyle
        }}
      >
        <CSSVariables scrollbarwidth={this.state.isFocus ? "1px" : "0px"}>
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <SlidingText
              in={this.props.in}
              isRoute={this.props.isRoute}
              Sref={this.textRef}
              wrapStyle={{
                pointerEvents: "none",
                position: "absolute",
                top: "0",
                left: 0,
                transform: initialTextAreaTransform(this.props.columnWidth),
                transformOrigin: "0% 50%"
              }}
              style={{
                color: "#A4A4A4",
                fontFamily: "Aktiv Grotesk W01 Light1370681",
                fontSize: `${responsiveFontSize(24, 14)}vw`,
                lineHeight: `${responsiveFontSize(27, 20)}}vw`,
                textAlign: "left"
              }}
            >
              {this.props.humanName}
            </SlidingText>

            <NonStyledTextArea
              ref={this.inputRef}
              onBlur={e => this.handleOnBlur(e)}
              onFocus={e => this.handleOnFocus(e)}
              onChange={e => this.props.onChange(e)}
              data-is-focus={this.state.isFocus}
              name={this.props.name}
              // rows={1}
              style={{
                color: "black",
                width: "100%",
                fontFamily: "Aktiv Grotesk W01 Regular",
                fontSize: `${responsiveFontSize(24, 14)}vw`,
                lineHeight: `135%`,
                textAlign: "left",
                height: `${0.124 * this.props.columnWidth}vw`
              }}
            />

            <AnimatedLine
              in={this.props.in}
              lineRef={this.props.lineRef}
              isRoute={this.props.isRoute}
              style={LineAnimationStyle.left}
              bottom={0}
              left={0}
              width={"100%"}
              height={"1px"}
            >
              <div
                style={{ height: "100%", width: "100%", background: "black" }}
              />
            </AnimatedLine>
          </div>
        </CSSVariables>
      </div>
    );
  }
}

export class MilkContactUsForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.fNameInput = React.createRef();
    this.fNameInputLineRef = React.createRef();
    this.lNameInput = React.createRef();
    this.lNameInputLineRef = React.createRef();
    this.emailInput = React.createRef();
    this.emailInputLineRef = React.createRef();
    this.phoneInput = React.createRef();
    this.phoneInputLineRef = React.createRef();
    this.messageInput = React.createRef();
    this.messageInputLineRef = React.createRef();

    this.fNameInputMotionPath = React.createRef();
    this.lNameInputMotionPath = React.createRef();
    this.emailInputMotionPath = React.createRef();
    this.phoneInputMotionPath = React.createRef();
    this.messageInputMotionPath = React.createRef();

    this.state = {
      submitAgainHover: false,
      inputsVisible: true,
      isEmailValid: true,
      firstNameValue: "",
      lastNameValue: "",
      emailValue: "",
      companyValue: "",
      messageValue: ""
    };
  }

  handleMessageOnChange(e) {
    // console.log(e.target.value.length);
    if (e.target.value && e.target.value.length > 1) {
      return;
    }

    this.setState({
      messageValue: e.target.value
    });
  }

  handleMessageFocus(e) {
    this.fNameInput.current.style.visibility = "hidden";
    this.lNameInput.current.style.visibility = "hidden";
    this.emailInput.current.style.visibility = "hidden";
    this.phoneInput.current.style.visibility = "hidden";
  }

  handleMessageBlur(e) {
    this.fNameInput.current.style.visibility = "visible";
    this.lNameInput.current.style.visibility = "visible";
    this.emailInput.current.style.visibility = "visible";
    this.phoneInput.current.style.visibility = "visible";

    this.setState({
      messageValue: e.target.value
    });
  }

  isFormValid() {
    return (
      this.state.firstNameValue !== "" &&
      this.state.emailValue !== "" &&
      this.state.messageValue !== "" &&
      this.state.isEmailValid
    );
  }

  handleFirstNameOnChange(e) {
    // console.log("firstname on change");
    // console.log(e.target.value);
    if (e.target.value && e.target.value.length > 1) {
      return;
    }

    this.setState({
      firstNameValue: e.target.value
    });
  }

  handleFirstNameBlur(e) {
    // console.log("first name blur");
    this.setState({
      firstNameValue: e.target.value
    });
  }

  handleLastNameOnChange(e) {
    if (e.target.value && e.target.value.length > 1) {
      return;
    }

    this.setState({
      lastNameValue: e.target.value
    });
  }

  handleLastNameBlur(e) {
    this.setState({
      lastNameValue: e.target.value
    });
  }

  handleEmailOnChange(e) {
    if (ValidateEmail(e.target.value) !== this.state.isEmailValid) {
      this.setState({
        isEmailValid: ValidateEmail(e.target.value)
      });
    }

    if (e.target.value && e.target.value.length > 1) {
      return;
    }

    this.setState({
      emailValue: e.target.value
    });
  }

  handleEmailBlur(e) {
    this.setState({
      emailValue: e.target.value
    });
  }

  handleCompanyOnChange(e) {
    if (e.target.value && e.target.value.length > 1) {
      return;
    }

    this.setState({
      companyValue: e.target.value
    });
  }

  handleCompanyBlur(e) {
    this.setState({
      companyValue: e.target.value
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const url =
      "https://vl5w2lg4m2.execute-api.us-east-1.amazonaws.com/dev/contact";
    let formData = new FormData(this.form.current);

    let bodyObject = {};
    formData.forEach((value, key) => {
      bodyObject[key] = value;
    });
    let bodyJSON = JSON.stringify(bodyObject);
    this.setState({
      inputsVisible: false,
      firstNameValue: "",
      lastNameValue: "",
      emailValue: "",
      companyValue: "",
      messageValue: ""
    });

    fetch(url, {
      mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: bodyJSON
    })
      .then(response => {
        // console.log(response);
        // console.log(response.text());
        response.text();
      })
      .then(text => {});
  }

  render() {
    return (
      <form
        ref={this.form}
        style={{
          position: "absolute",
          top: this.props.top,
          left: this.props.left,
          width: this.props.width
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%"
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              display: "grid",
              gridTemplateColumns: `${0.925 *
                this.props.columnWidth}vw ${0.074 *
                this.props.columnWidth}vw ${0.925 * this.props.columnWidth}vw`,
              gridTemplateRows: `${this.props.columnWidth * 0.124}vw ${0.161 *
                this.props.columnWidth}vw ${0.124 *
                this.props.columnWidth}vw ${0.161 *
                this.props.columnWidth}vw ${this.props.columnWidth *
                0.124}vw ${0.161 * this.props.columnWidth}vw ${0.124 *
                this.props.columnWidth}vw`
            }}
          >
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2
              }}
            >
              <svg
                viewBox="0 0 275 37"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  overflow: "visible",
                  visibility: "hidden"
                }}
              >
                <path
                  ref={this.fNameInputMotionPath}
                  d="M 0 0 c 8 -91, 172, -50, 147 31"
                  stroke="black"
                  fill="none"
                />
              </svg>
              <Transition
                in={this.state.inputsVisible}
                timeout={560 + 150}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.fromTo(
                      this.fNameInputLineRef.current,
                      0.2,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        transform: "scale(1)"
                      },
                      {
                        transform: "scale(0.78)",
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.65, 0.00, 0.90, 1.00"
                        )
                      }
                    );
                    //0.84, 0.00, 0.93, 1.00
                    let path = anime.path(this.fNameInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.fNameInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                  } else {
                    let duration = 0;
                    let delay = 0;

                    let path = anime.path(this.fNameInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.fNameInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                    motionPath.reverse();
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <MilkInput
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    aref={this.fNameInput}
                    lineRef={this.fNameInputLineRef}
                    onBlur={e => this.handleFirstNameBlur(e)}
                    onChange={e => this.handleFirstNameOnChange(e)}
                    isValid={true}
                    humanName="First Name"
                    name="first_name"
                    wrapStyle={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      left: 0,
                      top: 0
                    }}
                  />
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 3,
                gridColumnEnd: 4,
                gridRowStart: 1,
                gridRowEnd: 2
              }}
            >
              <svg
                viewBox="0 0 275 37"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  overflow: "visible",
                  visibility: "hidden"
                }}
              >
                <path
                  ref={this.lNameInputMotionPath}
                  d="M 0 0 c 69 20, 69 107, -52 68"
                  stroke="black"
                  fill="none"
                />
              </svg>
              <Transition
                in={this.state.inputsVisible}
                timeout={560 + 150}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.fromTo(
                      this.lNameInputLineRef.current,
                      0.2,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        transform: "scale(1)"
                      },
                      {
                        transform: "scale(0.78)",
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.65, 0.00, 0.90, 1.00"
                        )
                      }
                    );
                    //0.84, 0.00, 0.93, 1.00
                    let path = anime.path(this.lNameInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.lNameInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                  } else {
                    let duration = 0;
                    let delay = 0;

                    let path = anime.path(this.lNameInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.lNameInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                    motionPath.reverse();
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <MilkInput
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    aref={this.lNameInput}
                    lineRef={this.lNameInputLineRef}
                    humanName="Last Name"
                    name="last_name"
                    onBlur={e => this.handleLastNameBlur(e)}
                    onChange={e => this.handleLastNameOnChange(e)}
                    isValid={true}
                    wrapStyle={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      left: 0,
                      top: 0
                    }}
                  />
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 3,
                gridRowEnd: 4
              }}
            >
              <svg
                viewBox="0 0 275 37"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  overflow: "visible",
                  visibility: "hidden"
                }}
              >
                <path
                  ref={this.emailInputMotionPath}
                  d="M 0 0 c -50 -44, 36 -111, 52 -31"
                  stroke="black"
                  fill="none"
                />
              </svg>
              <Transition
                in={this.state.inputsVisible}
                timeout={560 + 150}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.fromTo(
                      this.emailInputLineRef.current,
                      0.2,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        transform: "scale(1)"
                      },
                      {
                        transform: "scale(0.78)",
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.65, 0.00, 0.90, 1.00"
                        )
                      }
                    );
                    //0.84, 0.00, 0.93, 1.00
                    let path = anime.path(this.emailInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.emailInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                  } else {
                    let duration = 0;
                    let delay = 0;

                    let path = anime.path(this.emailInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.emailInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                    motionPath.reverse();
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <MilkInput
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    aref={this.emailInput}
                    lineRef={this.emailInputLineRef}
                    isValid={this.state.isEmailValid}
                    humanName="Email"
                    name="email"
                    type="email"
                    onBlur={e => this.handleEmailBlur(e)}
                    onChange={e => this.handleEmailOnChange(e)}
                    wrapStyle={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      left: 0,
                      top: 0
                    }}
                  />
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 3,
                gridColumnEnd: 4,
                gridRowStart: 3,
                gridRowEnd: 4
              }}
            >
              <svg
                viewBox="0 0 275 37"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  overflow: "visible",
                  visibility: "hidden"
                }}
              >
                <path
                  ref={this.phoneInputMotionPath}
                  d="M 0 0 c 42 34, -8 107, -42 40"
                  stroke="black"
                  fill="none"
                />
              </svg>
              <Transition
                in={this.state.inputsVisible}
                timeout={560 + 150}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.fromTo(
                      this.phoneInputLineRef.current,
                      0.2,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        transform: "scale(1)"
                      },
                      {
                        transform: "scale(0.78)",
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.65, 0.00, 0.90, 1.00"
                        )
                      }
                    );
                    //0.84, 0.00, 0.93, 1.00
                    let path = anime.path(this.phoneInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.phoneInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                  } else {
                    let duration = 0;
                    let delay = 0;

                    let path = anime.path(this.phoneInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.phoneInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                    motionPath.reverse();
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <MilkInput
                    {...this.props}
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    aref={this.phoneInput}
                    lineRef={this.phoneInputLineRef}
                    onBlur={e => this.handleCompanyBlur(e)}
                    onChange={e => this.handleCompanyOnChange(e)}
                    isValid={true}
                    humanName="Company"
                    name="company"
                    wrapStyle={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      left: 0,
                      top: 0
                    }}
                  />
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 4,
                gridRowStart: 5,
                gridRowEnd: 6
              }}
            >
              <svg
                viewBox="0 0 565.02 37"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  overflow: "visible",
                  visibility: "hidden"
                }}
              >
                <path
                  ref={this.messageInputMotionPath}
                  d="M 0 0 c 22 63, -133 29, -52 -67"
                  stroke="black"
                  fill="none"
                />
              </svg>
              <Transition
                in={this.state.inputsVisible}
                timeout={560 + 150}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.fromTo(
                      this.messageInputLineRef.current,
                      0.2,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        transform: "scale(1)"
                      },
                      {
                        transform: "scale(0.78)",
                        delay: 0.15,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.65, 0.00, 0.90, 1.00"
                        )
                      }
                    );
                    //0.84, 0.00, 0.93, 1.00

                    let path = anime.path(this.messageInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.messageInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                  } else {
                    let duration = 0;
                    let delay = 0;

                    let path = anime.path(this.messageInputMotionPath.current);
                    let motionPath = anime({
                      targets: this.messageInput.current,
                      translateX: path("x"),
                      translateY: path("y"),
                      easing: "cubicBezier(0.84, 0.00, 0.93, 1.00)",
                      duration: 560,
                      delay: 150,
                      loop: false
                    });
                    motionPath.reverse();
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <MilkTextArea
                      {...this.props}
                      in={this.props.in}
                      aref={this.messageInput}
                      lineRef={this.messageInputLineRef}
                      isRoute={this.props.isRoute}
                      onFocus={e => this.handleMessageFocus(e)}
                      onBlur={e => this.handleMessageBlur(e)}
                      onChange={e => this.handleMessageOnChange(e)}
                      humanName="Message"
                      name="message"
                      wrapStyle={{
                        width: "100%",
                        position: "absolute",
                        bottom: 0
                      }}
                    />
                  </div>
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 4,
                gridRowStart: 7,
                gridRowEnd: 8
              }}
            >
              <Transition
                in={this.state.inputsVisible}
                timeout={440}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    this.animation = new TimelineMax({
                      repeat: 1
                    });

                    this.animation
                      .to(node, 0.08, {
                        scale: 0.8,
                        ease: CustomEase.create(
                          "custom",
                          "0.23, 0.07, 0.77, 0.93"
                        )
                      })
                      .to(node, 0.36, {
                        scale: 1,
                        ease: CustomEase.create(
                          "custom",
                          "0.23, 0.07, 0.77, 0.93"
                        )
                      });

                    TweenMax.fromTo(
                      node,
                      0.16,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.2,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );
                  }
                }}
              >
                <button
                  type="submit"
                  onClick={e => {
                    this.handleFormSubmit(e);
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "grid",
                    gridTemplateColumns: `auto ${0.0505 *
                      this.props.columnWidth}vw auto`,
                    gridTemplateRows: `auto`,
                    opacity: 1
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: `auto ${0.0505 *
                        this.props.columnWidth}vw auto`,
                      gridTemplateRows: `auto`
                    }}
                  >
                    <SlidingText
                      in={
                        this.isFormValid() ? this.props.in : this.isFormValid()
                      }
                      isRoute={this.props.isRoute}
                      style={{
                        color: "black",
                        fontFamily: "Aktiv Grotesk W01 Medium",
                        fontSize: `${responsiveFontSize(20)}vw`,
                        lineHeight: `${responsiveFontSize(24)}vw`,
                        fontWeight: "lighter",
                        textAlign: "left"
                      }}
                      wrapStyle={{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        gridRowStart: 1,
                        gridRowEnd: 2
                      }}
                    >
                      Submit
                    </SlidingText>

                    <div
                      style={{
                        position: "relative",
                        gridColumnStart: 3,
                        gridColumnEnd: 4,
                        gridRowStart: 1,
                        gridRowEnd: 2
                      }}
                    >
                      <AnimatedLine
                        in={
                          this.isFormValid()
                            ? this.props.in
                            : this.isFormValid()
                        }
                        isRoute={this.props.isRoute}
                        style={LineAnimationStyle.left}
                        top={"50%"}
                        left={0}
                        width={`${0.1279 * this.props.columnWidth}vw`}
                        height={`${0.038 * this.props.columnWidth}vw`}
                        wrapStyle={{
                          transform: "translateY(-50%)"
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            width: `${this.props.columnWidth * 0.1279}vw`,
                            height: `${this.props.columnWidth * 0.038}vw`,
                            top: 0,
                            left: 0,
                            transform: "translate(0, -50%) !important"
                          }}
                          src={require("assets/longArrow.svg")}
                        />
                      </AnimatedLine>
                    </div>
                  </div>
                </button>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 4,
                gridRowStart: 3,
                gridRowEnd: 4,
                pointerEvents: this.state.inputsVisible ? "none" : null
              }}
            >
              <Transition
                in={!this.state.inputsVisible}
                timeout={1020}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (!this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    TweenMax.set(node, { opacity: 0 });
                    TweenMax.fromTo(
                      node,
                      0.28,
                      {
                        scale: 0.81
                      },
                      {
                        scale: 1,
                        delay: 0.74,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          done();
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.39, 0.00, 0.40, 1.00"
                        )
                      }
                    );

                    TweenMax.fromTo(
                      node,
                      0.65,
                      {
                        opacity: 0.0
                      },
                      {
                        opacity: 0.0,
                        delay: 0,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          node.style.opacity = 1;
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.39, 0.00, 0.40, 1.00"
                        )
                      }
                    );
                  } else {
                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          done();
                        },
                        // ease: CustomEase.create("custom", "1.00, 0.00, 0.67, 1.00")
                        ease: CustomEase.create(
                          "custom",
                          "0.33, 0.00, 0.00, 1.00"
                        )
                      }
                    );
                  }
                }}
              >
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <DescriptionText
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    style={{
                      width: `${this.props.columnWidth * 1.572}vw`,
                      color: "black",
                      fontFamily: "Aktiv Grotesk W01 Medium",
                      fontSize: `${responsiveFontSize(20)}vw`,
                      lineHeight: `${responsiveFontSize(24)}vw`,
                      textAlign: "left",
                      position: "absolute",
                      top: "-50%",
                      left: `${this.props.columnWidth * 0.205}vw`,
                      transformOrigin: "0% 50%"
                    }}
                  >
                    Thank you! We will review your submission and get back to
                    you within 5 business days.
                  </DescriptionText>
                </div>
              </Transition>
            </div>
            <div
              style={{
                position: "relative",
                gridColumnStart: 1,
                gridColumnEnd: 4,
                gridRowStart: 5,
                gridRowEnd: 6,
                pointerEvents: this.state.inputsVisible ? "none" : null
              }}
            >
              <Transition
                in={!this.state.inputsVisible}
                timeout={560}
                appear={false}
                unmountOnExit={true}
                mountOnEnter={true}
                addEndListener={(node, done) => {
                  if (this.state.inputsVisible) {
                    let duration = 0;
                    let delay = 0;

                    this.animation = new TimelineMax({
                      repeat: 1
                    });

                    this.animation
                      .to(node, 0.08, {
                        scale: 0.8,
                        ease: CustomEase.create(
                          "custom",
                          "0.23, 0.07, 0.77, 0.93"
                        )
                      })
                      .to(node, 0.36, {
                        scale: 1,
                        ease: CustomEase.create(
                          "custom",
                          "0.23, 0.07, 0.77, 0.93"
                        )
                      });

                    TweenMax.fromTo(
                      node,
                      0.16,
                      {
                        opacity: 1
                      },
                      {
                        opacity: 0,
                        delay: 0.2,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          done();
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );
                  } else {
                    TweenMax.fromTo(
                      node,
                      0.56,
                      {
                        opacity: 0
                      },
                      {
                        opacity: 0,
                        delay: 0.0,
                        onComplete: () => {
                          // TweenMax.set(node, { clearProps: "transform" });
                          node.style.opacity = 1;
                          done();
                        },
                        ease: CustomEase.create(
                          "custom",
                          "0.17, 0.17, 0.83, 0.83"
                        )
                      }
                    );
                  }
                }}
              >
                <button
                  type="submit"
                  onClick={e => {
                    this.setState({ inputsVisible: true });
                    e.preventDefault();
                  }}
                  onMouseEnter={() => {this.setState({submitAgainHover: true})}}
                  onMouseLeave={() => {this.setState({submitAgainHover: false})}}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: `${this.props.columnWidth * 0.205}vw`
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: `auto ${this.props.columnWidth *
                        0.0505}vw auto`,
                      gridTemplateRows: `auto`
                    }}
                  >
                    <SlidingText
                      in={this.props.in}
                      isRoute={this.props.isRoute}
                      style={{
                        color: this.state.submitAgainHover ? "#000000" : "#A6A6A6",
                        fontFamily: "Aktiv Grotesk W01 Medium",
                        fontSize: `${responsiveFontSize(20)}vw`,
                        fontWeight: "lighter",
                        lineHeight: `${responsiveFontSize(24)}vw`,
                        textAlign: "left"
                      }}
                      wrapStyle={{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        gridRowStart: 1,
                        gridRowEnd: 2
                      }}
                    >
                      Submit again
                    </SlidingText>

                    <div
                      style={{
                        position: "relative",
                        gridColumnStart: 3,
                        gridColumnEnd: 4,
                        gridRowStart: 1,
                        gridRowEnd: 2
                      }}
                    >
                      <AnimatedLine
                        in={this.props.in}
                        isRoute={this.props.isRoute}
                        style={LineAnimationStyle.left}
                        top={"50%"}
                        left={0}
                        width={`${0.1279 * this.props.columnWidth}vw`}
                        height={`${0.038 * this.props.columnWidth}vw`}
                        wrapStyle={{
                          transform: "translateY(-50%)"
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            width: `${0.1279 * this.props.columnWidth}vw`,
                            height: `${0.038 * this.props.columnWidth}vw`,
                            top: 0,
                            left: 0,
                            transform: "translate(0, -50%) !important"
                          }}
                          src={require("assets/longArrowGray.svg")}
                        />
                      </AnimatedLine>
                    </div>
                  </div>
                </button>
              </Transition>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
