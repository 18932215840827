import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { SlidingText, SlidingTransitionType } from "components/SlidingText";
import {
  pageAnimation,
  opacityRouteAnimation
} from "animations/GlobalAnimations";
import { ArrowBlock } from "components/ArrowButtons";
import { mod } from "../../utils";
import { responsiveFontSize } from "../../Layouts";
import { UkraineBanner } from "../Ukraine"
import { CSSTransition } from "react-transition-group"

let HomeHeadlines = [
  ["Making the future of", "technology beautiful"],
  ["Breathing life", "into smart machine", "technology & interfaces"],
  ["Emotional interfaces", "for intelligent products"]
];

const FullPageArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${props =>
    props.windowHeight ? `${props.windowHeight}px` : "100vh"};
  width: ${props => (props.windowWidth ? `${props.windowWidth}px` : "100vw")};
  
`;

class TextGroup extends Component {
  getFontStyles(lastLine) {
    return {
      color: "white",
      fontWeight: "lighter",
      fontFamily: !lastLine ? "AktivGrotesk-Thin" : "Aktiv Grotesk W01 Medium",
      fontSize: `${responsiveFontSize(60, 31)}vw`,
      lineHeight: `${responsiveFontSize(78, 40.3)}vw`,
      textAlign: "left",
      letterSpacing: `${this.props.columnWidth * (2 / 297)}vw`
    };
  }
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: this.props.left,
          top: this.props.top
        }}
      >
        {this.props.lines.map((e, i) => (
          <SlidingText
            key={e}
            in={this.props.in}
            isRoute={false}
            isTransition={this.props.isTransition}
            transitionType={SlidingTransitionType.OPACITY}
            wrapStyle={{
              position: "relative",
              left: 0
            }}
            style={this.getFontStyles(i === this.props.lines.length - 1)}
          >
            {e}
          </SlidingText>
        ))}
      </div>
    );
  }
}

export default class TextSlideshowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headlineIdx: 0,
      isTransition: true
    };
    this.container = React.createRef();
    this.leftArrow = React.createRef();
    this.rightArrow = React.createRef();
  }

  componentDidMount() {
    if (this.props.in) {
      this.animateRouteIn();
    }
    if (!this.props.in) {
      this.container.current.style.top = "-100vh";
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.in !== this.props.in) {
      if (this.props.page === prevProps.page) {
        // || (!this.props.in && prevProps.location.pathname === "/" && this.props.page === 0)) {
        this.props.in ? this.animateRouteIn() : this.animateRouteOut();
      } else {
        this.props.in ? this.animatePageUp() : this.animatePageDown();
      }
    }
  }

  animatePageDown() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    pageAnimation(this.container.current, false, true, () => {
      let _ = this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    });
  }

  animatePageUp() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    pageAnimation(this.container.current, true, true, () => {
      let _ = this.props.onEndAnimation ? this.props.onEndAnimation() : null;
    });
  }

  animateRouteIn() {
    if (this.leftArrow.current && this.rightArrow.current) {
      opacityRouteAnimation(this.leftArrow.current, true, 0.23, 0.6);
      opacityRouteAnimation(this.rightArrow.current, true, 0.23, 0.6);
    }
  }

  animateRouteOut() {
    if (this.leftArrow.current && this.rightArrow.current) {
      opacityRouteAnimation(this.leftArrow.current, false);
      opacityRouteAnimation(this.rightArrow.current, false);
    }
  }

  getArrowGroupCenter() {
    return this.props.nColumnDividors === 6
      ? this.props.nColumnDividors - 2
      : this.props.nColumnDividors - 2;
  }

  nextText() {
    this.setState({
      headlineIdx: mod(this.state.headlineIdx + 1, HomeHeadlines.length),
      isRoute: false,
      isTransition: true
    });
  }

  prevText() {
    this.setState({
      headlineIdx: mod(this.state.headlineIdx - 1, HomeHeadlines.length),
      isRoute: false,
      isTransition: true
    });
  }

  render() {
    return (
      <FullPageArea
        ref={this.container}
        windowHeight={this.props.windowHeight}
        windowWidth={this.props.windowWidth}
      >
        {/* <CSSTransition
          in={this.props.in}
          timeout={300}
          unmountOnExit
          classNames="fade"
        >
          <UkraineBanner
            onOpenUkraine={this.props.onOpenUkraine}
            top={this.props.headerMode === "mobile" ? undefined : `${this.props.logoWidth * 0.44}vw`}
            bottom={this.props.headerMode === "mobile" ? 0 : undefined}
            left={this.props.headerMode === "mobile" ? "0px" : `${ this.props.leftMargin}vw`}
            width={this.props.headerMode === "mobile" ? "100%" : `${5 * this.props.columnWidth}vw`}
            headerMode={this.props.headerMode}
          />
        </CSSTransition> */}
        {HomeHeadlines.map((e, i) => (
          <TextGroup
            {...this.props}
            left={`${this.props.leftMargin}vw`}
            top={`${this.props.columnWidth * 1.5}vw`}
            in={this.props.in && i === this.state.headlineIdx}
            key={e[0]}
            isRoute={this.props.isRoute}
            isTransition={false}
            lines={e}
          />
        ))}

        {this.props.nColumnDividors > 3 && (
          <Fragment>
            <ArrowBlock
              {...this.props}
              left={true}
              aref={this.leftArrow}
              positionStyle={{
                left: `${this.props.leftMargin +
                  (this.getArrowGroupCenter() - 0.25) *
                    this.props.columnWidth}vw`,
                top: `${this.props.columnWidth * 1.0}vw`
              }}
              onClick={() => {
                if (this.props.onLeftArrow) {
                  this.props.onLeftArrow();
                }
              }}
            />
            <ArrowBlock
              {...this.props}
              aref={this.rightArrow}
              right={true}
              positionStyle={{
                left: `${this.props.leftMargin +
                  this.getArrowGroupCenter() * this.props.columnWidth}vw`,
                top: `${this.props.columnWidth * 1.0}vw`
              }}
              onClick={() => {
                if (this.props.onRightArrow) {
                  this.props.onRightArrow();
                }
              }}
            />
          </Fragment>
        )}
      </FullPageArea>
    );
  }
}
