import React, { Component } from "react";
import { responsiveFontSize } from "../Layouts";

export default class SectionTitle extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "left",
          verticalAlign: "top",
          ...this.props.style
        }}
      >
        <p
          style={{
            fontFamily: "Aktiv Grotesk W01 Light1370681",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: `${responsiveFontSize(22, 13)}vw`,
            lineHeight: `${responsiveFontSize(25, 15)}vw`,
            color: "#000000"
          }}
        >
          {this.props.firstLine}
        </p>
        <p
          style={{
            fontFamily: "AktivGrotesk-Thin",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: `${responsiveFontSize(55, 26)}vw`,
            lineHeight: `${responsiveFontSize(62, 30)}vw`,
            color: "#000000",
            marginTop: (this.props.columnWidth * (22 / 297)).toString() + "vw"
          }}
        >
          {this.props.secondLine}
        </p>
      </div>
    );
  }
}
