import React, { Component, Fragment } from "react";
import { FullPageArea } from "components/ColumnGrid";
import { Transition } from "react-transition-group";
import { SlidingAppearBlock } from "components/SlidingAppearImage";
import {
  SlidingText,
  DescriptionText,
  AboutTextDecoration
} from "components/SlidingText";
import { mod } from "../utils";
import { TweenMax } from "gsap";
import CustomEase from "../CustomEase";
import {
  FOVShaderImage,
  FOVTransitionType
} from "components/FOVShaderImageSlideshow";
import { ScrollDirection } from "components/ScrollWrapper";
import { responsiveFontSize } from "../Layouts";
import { ColumnGrid } from "components/ColumnGrid";
import {
  getResponsiveDescriptionSizeInfo,
  getResponsiveImageSizeInfo,
  getResponsiveTopMargin,
  getResponsiveArrowSizeInfo
} from "components/AboutSizeHelpers";
import {
  opacityRouteAnimation,
  pageAnimation
} from "../animations/GlobalAnimations";
import styled, { keyframes } from "styled-components";
import { isMobile } from "react-device-detect";
import Footer from "../components/Footer";
import { LoaderLine } from "./Loader";

// Array of { type, asset, blobURL }
let AboutCache = null;
let AboutCMSData = null;

export let BackupAboutData = [
  {
    images: [
      { type: "img", src: require("assets/art1.jpg") },
      { type: "img", src: require("assets/art2.jpg") },
      { type: "img", src: require("assets/art3.jpg") },
    ],
    title: "Milkinside pulses with the energy of Silicon Valley.",
    description: [
      "Based in San Francisco, California, we work at the intersection of strategy and art, partnering with companies that span industries and time zones.  From automotive to agriculture, from Tokyo to Tel Aviv, we are the tiger team inside your team.",
      "More than two dozen dreamers, designers, strategists and technologists contribute to the products and experiences we create.  The breadth of our experience and constantly varied work strengthens and informs every project.",
      "We pride ourselves on deeply understanding your business needs, so that we can bring human insights to the organizing your product offering. We limit the number of projects we work on to bring our best, most creative thinking to every problem."
    ],
    topLine: "Designing",
    bottomLine: "tomorrow today"
  },
  {
    images: [
      { type: "img", src: require("assets/art5.png") },
      { type: "img", src: require("assets/art6.png") },
      { type: "img", src: require("assets/art7.png") },
      { type: "img", src: require("assets/art8.png") }
    ],
    title: "Design is the difference maker.",
    description: [
      "Humans are a storytelling species, and storytelling is the core of Milkinside experience design.  We aim to make each user the protagonist in their own journey, because we know a product is defined by the way users feel about their experience and how it fits into their life story.",
      "Design is what transforms a technology into a product.",
      "We've crafted stories and experiences with medical practitioners and crop specialists, beauty shoppers and machine learning engineers.",
      "We help companies develop experiences and platforms that generate millions in revenue. Our design philosophy and approach to design systems have created entirely new consumer experiences for Seres EV, Renault, Mitsubishi, Rokid, Airbus, and many more."
    ],
    topLine: "Experts",
    bottomLine: "in Storytelling"
  }
];

const LinearGradOverlay = styled.div`
  position: relative;
  margin-top: ${props => props.columnWidth * 0.44 * 0.29}vw;

  &::after {
    visibility: ${props => (props.disabled ? "hidden" : "visible")}
    position: absolute;
    top: 0;
    height: ${props => props.gradientHeight}px;
    width: 100%;
    content: "";
    background: linear-gradient(to top,rgb(0,0,0) 3%,rgba(0,0,0,0.4) 80%);
    pointer-events: none;
  }
`;

class AboutSectionText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayHeight: 100,
      expanded: true
    };

    this.container = React.createRef();
    this.descriptionTextContainer = React.createRef();
  }

  componentDidMount() {
    this.setState({
      overlayHeight:
        this.props.windowHeight -
        this.descriptionTextContainer.current.getBoundingClientRect().top,
      expanded: false
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.nColumnDividors < 5 &&
      prevProps.windowHeight !== this.props.windowHeight
    ) {
      this.setState({
        overlayHeight:
          this.props.windowHeight -
          this.descriptionTextContainer.current.getBoundingClientRect().top
      });
    }
  }

  getExpandedDelta() {
    let currentYPosition =
      ((this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveDescriptionSizeInfo(this.props).topMargin) /
        100) *
      this.props.windowWidth;

    let deltaPx =
      currentYPosition + this.getTextScrollHeight() - this.props.windowHeight;

    let deltaVw = (deltaPx / this.props.windowWidth) * 100;
    return deltaVw;
  }

  getTextScrollHeight() {
    return this.container.current.scrollHeight;
  }

  restoreTextPosition() {
    let currentTop =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveDescriptionSizeInfo(this.props).topMargin;
    this.container.current.style.top = `${currentTop}vw`;
  }

  animateShowFull() {
    this.setState({
      expanded: true
    });
    let currentTop =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveDescriptionSizeInfo(this.props).topMargin;

    TweenMax.fromTo(
      this.container.current,
      0.5,
      {
        top: `${currentTop}vw`
      },
      {
        top: `${currentTop - this.getExpandedDelta()}vw`,
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );
  }

  animateHide() {
    let currentTop =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveDescriptionSizeInfo(this.props).topMargin;

    TweenMax.fromTo(
      this.container.current,
      0.5,
      {
        top: `${currentTop - this.getExpandedDelta()}vw`
      },
      {
        top: `${currentTop}vw`,
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00"),
        onComplete: () => {
          this.setState({
            expanded: false
          });
        }
      }
    );
  }

  render() {
    return (
      <div
        ref={this.container}
        style={{ position: "absolute", top: 0, left: 0, ...this.props.style }}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: `${this.props.nColumnDividors >= 5 ? `50%` : 0}`,
              transform:
                this.props.nColumnDividors >= 5 ? "translate(0, -50%)" : ""
            }}
          >
            <SlidingText
              {...this.props}
              in={this.props.in}
              disableExitAnimation={this.props.disableExitAnimation}
              unmountOnExit={false}
              isRoute={this.props.isRoute}
              isTransition={this.props.isTransition}
              transitionDelay={this.props.nColumnDividors >= 5 ? 0.2 : 0.76}
              key={this.props.title}
              wrapStyle={{
                position: "relative",
                top: 0,
                left: 0
              }}
              style={{
                color: "white",
                fontFamily:
                  this.props.nColumnDividors < 5
                    ? "Aktiv Grotesk W01 Light1370681"
                    : "Aktiv Grotesk W01 Regular",
                fontWeight: "lighter",
                fontSize: `${responsiveFontSize(
                  38,
                  this.props.nColumnDividors === 5 ? 18 : 23
                )}vw`,
                lineHeight: `${responsiveFontSize(
                  42,
                  this.props.nColumnDividors === 5 ? 22 : 28
                )}vw`,
                letterSpacing: `${this.props.columnWidth *
                  (this.props.nColumnDividors < 5 ? 0.325 / 180 : 0)}vw`,
                textAlign: "left"
              }}
            >
              {this.props.title}
            </SlidingText>
            <LinearGradOverlay
              ref={this.descriptionTextContainer}
              columnWidth={this.props.columnWidth}
              gradientHeight={this.state.overlayHeight}
              disabled={
                this.state.expanded ||
                this.props.nColumnDividors > 4 ||
                !this.props.in
              }
            >
              {this.props.descriptionArr.map((e, i) => (
                <DescriptionText
                  {...this.props}
                  in={this.props.in}
                  disableExitAnimation={this.props.disableExitAnimation}
                  downAnimation={this.props.direction === ScrollDirection.down}
                  unmountOnExit={false}
                  isRoute={this.props.isRoute}
                  isTransition={this.props.isTransition}
                  transitionDelay={this.props.nColumnDividors >= 5 ? 0.2 : 0.76}
                  key={e}
                  style={{
                    left: 0,
                    width: "100%",
                    color: "rgba(255,255,255,0.9)",
                    letterSpacing: "0.3px",
                    fontFamily: "Neue Haas Grotesk Disp W01_55R",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: `${responsiveFontSize(
                      23,
                      this.props.nColumnDividors === 5 ? 12 : 14
                    )}vw`,
                    lineHeight: `${responsiveFontSize(
                      32,
                      this.props.nColumnDividors === 5 ? 15 : 18
                    )}vw`,
                    textAlign: "left",
                    position: "relative"
                  }}
                >
                  {e}
                </DescriptionText>
              ))}
            </LinearGradOverlay>
            {this.props.recentPartners && (
              <Fragment>
                <p
                  ref={this.props.recentPartnerTextRef}
                  style={{
                    position: "relative",
                    marginTop: `${responsiveFontSize(
                      60,
                      this.props.nColumnDividors === 5 ? 22 : 28
                    )}vw`,

                    left: 0,
                    fontFamily: "Aktiv Grotesk W01 Medium",
                    fontStyle: "normal",
                    fontWeight: "lighter",
                    lineHeight: `${responsiveFontSize(
                      18,
                      this.props.nColumnDividors > 4 ? 11 : 13
                    )}vw`,
                    fontSize: `${responsiveFontSize(
                      15,
                      this.props.nColumnDividors > 4 ? 8 : 10
                    )}vw`,
                    letterSpacing: "0.1em",
                    color: "rgb(54, 53, 54)"
                  }}
                >
                  RECENT PARTNERS
                </p>
                <img
                  ref={this.props.recentPartnerLogoRef}
                  style={{
                    position: "relative",
                    left: 0,
                    marginTop: `${responsiveFontSize(
                      16,
                      this.props.nColumnDividors === 5 ? 15 : 18
                    )}vw`,
                    height: `${responsiveFontSize(
                      30,
                      this.props.nColumnDividors > 4 ? 11 : 13
                    )}vw`
                  }}
                  src={require("../assets/recent_partners.png")}
                />
              </Fragment>
            )}
            {this.props.awards && (
              <Fragment>
                <p
                  ref={this.props.awardsTextRef}
                  style={{
                    position: "relative",
                    marginTop: `${responsiveFontSize(
                      60,
                      this.props.nColumnDividors === 5 ? 22 : 28
                    )}vw`,

                    left: 0,
                    fontFamily: "Aktiv Grotesk W01 Medium",
                    fontStyle: "normal",
                    fontWeight: "lighter",
                    lineHeight: `${responsiveFontSize(
                      18,
                      this.props.nColumnDividors > 4 ? 11 : 13
                    )}vw`,
                    fontSize: `${responsiveFontSize(
                      15,
                      this.props.nColumnDividors > 4 ? 8 : 10
                    )}vw`,
                    letterSpacing: "0.1em",
                    color: "rgb(54, 53, 54)"
                  }}
                >
                  AWARDS
                </p>
                <div
                  ref={this.props.awardsLogosRef}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, auto)",
                    gridColumnGap: `${0.15 * this.props.columnWidth}vw`,
                    position: "relative",
                    left: 0,
                    marginTop: `${responsiveFontSize(
                      16,
                      this.props.nColumnDividors === 5 ? 15 : 18
                    )}vw`,
                    width: "100%",
                    height: `${responsiveFontSize(
                      30,
                      this.props.nColumnDividors > 4 ? 11 : 13
                    )}vw`
                  }}
                >
                  <img
                    title={"FWA of the day 2019"}
                    alt={"FWA of the day 2019"}
                    src={require("../assets/fwa.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"2x times Cannes Lion 2017"}
                    alt={"2x times Cannes Lion 2017"}
                    src={require("../assets/canneslion.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"IF design awards"}
                    alt={"IF design awards"}
                    src={require("../assets/if.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"Red Dot awards for best branding 2019"}
                    alt={"Red Dot awards for best branding 2019"}
                    src={require("../assets/reddot.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"Webby design awards 2018"}
                    alt={"Webby design awards 2018"}
                    src={require("../assets/webby.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"German Design Awards"}
                    alt={"German Design Awards"}
                    src={require("../assets/german.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                  <img
                    title={"MUX design awards - best of the best"}
                    alt={"MUX design awards - best of the best"}
                    src={require("../assets/mux.png")}
                    style={{
                      position: "relative",
                      height: `${responsiveFontSize(
                        30,
                        this.props.nColumnDividors > 4 ? 11 : 13
                      )}vw`,
                      top: "50%",
                      transform: "translate(0, -50%)"
                    }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class DownArrowBlock extends Component {
  render() {
    return (
      <div
        ref={this.props.aref}
        style={{
          position: "absolute",
          height: `${this.props.blockWidth}vw`,
          width: `${this.props.blockWidth}vw`,
          ...this.props.style
        }}
      >
        <button
          onClick={this.props.onClick}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            background: "none",
            border: "none",
            outline: "none",
            padding: 0
          }}
        >
          <img
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: `${this.props.arrowWidth}vw`,
              height: `${this.props.arrowWidth / 3}vw`,
              pointerEvents: "none"
            }}
            src={require("assets/DownArrow.svg")}
          />
        </button>
      </div>
    );
  }
}

class AboutArrowBlock extends Component {
  render() {
    return (
      <div
        ref={this.props.aref}
        style={{
          position: "absolute",
          height: `${this.props.blockWidth}vw`,
          width: `${this.props.blockWidth}vw`,
          background: this.props.colored ? this.props.colored : null,
          ...this.props.style
        }}
      >
        <button
          onClick={this.props.onClick}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            background: "none",
            border: "none",
            outline: "none",
            padding: 0
          }}
        >
          <img
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: `${this.props.arrowAspect * this.props.blockWidth}vw`,
              height: `${this.props.arrowAspect * this.props.blockWidth * 2}vw`,
              pointerEvents: "none"
            }}
            src={
              this.props.left
                ? require("assets/LeftArrow.svg")
                : require("assets/RightArrow.svg")
            }
          />
        </button>
      </div>
    );
  }
}

const FOVPlaceholderImageStyle = `
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.leftArrow = React.createRef();
    this.rightArrow = React.createRef();
    this.container = React.createRef();
    this.textSection = React.createRef();
    this.textDecoration = React.createRef();
    this.placeholderImg = React.createRef();
    this.scrollIndicator = React.createRef();
    this.seeMoreArrow = React.createRef();
    this.recentPartnerText = React.createRef();
    this.recentPartnerLogos = React.createRef();
    this.awardsText = React.createRef();
    this.awardsLogos = React.createRef();
    this.loaderline = React.createRef();

    this.blockEndAnimation = this.blockEndAnimation.bind(this);
    this.isAppear = props.isAppear;

    this.state = {
      currentImage: props.currentImage,
      isImgDisplayed: true,
      loaderBackground: !props.fovLoaded
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.in !== prevProps.in) {
      if (this.props.in && !this.props.fovLoaded) {
        if (this.loaderline.current) {
          this.loaderline.current.animation.play();
        }
      }
    }
    if (prevState.currentImage !== this.state.currentImage) {
      if (!this.state.currentImage) {
        this.loaderline.current.animation.play();
      }
    }
  }
  componentDidMount() {
    if (this.loaderline.current) {
      this.loaderline.current.animation.play();
    }
  }

  blockEndAnimation() {
    this.setState({ loaderBackground: !this.props.fovLoaded});
    if (this.props.onEndAnimation) {
      this.props.onEndAnimation();
    }
  }

  showStaticImg() {
    this.setState({
      isImgDisplayed: true
    });
  }

  hideStaticImg() {
    this.setState({
      isImgDisplayed: false
    });
  }

  animateLeave() {
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    this.textSection.current.setState({
      expanded: true
    });

    this.isAnimating = true;

    if (this.props.nColumnDividors > 4) {
      pageAnimation(
        this.container.current,
        false,
        this.props.direction === ScrollDirection.up ? true : false,
        () => {
          this.container.current.style.display = "none";
          this.restoreAppearance();
        }
      );
    }

    if (this.props.nColumnDividors < 5) {
      let newTop = `${this.props.direction === ScrollDirection.up ? "-" : ""}${
        this.container.current.scrollHeight
      }vw`;

      TweenMax.fromTo(
        this.container.current,
        this.props.direction === ScrollDirection.up ? 1.4 : 1.4,
        {
          top: "0vh"
        },
        {
          top: newTop,
          delay: 0,
          onComplete: () => {
            this.container.current.style.display = "none";
            this.restoreAppearance();
          },
          ease: CustomEase.create(
            "custom",
            this.props.direction === ScrollDirection.down
              ? "0.96, 0, 0.41, 1"
              : "0.96, 0, 0.41, 1"
          )
        }
      );
    }

    opacityRouteAnimation(this.seeMoreArrow.current, false, 0.233, 0);
    opacityRouteAnimation(this.leftArrow.current, false, 0.233, 0);
    opacityRouteAnimation(this.rightArrow.current, false, 0.233, 0);
    if (this.recentPartnerText.current) {
      opacityRouteAnimation(this.recentPartnerText.current, false, 0.233, 0);
      opacityRouteAnimation(this.recentPartnerLogos.current, false, 0.233, 0);
    }
    if (this.awardsText.current) {
      opacityRouteAnimation(this.awardsText.current, false, 0.233, 0);
      opacityRouteAnimation(this.awardsLogos.current, false, 0.233, 0);
    }
  }

  animateEnter() {
    this.setState({loaderBackground: false});
    if (this.props.onStartAnimation) {
      this.props.onStartAnimation();
    }
    this.isAnimating = true;

    if (this.props.nColumnDividors > 4 && !this.isAppear) {
      pageAnimation(
        this.container.current,
        true,
        this.props.direction === ScrollDirection.up ? false : true,
        () => {}
      );
    }

    if (this.props.nColumnDividors < 5) {
      this.textSection.current.setState({ expanded: true });

      setTimeout(() => {
        this.textSection.current.setState({ expanded: false });
      }, 1000);
    }

    // if (this.props.nColumnDividors < 5) {
    let delay = this.props.nColumnDividors >= 5 ? 0.4 : 0.833;
    opacityRouteAnimation(this.seeMoreArrow.current, true, delay, 0.6);
    opacityRouteAnimation(this.leftArrow.current, true, delay, 0.6);
    opacityRouteAnimation(this.rightArrow.current, true, delay, 0.6);
    if (this.recentPartnerText.current) {
      opacityRouteAnimation(this.recentPartnerText.current, true, delay, 0.6);
      opacityRouteAnimation(this.recentPartnerLogos.current, true, delay, 0.6);
    }
    if (this.awardsText.current) {
      opacityRouteAnimation(this.awardsText.current, true, delay, 0.6);
      opacityRouteAnimation(this.awardsLogos.current, true, delay, 0.6);
    }
    // }
  }

  animateTextMode() {
    this.isAnimating = true;
    let delta = this.textSection.current.getExpandedDelta();
    this.textDecoration.current.animateOut();

    let maxDelta =
      getResponsiveImageSizeInfo(this.props).imageWidth *
        getResponsiveImageSizeInfo(this.props).imageAspectRatio -
      getResponsiveArrowSizeInfo(this.props).blockWidth;
    let arrowDelta = Math.min(delta, maxDelta);

    TweenMax.fromTo(
      this.seeMoreArrow.current,
      0.5,
      {
        top: `${getResponsiveDescriptionSizeInfo(this.props).topMargin}vw`,
        transform: "translate(0, -35%) rotate(180deg)"
      },
      {
        top: `${getResponsiveDescriptionSizeInfo(this.props).topMargin -
          delta}vw`,
        transform: "translate(0, -35%) rotate(0deg)",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.seeMoreArrow.current,
      1.3,
      {
        transform: "translate(0, -35%) rotate(180deg)"
      },
      {
        transform: "translate(0, -35%) rotate(0deg)",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.scrollIndicator.current,
      0.6,
      {
        opacity: 0
      },
      {
        opacity: 1,
        delay: 0.4,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.textDecoration.current.container.current,
      0.5,
      {
        top: "0vw"
      },
      {
        top: `${-delta}vw`,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.rightArrow.current,
      0.5,
      {
        bottom: "0vw"
      },
      {
        bottom: `${delta}vw`,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.leftArrow.current,
      0.5,
      {
        bottom: `${getResponsiveArrowSizeInfo(this.props).blockWidth}vw`
      },
      {
        bottom: `${getResponsiveArrowSizeInfo(this.props).blockWidth +
          delta}vw`,
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    this.textSection.current.animateShowFull();

    TweenMax.fromTo(
      this.placeholderImg.current,
      0.5,
      {
        top: `0vw`
      },
      {
        top: `${-delta}vw`,
        delay: 0,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );
  }

  restoreImageMode() {
    let delta = this.textSection.current.getExpandedDelta();
    this.textDecoration.current.animateIn();

    let maxDelta =
      getResponsiveImageSizeInfo(this.props).imageWidth *
        getResponsiveImageSizeInfo(this.props).imageAspectRatio -
      getResponsiveArrowSizeInfo(this.props).blockWidth;
    let arrowDelta = Math.min(delta, maxDelta);

    TweenMax.fromTo(
      this.seeMoreArrow.current,
      0.5,
      {
        top: `${getResponsiveDescriptionSizeInfo(this.props).topMargin -
          delta}vw`,
        transform: "translate(0, -35%) rotate(0deg)"
      },
      {
        top: `${getResponsiveDescriptionSizeInfo(this.props).topMargin}vw`,
        transform: "translate(0, -35%) rotate(180deg)",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.seeMoreArrow.current,
      1.3,
      {
        transform: "translate(0, -35%) rotate(0deg)"
      },
      {
        transform: "translate(0, -35%) rotate(180deg)",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.scrollIndicator.current,
      0.3,
      {
        opacity: 1
      },
      {
        opacity: 0,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.textDecoration.current.container.current,
      0.5,
      {
        top: `${-delta}vw`
      },
      {
        top: "0vw",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.rightArrow.current,
      0.5,
      {
        bottom: `${delta}vw`
      },
      {
        bottom: "0vw",
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    TweenMax.fromTo(
      this.leftArrow.current,
      0.5,
      {
        bottom: `${getResponsiveArrowSizeInfo(this.props).blockWidth + delta}vw`
      },
      {
        bottom: `${getResponsiveArrowSizeInfo(this.props).blockWidth}vw`,
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );

    this.textSection.current.animateHide();

    TweenMax.fromTo(
      this.placeholderImg.current,
      0.5,
      {
        top: `${-delta}vw`
      },
      {
        top: `0vw`,
        delay: 0,
        onComplete: () => {},
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );
  }

  restoreAppearance() {
    this.placeholderImg.current.style.top = "0vw";
    this.leftArrow.current.style.bottom = `${
      getResponsiveArrowSizeInfo(this.props).blockWidth
    }vw`;
    this.rightArrow.current.style.bottom = "0vw";
    this.textSection.current.restoreTextPosition();
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        unmountOnExit={true}
        mountOnEnter={true}
        appear={this.props.isAppear}
        timeout={1400}
        onEntered={(_, isAppear) => {
          this.isAppear = false;
          if (this.props.onMounted) {
            this.props.onMounted();
          }
        }}
        addEndListener={() => {
          this.props.in ? this.animateEnter() : this.animateLeave();
        }}
      >
        <FullPageArea
          ref={this.container}
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
          style={{
            overflow: "hidden"
          }}
        >
          <div
            style={{
              position: "absolute",
              left: this.props.leftStyle
                ? `${getResponsiveImageSizeInfo(this.props).imageLeftMargin}vw`
                : null,
              right: !this.props.leftStyle
                ? `${getResponsiveImageSizeInfo(this.props).imageLeftMargin}vw`
                : null,
              top: `${
                this.props.nColumnDividors >= 5
                  ? getResponsiveTopMargin(this.props)
                  : getResponsiveImageSizeInfo(this.props).imageTopMargin
              }vw`,
              height: `${getResponsiveImageSizeInfo(this.props).imageWidth *
                getResponsiveImageSizeInfo(this.props).imageAspectRatio}vw`,
              width: `${getResponsiveImageSizeInfo(this.props).imageWidth}vw`
            }}
          >
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                // background: this.props.fovLoaded ? "" : "black"
              }}
            >
              <SlidingAppearBlock
                aref={this.placeholderImg}
                disableExitAnimation={true} //this.props.nColumnDividors < 5}
                in={this.props.in}
                isRoute={this.props.isRoute}
                isTransition={this.props.isTransition}
                isAppear={true} //this.props.fovLoaded}
                top={0}
                left={0}
                height={`${getResponsiveImageSizeInfo(this.props).imageWidth *
                  getResponsiveImageSizeInfo(this.props).imageAspectRatio}vw`}
                width={`${getResponsiveImageSizeInfo(this.props).imageWidth}vw`}
                onEndAnimation={this.blockEndAnimation}
              >
                {this.state.currentImage &&
                  this.state.currentImage.type === "img" && (
                    <div
                      style={{
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: `url('${this.state.currentImage.blobURL}')`,
                        visibility: this.state.isImgDisplayed
                          ? "visible"
                          : "hidden",
                        position: "relative",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                  )}
                {this.state.currentImage &&
                  this.state.currentImage.type === "video" && (
                    <video
                      src={this.state.currentImage.blobURL}
                      style={{
                        position: "relative",
                        width: "100%",
                        visibility: this.state.isImgDisplayed
                          ? "visible"
                          : "hidden",
                        height: "100%",
                        objectFit: "cover"
                      }}
                    />
                  )}
                {!this.state.currentImage && (
                  <div style={{ background: "black", position: "relative", width: "100%", height: "100%"}}>
                  <LoaderLine
                    ref={this.loaderline}
                    left="50%"
                    top="50%"
                    width={`${0.5006 * this.props.columnWidth}vw`}
                    height={`${0.009734 * this.props.columnWidth}vw`}
                    wrapStyle={{
                      transform: "translate(-50%, -50%)"
                    }}
                  />
                  </div>
                )}
              </SlidingAppearBlock>

              <div
                style={{
                  position: "absolute",
                  top:
                    (this.props.leftStyle &&
                      this.props.nColumnDividors === 6) ||
                    (this.props.nColumnDividors < 5 && !this.props.leftStyle)
                      ? `${(60 / 297) * this.props.columnWidth}vw`
                      : 0,
                  width: `${
                    getResponsiveArrowSizeInfo(this.props).blockWidth
                  }vw`,
                  height: `${getResponsiveImageSizeInfo(this.props).imageWidth *
                    getResponsiveImageSizeInfo(this.props).imageAspectRatio}vw`,
                  left: this.props.leftStyle ? "100%" : null,
                  right: this.props.leftStyle ? null : "100%",
                  pointerEvents: "none"
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left:
                      this.props.nColumnDividors === 6
                        ? `${this.props.leftStyle ? "100%" : "0%"}`
                        : `${this.props.leftStyle ? "50%" : "-50%"}`
                  }}
                >
                  <AboutTextDecoration
                    ref={this.textDecoration}
                    {...this.props}
                    disableExitAnimation={true} //this.props.nColumnDividors < 5}
                    in={this.props.in}
                    isRoute={this.props.isRoute}
                    isTransition={this.props.isTransition}
                    key={this.props.topLine}
                    leftRotate={!this.props.leftStyle ? true : false}
                    centered={this.props.nColumnDividors < 6 ? true : false}
                    top={0}
                    topLine={this.props.topLine.toUpperCase()}
                    bottomLine={this.props.bottomLine.toUpperCase()}
                  />
                </div>
              </div>
            </div>
            <AboutArrowBlock
              aref={this.leftArrow}
              left
              onClick={() => {
                if (this.props.onLeftArrowClick) {
                  this.props.onLeftArrowClick();
                }
              }}
              colored={this.props.nColumnDividors < 6 ? "black" : null}
              blockWidth={getResponsiveArrowSizeInfo(this.props).blockWidth}
              arrowAspect={getResponsiveArrowSizeInfo(this.props).arrowAspect}
              style={{
                right:
                  this.props.nColumnDividors < 6
                    ? this.props.leftStyle
                      ? null
                      : "100%"
                    : "100%",
                left:
                  this.props.nColumnDividors < 6 && this.props.leftStyle
                    ? "100%"
                    : null,
                bottom:
                  this.props.nColumnDividors < 6
                    ? `${getResponsiveArrowSizeInfo(this.props).blockWidth}vw`
                    : 0
              }}
            />
            <AboutArrowBlock
              aref={this.rightArrow}
              onClick={() => {
                if (this.props.onRightArrowClick) {
                  this.props.onRightArrowClick();
                }
              }}
              colored={this.props.nColumnDividors < 6 ? "#005AE1" : null}
              blockWidth={getResponsiveArrowSizeInfo(this.props).blockWidth}
              arrowAspect={getResponsiveArrowSizeInfo(this.props).arrowAspect}
              style={{
                left:
                  this.props.nColumnDividors === 6
                    ? "100%"
                    : this.props.leftStyle
                    ? "100%"
                    : null,
                right:
                  this.props.nColumnDividors < 6 && !this.props.leftStyle
                    ? "100%"
                    : null,
                bottom: 0
              }}
            />
          </div>

          <AboutSectionText
            ref={this.textSection}
            recentPartnerTextRef={this.recentPartnerText}
            recentPartnerLogoRef={this.recentPartnerLogos}
            awardsTextRef={this.awardsText}
            awardsLogosRef={this.awardsLogos}
            {...this.props}
            recentPartners={
              this.props.recentPartners && this.props.nColumnDividors >= 5
            }
            disableExitAnimation={true} //this.props.nColumnDividors < 5}
            in={this.props.in}
            isRoute={this.props.isRoute}
            isTransition={this.props.isTransition}
            title={this.props.title}
            descriptionArr={this.props.description}
            style={{
              top: `${
                this.props.nColumnDividors >= 5
                  ? getResponsiveTopMargin(this.props)
                  : getResponsiveDescriptionSizeInfo(this.props).topMargin
              }vw`,
              width: `${
                getResponsiveDescriptionSizeInfo(this.props).textWidth
              }vw`,
              left: `${
                getResponsiveDescriptionSizeInfo(this.props).textLeftMargin
              }vw`,
              height:
                this.props.nColumnDividors >= 5
                  ? `${getResponsiveImageSizeInfo(this.props).imageWidth *
                      getResponsiveImageSizeInfo(this.props)
                        .imageAspectRatio}vw`
                  : ""
            }}
          />

          <DownArrowBlock
            aref={this.seeMoreArrow}
            arrowWidth={0.111 * this.props.columnWidth}
            blockWidth={this.props.leftMargin * 2}
            style={{
              top: `${
                getResponsiveDescriptionSizeInfo(this.props).topMargin
              }vw`,
              transform: "translate(0, -35%) rotate(180deg)",
              right: 0,
              background: "",
              visibility: this.props.nColumnDividors > 4 ? "hidden" : "visible"
            }}
            // ref={}
            onClick={() => {}}
          />

          <ScrollIndicator
            ref={this.scrollIndicator}
            columnWidth={this.props.columnWidth}
            style={{
              bottom: 0,
              right: `${this.props.leftMargin}vw`,
              opacity: 0,
              visibility: this.props.nColumnDividors > 4 ? "hidden" : "visible"
            }}
          />
        </FullPageArea>
      </Transition>
    );
  }
}

const scrolllingAnimation = keyframes`
0% {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top center;
  transform-origin: top center
}

24.9% {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top center;
  transform-origin: top center
}

25% {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center
}

100%,50% {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center
}
`;

const ScrollIndicator = styled.span`
  position: absolute;

  height: ${props => (120 / 297) * props.columnWidth}vw;
  width: 1px;
  background: #6f6f6f59;

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: #ffffff7a;
    -webkit-animation: ${scrolllingAnimation} 2s linear infinite;
    -moz-animation: ${scrolllingAnimation} 2s linear infinite;
    -o-animation: ${scrolllingAnimation} 2s linear infinite;
    /* animation: ${scrolllingAnimation} 2s linear infinite; */
}


`;

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      direction: null,
      isRoute: props.history.action === "PUSH" ? true : false,
      isTransition: false,
      footer: false,
      fovLoaded: false,
      assets: null
    };

    this.handleLeftArrow = this.handleLeftArrow.bind(this);
    this.handleRightArrow = this.handleRightArrow.bind(this);
    this.pageRefs = [];

    this.pageFinishedTransition = this.pageFinishedTransition.bind(this);
    this.fovContainer = React.createRef();
    this.slider = React.createRef();
    this.isAnimating = false;
    this.mobileNavEnabled = null;
  }

  setupDataAssets() {
    this.pageRefs = [];
    this.props.data.forEach(_ => {
      this.pageRefs.push(React.createRef());
    });

    this.assets = null;

    this.sectionIdxMap = [];

    let isUpdatedData =
      !AboutCMSData ||
      (AboutCMSData &&
        JSON.stringify(this.props.data) !== JSON.stringify(AboutCMSData));
    this.assets =
      AboutCache && !isUpdatedData ? AboutCache.map(e => e.asset) : [];

    let imageCount = 0;
    this.props.data.forEach((e, i) => {
      let sectionIdxInfo = {
        offsetIdx: imageCount,
        n: e.images.length
      };
      imageCount += e.images.length;
      this.sectionIdxMap.push(sectionIdxInfo);

      if (isUpdatedData) {
        e.images.forEach(imgSrc => {
          let assetInfo = { ...imgSrc, forceEntireLoad: true };
          this.assets.push(assetInfo);
        });
      }
    });

    if (AboutCache && isUpdatedData) {
      AboutCache.forEach(e => {
        URL.revokeObjectURL(e.blobUrl);
      });
      AboutCache = null;
    }

    AboutCMSData = this.props.data;
    this.setState({ assets: this.assets });
  }

  componentDidMount() {
    if (this.isAnimating) {
      setTimeout(() => {
        this.isAnimating = false;
      }, 1200);
    }
    if (this.props.data) {
      this.setupDataAssets();
      this.hideTransitionFOV(this.state.page, null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let oldmobileNavEnabled = this.mobileNavEnabled;
    if (prevProps.windowWidth !== this.props.windowWidth) {
      this.setNavigationMode();
      this.slider.current.curtain.resize();
    }

    if (prevProps.in !== this.props.in) {
      this.setState({
        isRoute: true
      });
      if (this.isAnimating) {
        setTimeout(() => {
          this.isAnimating = false;
        }, 1200);
      }
    }

    if (this.mobileNavEnabled !== oldmobileNavEnabled) {
      this.setState({
        textMode: false
      });
    }

    if (prevState.assets !== this.state.assets) {
      if (this.state.asset && this.slider.current) {
        if (mod(this.state.page, 2) === 0) {
          this.slider.current.plane1.current.style.left = "0px";
          this.slider.current.plane2.current.style.left = "0px";
          this.slider.current.plane1.current.style.right = "";
          this.slider.current.plane2.current.style.right = "";
        } else {
          this.slider.current.plane1.current.style.left = "";
          this.slider.current.plane2.current.style.left = "";
          this.slider.current.plane1.current.style.right = "0px";
          this.slider.current.plane2.current.style.right = "0px";
        }
      }
    }

    if (prevProps.data !== this.props.data) {
      if (this.props.data) {
        this.setupDataAssets();
      }
    }
  }

  shouldBackgroundScroll(direction) {
    return false;
  }

  setNavigationMode() {
    if (!this.props.in) {
      return;
    }
    let nColumnDividors = this.props.nColumnDividors;
    let windowHeight = this.props.windowHeight;
    if (
      (windowHeight <
        (this.pageRefs[this.state.page].current ? this.pageRefs[this.state.page].current.container.current.scrollHeight : 0) ||
        this.state.footer) &&
      nColumnDividors < 5
    ) {
      this.mobileNavEnabled = true;
    } else {
      this.mobileNavEnabled = false;
    }
  }

  canScroll(direction) {
    let canScroll = true;

    canScroll = canScroll && !this.isAnimating;
    // console.log("can scroll is animating: " + this.isAnimating);

    canScroll =
      canScroll &&
      ((direction === ScrollDirection.up &&
        (this.state.page < this.props.data.length - 1 ||
          (this.mobileNavEnabled && !this.textMode))) ||
        (direction === ScrollDirection.down &&
          (this.state.page > 0 || (this.mobileNavEnabled && this.textMode))));

    if (
      !canScroll &&
      !this.isAnimating &&
      this.state.page === this.props.data.length - 1 &&
      direction === ScrollDirection.up &&
      !this.state.footer
    ) {
      return true;
    }

    return canScroll;
  }

  hideTransitionFOV(newPageIdx, direction) {
    if (!this.slider.current) return;
    this.isAnimating = true;
    let enteringPageRef = this.pageRefs[newPageIdx];

    if (direction !== null) {
      // Image Setting
      let currentImgIdx = this.slider.current.state.focusIndex;
      let leavingPageRef = this.pageRefs[this.state.page];

      leavingPageRef.current.setState({
        currentImage: this.loadedAssets
          ? this.loadedAssets[currentImgIdx.toString()]
          : null
      });
      leavingPageRef.current.showStaticImg();

      let beginSectionImgIdx = this.sectionIdxMap[newPageIdx].offsetIdx;
      enteringPageRef.current.setState({
        currentImage: this.loadedAssets
          ? this.loadedAssets[beginSectionImgIdx.toString()]
          : null
      });
      enteringPageRef.current.showStaticImg();

      if (this.state.fovLoaded) {
        this.slider.current.setImageIndex(beginSectionImgIdx, false);
      } else {
        this.slider.current.setState({focusIndex: beginSectionImgIdx});
      }
    }

    this.slider.current.glPlane1.uniforms.opacity.value = 0;
    this.slider.current.glPlane2.uniforms.opacity.value = 0;
    this.slider.current.curtain.needRender();

    if (mod(newPageIdx, 2) === 0) {
      this.slider.current.plane1.current.style.left = "0px";
      this.slider.current.plane2.current.style.left = "0px";
      this.slider.current.plane1.current.style.right = "";
      this.slider.current.plane2.current.style.right = "";
    } else {
      this.slider.current.plane1.current.style.left = "";
      this.slider.current.plane2.current.style.left = "";
      this.slider.current.plane1.current.style.right = "0px";
      this.slider.current.plane2.current.style.right = "0px";
    }
  }

  pageFinishedTransition() {
    if (!this.slider.current) return;
    this.slider.current.glPlane1.uniforms.opacity.value = 1;
    this.slider.current.glPlane2.uniforms.opacity.value = 1;
    this.slider.current.curtain.resize();
    setTimeout(() => {
      this.pageRefs[this.state.page].current.hideStaticImg();
    }, 200);
    let currentTopMargin =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveImageSizeInfo(this.props).imageTopMargin;
    this.fovContainer.current.style.top = `${currentTopMargin}vw`;
    // this.fovContainer.current.style.visibility = `visible`;

    // this.isAnimating = false;
  }

  animateNextPage() {
    this.isAnimating = true;
    let animationTime = 1750;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    let newPage = Math.min(
      Math.max(this.state.page + 1, 0),
      this.props.data.length - 1
    );
    if (newPage === this.state.page) {
      return;
    }
    this.hideTransitionFOV(newPage, ScrollDirection.up);

    this.setState({
      page: newPage,
      direction: ScrollDirection.up,
      isTransition: true,
      isRoute: false
    });
  }

  animatePreviousPage() {
    this.isAnimating = true;
    let animationTime = 1750;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    let newPage = Math.max(
      Math.min(this.state.page - 1, this.props.data.length - 1),
      0
    );
    if (newPage === this.state.page) {
      return;
    }
    this.hideTransitionFOV(newPage, ScrollDirection.down);
    this.setState({
      page: newPage,
      direction: ScrollDirection.down,
      isTransition: true,
      isRoute: false
    });
  }

  animateFooter() {
    let currentPage = this.pageRefs[this.state.page];
    let newTop = `-${1.5 * this.props.columnWidth}vw`;

    this.isAnimating = true;
    let animationTime = 750;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    TweenMax.fromTo(
      currentPage.current.container.current,
      0.6,
      {
        top: "0vh"
      },
      {
        top: newTop,
        delay: 0,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    let expandTextDelta = currentPage.current.textSection.current.getExpandedDelta();

    let newFovTop = `${(this.props.nColumnDividors >= 5
      ? getResponsiveTopMargin(this.props)
      : getResponsiveImageSizeInfo(this.props).imageTopMargin -
        expandTextDelta) -
      1.5 * this.props.columnWidth}vw`;

    let currentFovTop = `${
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveImageSizeInfo(this.props).imageTopMargin -
          expandTextDelta
    }vw`;

    TweenMax.fromTo(
      this.fovContainer.current,
      0.6,
      {
        top: currentFovTop
      },
      {
        top: newFovTop,
        delay: 0,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    this.setState({
      footer: true
    });
  }

  animateRestoreFooter() {
    let currentPage = this.pageRefs[this.state.page];
    let newTop = `-${1.5 * this.props.columnWidth}vw`;

    this.isAnimating = true;
    let animationTime = 750;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    TweenMax.fromTo(
      currentPage.current.container.current,
      0.6,
      {
        top: newTop
      },
      {
        top: "0vw",
        delay: 0,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    let expandTextDelta = currentPage.current.textSection.current.getExpandedDelta();

    let footerFovTop = `${(this.props.nColumnDividors >= 5
      ? getResponsiveTopMargin(this.props)
      : getResponsiveImageSizeInfo(this.props).imageTopMargin -
        expandTextDelta) -
      1.5 * this.props.columnWidth}vw`;

    let newFovTop = `${
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveImageSizeInfo(this.props).imageTopMargin -
          expandTextDelta
    }vw`;

    TweenMax.fromTo(
      this.fovContainer.current,
      0.6,
      {
        top: footerFovTop
      },
      {
        top: newFovTop,
        delay: 0,
        onComplete: () => {
          // this.isAnimating = false;
        },
        ease: CustomEase.create("custom", "0.60, 0.00, 0.39, 1.00")
      }
    );

    this.setState({
      footer: false
    });
  }

  animateTextMode() {
    this.isAnimating = true;
    let animationTime = isMobile ? 750 : 1200;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    let currentAboutSection = this.pageRefs[this.state.page].current;
    currentAboutSection.animateTextMode();
    let expandTextDelta = currentAboutSection.textSection.current.getExpandedDelta();

    let currentTopMargin =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveImageSizeInfo(this.props).imageTopMargin;

    TweenMax.fromTo(
      this.fovContainer.current,
      0.5,
      {
        top: `${currentTopMargin}vw`
      },
      {
        top: `${currentTopMargin - expandTextDelta}vw`,
        delay: 0,
        onComplete: () => {
          if (isMobile) {
            // this.isAnimating = false;
          }
        },
        ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
      }
    );
  }

  animateRestoreImageMode() {
    this.isAnimating = true;
    let animationTime = isMobile ? 750 : 1200;
    setTimeout(() => {
      this.isAnimating = false;
    }, animationTime);

    let currentAboutSection = this.pageRefs[this.state.page].current;
    currentAboutSection.restoreImageMode();

    let targetTopMargin =
      this.props.nColumnDividors >= 5
        ? getResponsiveTopMargin(this.props)
        : getResponsiveImageSizeInfo(this.props).imageTopMargin;

    TweenMax.to(this.fovContainer.current, 0.5, {
      top: `${targetTopMargin}vw`,
      onComplete: () => {
        if (isMobile) {
          // this.isAnimating = false;
        }
      },
      ease: CustomEase.create("custom", "0.59, 0.00, 0.04, 1.00")
    });
  }

  scrollUp() {
    if (this.mobileNavEnabled) {
      if (this.textMode) {
        // Foother
        if (this.state.page === this.props.data.length - 1) {
          this.animateFooter();
        } else {
          this.textMode = false;
          this.animateNextPage();
          // console.log("next page animation");
        }
      } else {
        this.textMode = true;
        this.animateTextMode();
        // console.log("text mode animation");
      }
    } else {
      this.textMode = false;

      if (this.state.page === this.props.data.length - 1) {
        this.animateFooter();
      } else {
        this.animateNextPage();
      }
    }
    // console.log("text mode: " + this.textMode);
  }
  scrollDown() {
    if (this.mobileNavEnabled) {
      if (this.state.footer) {
        this.animateRestoreFooter();
      } else if (this.textMode) {
        this.textMode = false;
        this.animateRestoreImageMode();
        // console.log("resport text animation");
      } else {
        this.textMode = false;
        this.animatePreviousPage();
        // console.log("prev page animation");
      }
    } else {
      this.textMode = false;
      if (this.state.footer) {
        this.animateRestoreFooter();
      } else {
        this.animatePreviousPage();
      }
    }

    // console.log("text mode: " + this.textMode);
  }

  handleLeftArrow() {
    if (this.isFOVAnimating || !this.slider.current || !this.state.fovLoaded) {
      return;
    }
    let currentImageIdx = this.slider.current.state.focusIndex;
    let offset =
      currentImageIdx - this.sectionIdxMap[this.state.page].offsetIdx;

    let newOffsetImageIdx = mod(
      offset - 1,
      this.sectionIdxMap[this.state.page].n
    );
    let newImageIdx =
      this.sectionIdxMap[this.state.page].offsetIdx + newOffsetImageIdx;

    this.slider.current.setImageIndex(newImageIdx, true);
    this.pageRefs[this.state.page].current.setState({
      currentImage: this.loadedAssets
        ? this.loadedAssets[newImageIdx.toString()]
        : null
    });
  }

  handleRightArrow() {
    if (this.isFOVAnimating || !this.slider.current || !this.state.fovLoaded) {
      return;
    }
    let currentImageIdx = this.slider.current.state.focusIndex;
    let offset =
      currentImageIdx - this.sectionIdxMap[this.state.page].offsetIdx;

    let newOffsetImageIdx = mod(
      offset + 1,
      this.sectionIdxMap[this.state.page].n
    );
    let newImageIdx =
      this.sectionIdxMap[this.state.page].offsetIdx + newOffsetImageIdx;

    this.slider.current.setImageIndex(newImageIdx, true);
    this.pageRefs[this.state.page].current.setState({
      currentImage: this.loadedAssets
        ? this.loadedAssets[newImageIdx.toString()]
        : null
    });
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
        onExit={() => {
          this.hideTransitionFOV(0, null);
        }}
        onExited={() => {
          this.setState({
            page: 0,
            direction: null,
            isTransition: false
          });
        }}
        timeout={1500}
      >
        <FullPageArea
          windowHeight={this.props.windowHeight}
          windowWidth={this.props.windowWidth}
          style={{
            overflow: "hidden"
          }}
        >
          {this.props.in && this.props.nColumnDividors < 6 && (
            <ColumnGrid {...this.props} />
          )}
          {this.props.data && this.props.data.map((e, i) => (
            <AboutPage
              key={e.title}
              ref={this.pageRefs[i]}
              onMounted={() => {this.setNavigationMode()}}
              {...this.props}
              in={this.props.in && this.state.page === i}
              isAppear={i === 0}
              isRoute={this.state.isRoute}
              isTransition={this.state.isTransition}
              fovLoaded={this.state.fovLoaded}
              {...e}
              currentImage={null}
              direction={this.state.direction}
              leftStyle={mod(i, 2) === 0}
              mobileNav={this.mobileNavEnabled}
              onEndAnimation={this.pageFinishedTransition}
              onRightArrowClick={this.handleRightArrow}
              onLeftArrowClick={this.handleLeftArrow}
              recentPartners={i === 0}
              awards={i === 1}
            />
          ))}

          <div
            ref={this.fovContainer}
            style={{
              position: "absolute",
              // visibility: "hidden",
              top: `${
                this.props.nColumnDividors >= 5
                  ? getResponsiveTopMargin(this.props)
                  : getResponsiveImageSizeInfo(this.props).imageTopMargin
              }vw`,
              left: `${
                getResponsiveImageSizeInfo(this.props).imageLeftMargin
              }vw`,
              width: `${this.props.headerWidth}vw`,
              height: `${getResponsiveImageSizeInfo(this.props).imageWidth *
                getResponsiveImageSizeInfo(this.props).imageAspectRatio}vw`,
              pointerEvents: "none"
            }}
          >
            {this.state.assets && this.state.assets.length > 0 && (
              <FOVShaderImage
                ref={this.slider}
                assets={this.state.assets}
                type={FOVTransitionType.SLIDESHOW}
                onAnimating={() => {
                  this.isFOVAnimating = true;
                }}
                onEndAnimation={() => {
                  this.isFOVAnimating = false;
                }}
                onAllSourcesLoaded={loadedAssetsBlobURLs => {
                  AboutCache = loadedAssetsBlobURLs;
                  this.setState({
                    assets: Object.keys(this.state.assets).map(
                      key => AboutCache[key]
                    )
                  });
                  this.loadedAssets = loadedAssetsBlobURLs;
                  this.pageRefs[this.state.page].current.setState({
                    currentImage: this.loadedAssets[
                      this.slider.current.state.focusIndex.toString()
                    ],
                    isImgDisplayed: false
                  });

                  this.setState({
                    fovLoaded: true
                  });
                }}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  display: !this.props.in ? "none" : "",
                  transition: "opacity 0.8s",
                  opacity: this.state.fovLoaded ? 1 : 0
                }}
                planeStyle={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: `${getResponsiveImageSizeInfo(this.props).imageWidth *
                    getResponsiveImageSizeInfo(this.props).imageAspectRatio}vw`,
                  width: `${
                    getResponsiveImageSizeInfo(this.props).imageWidth
                  }vw`
                }}
              />
            )}
          </div>
          <Footer
            {...this.props}
            style={{
              transform: `translate(0, ${this.state.footer ? "0" : "100%"})`,
              transition: "transform 0.5s cubic-bezier(0.60, 0.00, 0.39, 1.00)"
            }}
          />
        </FullPageArea>
      </Transition>
    );
  }
}
